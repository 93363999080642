import useSWR from "swr";

import useWebSocket from "react-use-websocket";
import { useAuth } from "@clerk/clerk-react";

export const fetcher = (req, auth) => async (url) => {
  const token = await auth;

  if (!req.headers) {
    req.headers = {};
  }
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
  }

  const base = req.base || process.env.REACT_APP_CLOUD_URL;

  const reqUrl = `${base}${url.split(":")[0]}`;
  console.log({ reqUrl }, req);
  return fetch(reqUrl, req)
    .then(async (res) => {
      if (!res.ok) {
        try {
          throw new Error(
            `Fetch error: ${res.status} ${res.statusText} - ${await res.text()}`
          );
        } catch (err) {}
        throw new Error(`Fetch error: ${res.status} ${res.statusText}`);
      }
      if (res.headers.get("content-type") === "application/json") {
        return res.json();
      }
      if (req.text || res.headers.get("content-type") === "text/plain") {
        return res.text();
      }
      return res.blob();
    })
    .then((res) => {
      if (!req.subject) return res;
      return res[req.subject];
    });
};

export const useGameserver = (nonce) => {
  const { getToken } = useAuth();
  return useSWR(
    `/beta/gameserver:${nonce}`,
    fetcher(
      { method: "GET", subject: "gameserver" },
      getToken({ template: "default" })
    )
  );
};

export const useGameserverDetails = (gameserverId, nonce) => {
  const { getToken } = useAuth();
  return useSWR(
    `/beta/gameserver/${gameserverId}:${nonce}`,
    fetcher(
      { method: "GET", subject: "gameserver" },
      getToken({ template: "default" })
    )
  );
};

export const useGameserverLogs = (gameserverId, ctx, auth, shouldConnect) => {
  return useWebSocket(
    `${process.env.REACT_APP_CLOUD_URL.replace(
      "http",
      "ws"
    )}/gameservers/${gameserverId}/logs?follow=true`,
    {
      share: true,
      retryOnError: true,
      reconnectAttempts: 5,
      reconnectInterval: 1000,
      shouldReconnect: () => !!auth && shouldConnect,
      protocols: [`xyz.ogh.auth.${auth}`, "xyz.ogh.ws"],
      ...ctx,
    },
    !!auth
  );
};

export const getGameserverVersions = async (game, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, subject: "versions" },
    auth
  )(`/games/${game}/versions`);
};

export const useGameserverVersions = (game) => {
  const { getToken } = useAuth();
  return useSWR(
    `/games/${game}/versions`,
    fetcher(
      { method: "GET", subject: "versions" },
      getToken({ template: "default" })
    )
  );
};

export const getGameserverManifest = async (game, version) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { base: "https://public.opengamehosting.xyz", method },
    ""
  )(`/games/manifest/${game}/${version}/manifest.json`);
};

export const getGameserverDetails = async (gameserverId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}`);
};

export const updateGameserverDetails = async (gameserverId, patch, auth) => {
  const method = "PATCH";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
      body: JSON.stringify(patch),
      headers: { "content-type": "application/json" },
    },
    auth
  )(`/gameservers/${gameserverId}`);
};

export const getGameserverEvents = async (gameserverId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, subject: "events" },
    auth
  )(`/gameservers/${gameserverId}/events`);
};

export const getGameserverTasks = async (gameserverId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/tasks`);
};

export const disableGameserverTask = async (gameserverId, taskId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/tasks/${taskId}/disable`);
};

export const enableGameserverTask = async (gameserverId, taskId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/tasks/${taskId}/enable`);
};

export const getGameserverLogs = async (gameserverId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/logs`);
};

export const scheduleGameserver = async (gameserver, auth) => {
  const method = "POST";
  const body = JSON.stringify(gameserver);
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method, body, subject: "gameserver" }, auth)("/beta/order");
};

export const getQueuePositionGameserver = async (auth) => {
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ subject: "queue" }, auth)("/beta/gameserver/queue");
};

export const stopGameserver = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/stop`);
};

export const startGameserver = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/start`);
};

export const restartGameserver = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/restart`);
};

export const pauseGameserver = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, subject: "gameserver" },
    auth
  )(`/gameservers/${gameserverId}/pause`);
};

export const unpauseGameserver = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, subject: "gameserver" },
    auth
  )(`/gameservers/${gameserverId}/unpause`);
};

export const deleteGameserver = async (gameserverId, auth) => {
  const method = "DELETE";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}`);
};

export const sendGameserverCommand = async (gameserverId, command, auth) => {
  const method = "POST";
  const body = JSON.stringify({ command });
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, body, subject: "gameserver" },
    auth
  )(`/gameservers/${gameserverId}/command`);
};

export const getGameserverBackups = async (gameserverId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/backups`);
};

export const getGameserverBackup = async (gameserverId, backupId, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/backups/${backupId}`);
};

export const downloadGameserverBackup = async (
  gameserverId,
  backupId,
  auth
) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, headers: { accept: "application/octet-stream" } },
    auth
  )(`/gameservers/${gameserverId}/backups/${backupId}`);
};

export const restoreGameserverBackup = async (gameserverId, backupId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/backups/${backupId}/restore`);
};

export const restoreGameserverTimemachine = async (
  gameserverId,
  restorePoint,
  auth
) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, body: JSON.stringify({ nearest: restorePoint }) },
    auth
  )(`/gameservers/${gameserverId}/timemachine/restore`);
};

export const deleteGameserverBackup = async (gameserverId, backupId, auth) => {
  const method = "DELETE";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/backups/${backupId}`);
};

export const newGameserverBackup = async (gameserverId, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher({ method }, auth)(`/gameservers/${gameserverId}/backups`);
};

export const newGameserverTask = async (gameserverId, task, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, body: JSON.stringify(task) },
    auth
  )(`/gameservers/${gameserverId}/tasks`);
};

export const deleteGameserverTask = async (gameserverId, taskId, auth) => {
  const method = "DELETE";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/tasks/${taskId}`);
};

export const getGameserverCredential = async (
  gameserverId,
  credentialType,
  auth
) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, subject: "credential" },
    auth
  )(`/gameservers/${gameserverId}/credentials/${credentialType}`);
};

export const getGameserverFiles = async (gameserverId, path, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, headers: { accept: "application/json" } },
    auth
  )(`/gameservers/${gameserverId}/files/${path}`);
};

export const getGameserverFileContent = async (gameserverId, path, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method, headers: { accept: "application/octet-stream" }, text: true },
    auth
  )(`/gameservers/${gameserverId}/files/${path}`);
};

export const deleteGameserverFile = async (gameserverId, path, auth) => {
  const method = "DELETE";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    { method },
    auth
  )(`/gameservers/${gameserverId}/files/${path}`);
};

export const updateGameserverFileContent = async (
  gameserverId,
  path,
  content,
  auth
) => {
  const method = "PUT";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
      headers: {
        "content-type": "text/plain",
      },
      body: content,
    },
    auth
  )(`/gameservers/${gameserverId}/files/${path}`);
};

export const getGameserverFileConfig = async (gameserverId, path, auth) => {
  const method = "GET";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
    },
    auth
  )(`/gameservers/${gameserverId}/config/${path}`);
};

export const updateGameserverFileConfig = async (
  gameserverId,
  path,
  config,
  auth
) => {
  const method = "PATCH";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(config),
    },
    auth
  )(`/gameservers/${gameserverId}/config/${path}`);
};

export const runGameserverFileConfig = async (
  gameserverId,
  filename,
  config,
  auth
) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ filename, set: config }),
    },
    auth
  )(`/gameservers/${gameserverId}/config`);
};

export const installGameserverMod = async (gameserverId, install, auth) => {
  const method = "POST";
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return fetcher(
    {
      method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(install),
    },
    auth
  )(`/gameservers/${gameserverId}/mods`);
};
