import { Typography } from "@mui/joy";

const heading = (level, props) => (
  <Typography
    level={level}
    sx={{ fontWeight: 400, marginBottom: "1rem" }}
    {...props}
  />
);

export const H1 = (props) => heading("h1", props);
export const H2 = (props) => heading("h2", props);
export const H3 = (props) => heading("h3", props);
export const H4 = (props) => heading("h4", props);
export const H5 = (props) => heading("h5", props);
