import { Chip } from "@mui/joy";
import { useEffect, useState } from "react";

export const ChipOrb = ({ interval, color1, color2, ...props }) => {
  interval = interval || 1000;
  const [color, setColor] = useState(color1);
  useEffect(() => {
    const i = setInterval(
      () => setColor(color === color1 ? color2 : color1),
      interval
    );
    return () => clearInterval(i);
  }, [interval, color, color1, color2]);
  return <Chip color={color} {...props} />;
};
