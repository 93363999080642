import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Select from "react-select";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

import GameIcon from "components/misc/GameIcon";
import { useNavigate } from "react-router";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto pt-10 pb-20`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

const Heading = tw.h1`font-black text-3xl md:text-3xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-1 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-4`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const TwoColumns = tw.div`max-w-screen-xl mx-auto flex flex-wrap justify-between`;
const TwoColumnsColumn = tw.div`md:w-1/2`;

const games = [
  {
    value: "minecraft",
    label: "Minecraft: Java Edition",
  },
  {
    value: "cs2",
    label: "Counter Strike: 2",
  },
  {
    value: "valheim",
    label: "Valheim",
  },
  {
    value: "factorio",
    label: "Factorio",
  },
];

function getLabel({ value, label }, style) {
  return (
    <div style={{ alignItems: "center", display: "flex", ...style }}>
      <span style={{ fontSize: 18, marginRight: "0.5em" }}>
        <img src={GameIcon(value)} width={16} alt={`${value} icon`} />
      </span>
      <span style={{ fontSize: 14 }}>{label}</span>
    </div>
  );
}

const headerSelectStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    backgroundClip: "padding-box",
    borderColor: "rgba(0,0,0,0.1)",
    boxShadow: isFocused ? "0 0 0 1px #4C9AFF" : undefined,

    ":hover": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  }),
  option: (base) => ({
    ...base,
    padding: "4px 12px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "black",
  }),
  container: (base) => ({
    ...base,
    width: "80%",
  }),
};

export default ({
  heading = "Modern React Templates, Just For You",
  description = "Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  features = [],
  features2 = [],
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
  onSelectChange = () => {},
}) => {
  const [selectValue, setSelectValue] = useState(games[0]);
  const [selectInteracted, setSelectInteracted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectInteracted) {
      return;
    }
    const getGame = () => {
      const val = [...games].sort(() => (Math.random() > 0.5 ? 1 : -1))[0];
      if (val.value === selectValue.value) {
        return getGame();
      }
      return val;
    };
    const i = setInterval(() => {
      setSelectValue(getGame());
    }, 1000);
    return () => clearInterval(i);
  }, [selectValue, selectInteracted]);
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <Select
                formatOptionLabel={getLabel}
                options={games}
                onChange={(option) => {
                  setSelectValue(option);
                  onSelectChange(option);
                }}
                onFocus={() => setSelectInteracted(true)}
                value={selectValue}
                styles={headerSelectStyles}
              />
            </Actions>
            <h5 style={{ marginTop: "1rem" }}>Or chose a game below:</h5>
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
            >
              {games.map((game, index) => (
                <div
                  style={{
                    padding: "5px 10px",
                    border: "1px dashed rgba(0,0,0,0.1)",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/games/${game.value}`)}
                >
                  {getLabel(game)}
                </div>
              ))}
            </div>
            <TwoColumns>
              <TwoColumnsColumn>
                <FeatureList>
                  {features.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      <FeatureText>{feature}</FeatureText>
                    </Feature>
                  ))}
                </FeatureList>
              </TwoColumnsColumn>
              <TwoColumnsColumn>
                <FeatureList>
                  {features2.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      <FeatureText>{feature}</FeatureText>
                    </Feature>
                  ))}
                </FeatureList>
              </TwoColumnsColumn>
            </TwoColumns>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img css={imageCss} src={imageSrc} alt="Hero" />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
