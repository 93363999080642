export const serverProperties = [
  {
    name: "level-seed",
    default: "",
    description:
      "Use to randomize the world. If left empty, a seed is chosen at random.",
    type: "String",
  },
  {
    name: "gamemode",
    default: "survival",
    description: "Sets the game mode for new players.",
    type: "Enum",
    values: ["survival", "creative", "adventure"],
  },
  {
    name: "enable-command-block",
    default: "false",
    description: "Enables command blocks",
    type: "Boolean",
  },
  {
    name: "enable-query",
    default: "false",
    description:
      "Enables GameSpy4 protocol server listener. Used to get information about server.",
    type: "Boolean",
  },
  {
    name: "generator-settings",
    default: "{}",
    description: "The settings used to customize world generation.",
    type: "String",
  },
  {
    name: "enforce-secure-profile",
    default: "true",
    description:
      "If set to enabled, players without a Mojang-signed public key will not be able to connect to the server.",
    type: "Boolean",
  },
  {
    name: "level-name",
    default: "world",
    description:
      " The 'level-name' value is used as the world name and its folder name.",
    type: "String",
  },
  {
    name: "motd",
    default: "A Minecraft Server",
    description:
      "This is the message that is displayed in the server list of the client, below the name.",
    type: "String",
  },
  {
    name: "pvp",
    default: "true",
    description: "Enable PvP on the server.",
    type: "Boolean",
  },
  {
    name: "generate-structures",
    default: "true",
    description:
      "Defines whether structures (such as villages) can be generated",
    type: "Boolean",
  },
  {
    name: "difficulty",
    default: "easy",
    description:
      "Defines the difficulty (such as damage dealt by mobs and the way hunger and poison affects players) of the server.",
    type: "Enum",
    values: ["peaceful", "easy", "normal", "hard"],
  },
  {
    name: "max-tick-time",
    default: "60000",
    description:
      "The maximum number of milliseconds a single tick may take before the server watchdog stops and crashes with an error.",
    type: "Integer",
  },
  {
    name: "require-resource-pack",
    default: "false",
    description:
      "When this option is enabled, players will be prompted for a response and will be disconnected if they decline the required pack.",
    type: "Boolean",
  },
  {
    name: "max-players",
    default: "20",
    description:
      "The maximum number of players that can play on the server at the same time.",
    type: "Integer",
  },
  {
    name: "online-mode",
    default: "true",
    description:
      "Controls whether or not the server will authenticate players with Mojang's servers. Warning: You should set this to disabled only if you are using a proxy server with private IP.",
    type: "Boolean",
  },
  {
    name: "enable-status",
    default: "true",
    description:
      "Makes the server appear as 'online' on the server list. If set to disabled, it will suppress replies from clients. This means it will appear as offline, but will still accept connections.",
    type: "Boolean",
  },
  {
    name: "allow-flight",
    default: "false",
    description:
      "Allows users to use flight on the server while in Survival mode, if they have a mod that provides flight installed.",
    type: "Boolean",
  },
  {
    name: "initial-disabled-packs",
    default: "",
    description:
      "Comma-separated list of datapacks to not be auto-enabled on world creation.",
    type: "String",
  },
  {
    name: "broadcast-rcon-to-ops",
    default: "true",
    description:
      "Controls whether operators will see executed commands via RCON of other operators in chat.",
    type: "Boolean",
  },
  {
    name: "view-distance",
    default: "10",
    description:
      "Server-side viewing distance - measured in chunks in each direction of the player (radius, not diameter).",
    type: "Integer",
  },
  {
    name: "resource-pack-prompt",
    default: "",
    description:
      "Adds a custom message to be shown on resource pack prompt when Require Resource Pack is used.",
    type: "String",
  },
  {
    name: "allow-nether",
    default: "true",
    description:
      "Allows players to travel to the Nether. If disabled, Nether Portals do not work.",
    type: "Boolean",
  },
  {
    name: "op-permission-level",
    default: "4",
    description: "Sets the default permission level for ops when using /op.",
    type: "Integer",
  },
  {
    name: "prevent-proxy-connections",
    default: "false",
    description:
      "If the ISP/AS sent from the server is different from the one from Mojang Studios' authentication server, the player is kicked.",
    type: "Boolean",
  },
  {
    name: "hide-online-players",
    default: "false",
    description: "If enabled, a player list is not sent on status requests.",
    type: "Boolean",
  },
  {
    name: "resource-pack",
    default: "",
    description:
      "Optional URI to a resource pack. The player may choose to use it.",
    type: "String",
  },
  {
    name: "simulation-distance",
    default: "10",
    description:
      "Controls the number of chunks in each direction from each player in which entities will remain loaded.",
    type: "Integer",
  },
  {
    name: "rcon.password",
    default: "",
    description:
      "Sets a password for RCON to use your server's console remotely.",
    type: "String",
  },
  {
    name: "player-idle-timeout",
    default: "0",
    description:
      "Controls if and when players will be kicked from the server if they are idle, after a number of minutes.",
    type: "Integer",
  },
  {
    name: "force-gamemode",
    default: "false",
    description:
      "Controls whether or not players should be in the same gamemode as the server every time they join the server.",
    type: "Boolean",
  },
  {
    name: "hardcore",
    default: "false",
    description:
      "If set to enabled, server difficulty is ignored and set to hard and players are set to spectator mode if they die.",
    type: "Boolean",
  },
  {
    name: "white-list",
    default: "false",
    description:
      "Enables a whitelist on the server. With a whitelist enabled, users not on the whitelist cannot connect.",
    type: "Boolean",
  },
  {
    name: "broadcast-console-to-ops",
    default: "true",
    description:
      "Controls whether operators will see executed commands of other operators in chat.",
    type: "Boolean",
  },
  {
    name: "spawn-npcs",
    default: "true",
    description: "Controls whether or not NPCs will spawn in your worlds.",
    type: "Boolean",
  },
  {
    name: "spawn-animals",
    default: "true",
    description: "Controls whether or not animals will spawn in your worlds.",
    type: "Boolean",
  },
  {
    name: "function-permission-level",
    default: "2",
    description: "Sets the default permission level for functions.",
    type: "Integer",
  },
  {
    name: "initial-enabled-packs",
    default: "vanilla",
    description:
      "Comma-separated list of datapacks to be enabled during world creation. Feature packs need to be explicitly enabled.",
    type: "String",
  },
  {
    name: "level-type",
    default: "minecraft:normal",
    description: "Determines the world preset that is generated.",
    type: "Enum",
    values: [
      "minecraft:normal",
      "minecraft:flat",
      "minecraft:large_biomes",
      "minecraft:amplified",
      "minecraft:single_biome_surface",
    ],
  },
  {
    name: "spawn-monsters",
    default: "true",
    description:
      "Determines if monsters can spawn. This setting has no effect on peaceful difficulty.",
    type: "Boolean",
  },
  {
    name: "enforce-whitelist",
    default: "false",
    description: "Enforces the whitelist on the server.",
    type: "Boolean",
  },
  {
    name: "spawn-protection",
    default: "16",
    description:
      "Determines the side length of the square spawn protection area as 2x+1. Setting this to 0 disables the spawn protection. (e.g. 1 = a 3×3 square centere protected)",
    type: "Integer",
  },
  {
    name: "resource-pack-sha1",
    default: "",
    description:
      "Optional SHA-1 digest of the resource pack, in lowercase hexadecimal. It is recommended to specify this, because it is used to verify the integrity of the resource pack.",
    type: "String",
  },
  {
    name: "max-world-size",
    default: "29999984",
    description:
      "This sets the maximum possible size in blocks, expressed as a radius, that the world border can obtain. (e.g. 1000 = 2000x2000 border)",
    type: "Integer",
  },
];
