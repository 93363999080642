import cs2 from "images/games/cs2-icon.png";
import minecraft from "images/games/minecraft-icon.png";
import factorio from "images/games/factorio-icon.png";
import valheim from "images/games/valheim-icon.png";

const icons = {
  cs2,
  minecraft,
  factorio,
  valheim,
};

export default (game) => icons[game];
