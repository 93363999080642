import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "components/hero/BackgroundAsImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {
  FormContainer,
  InputContainer,
} from "components/forms/SimpleContactUs";

import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import minecraftHeroSrc from "images/minecraft-hero.png";

import { useNavigate, useParams } from "react-router-dom";
import GameIcon from "components/misc/GameIcon";
import Select from "react-select";
import { RadioGroup } from "@headlessui/react";
import styled from "styled-components";
import { capital } from "case";
import { SignInButton, SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";
import { scheduleGameserver, useGameserverVersions } from "../data/gameserver";
import {
  Button,
  Switch,
  Radio,
  Stepper,
  Step,
  StepIndicator,
  StepButton,
  Box,
  Sheet,
} from "@mui/joy";

import Flag from "react-flagkit";

const Notice = tw.div`flex-1 w-2/3 mb-8 flex-auto rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const NoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Container = tw.div`px-48`;
const Columns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between`;
const Column = tw.div`sm:px-0 sm:w-1/3`;
const OffsetColumnsFirst = tw.div`sm:px-0 sm:w-3/4`;
const OffsetColumnsLast = tw.div`sm:px-0 sm:w-1/4`;
const Section = tw.section`py-16`;
const MidSection = tw(Section)``;
const SectionTitle = tw.h5`my-1 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const ChoiceContainer = styled.div`flex flex-col align-items-left`;
const Choice = styled.div`
  ${tw`flex px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-4 w-1/6 relative`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const ActiveChoice = tw(Choice)`bg-primary-100`;
const ChoiceHeading = styled.h4`
  ${tw`text-xl leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Check = tw.div`text-white bg-primary-500 w-12 h-6 w-6`;

const OrderDiv = tw.div`bg-gray-200 px-5 py-5`;
const OrderContent = tw.div`py-5`;
const OrderDetails = tw.div`pt-2 pb-10`;
const OrderTotal = tw.h1`text-4xl font-bold`;
const TosDiv = tw.div`flex items-center`;
const OrderButton = tw.button`
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 text-2xl focus:shadow-outline
  border-b-0 block mt-2 w-full
`;

function getLabel({ value, label, icon }) {
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      {icon && (
        <span style={{ fontSize: 18, marginRight: "0.5em" }}>
          <img src={GameIcon(value)} width={16} alt={`${value} icon`} />
        </span>
      )}
      <span style={{ fontSize: 14 }}>{label}</span>
    </div>
  );
}

const headerSelectStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    backgroundClip: "padding-box",
    borderColor: "rgba(0,0,0,0.1)",
    boxShadow: isFocused ? "0 0 0 1px #4C9AFF" : undefined,

    ":hover": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  }),
  option: (base) => ({
    ...base,
    padding: "4px 12px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "black",
  }),
  container: (base) => ({
    ...base,
    width: "80%",
  }),
};

const games = [
  {
    value: "minecraft",
    label: "Minecraft: Java Edition",
    icon: GameIcon("minecraft"),
  },
  {
    value: "cs2",
    label: "Counter Strike: 2",
    icon: GameIcon("cs2"),
  },
  {
    value: "valheim",
    label: "Valheim",
    icon: GameIcon("valheim"),
  },
  {
    value: "factorio",
    label: "Factorio",
    icon: GameIcon("factorio"),
  },
];

const modloaders = {
  minecraft: [
    {
      value: "",
      label: "Vanilla",
    },
    {
      value: "forge",
      label: "Forge",
    },
    {
      value: "fabric",
      label: "Fabric",
    },
    {
      value: "paper",
      label: "Paper",
    },
  ],
  valheim: [
    {
      value: "",
      label: "Vanilla",
    },
    {
      value: "bepinex",
      label: "BepInEx",
    },
  ],
  cs2: [
    {
      value: "",
      label: "Vanilla",
    },
    {
      value: "metamod",
      label: "MetaMod: Source",
    },
  ],
};

const standardPlans = [
  {
    name: "Standard",
    ram: "8GB",
    cpus: "2 CPUs",
    disk: "60 GB SSD disk",
    value: "standard-1-1",
  },
];

const regions = [
  {
    name: "Germany",
    country: "Germany",
    value: "eu-west",
    code: "DE",
  },
  {
    name: "UK",
    country: "United Kingdom",
    value: "uk-gb",
    code: "GB",
  },
];

export default () => {
  let { game } = useParams();
  const { register, handleSubmit, getValues } = useForm();
  const [version, setVersion] = useState("latest");
  const [modloader, setModloader] = useState("");
  const [modloaderVersion, setModloaderVersion] = useState("");
  const [plan, setPlan] = useState(null);
  const [region, setRegion] = useState(null);
  const [tosCheck, setTosCheck] = useState(false);
  const [config, setConfig] = useState(false);
  const [error, setError] = useState({});
  const { getToken, isSignedIn } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data: versions, error: err, isLoading } = useGameserverVersions(game);

  console.log({ versions, err, isLoading });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setError(() => ({}));
    if (!isSignedIn) {
      return;
    }
    if (!tosCheck) {
      setError(() => ({
        type: "tos",
        message: "You must agree to the terms of service",
      }));
      return;
    }
    const configuration = Object.entries(data.config || {}).reduce(
      (acc, [key, value]) => {
        if (value === "") {
          return acc;
        }
        return { ...acc, [key]: value };
      },
      {}
    );
    if (modloader) {
      configuration.MODLOADER = modloader;
    }
    if (modloaderVersion) {
      configuration.MODLOADER_VERSION = modloaderVersion;
    }
    const order = {
      gameserver: {
        game,
        version,
        configuration,
        region: region && region.value,
        plan: plan && plan.value,
      },
    };
    const token = await getToken({ template: "default" });
    try {
      const gameserver = await scheduleGameserver(order.gameserver, token);
      console.log({ gameserver, token });
      navigate(`/gameservers/${gameserver.name}`);
    } catch (err) {
      setError({ type: "base", message: err.message });
    }
  };

  useEffect(() => {
    setVersion("latest");
    setModloader("");
    setModloaderVersion("");
  }, [game]);

  useEffect(() => {
    const abortController = new AbortController();
    const url = `https://public.opengamehosting.xyz/games/manifest/${game}/${version}/manifest.json`;
    const fetchData = async () => {
      try {
        fetch(url)
          .then((res) => res.json())
          .then((res) => setConfig(res.configuration));
      } catch (err) {
        console.log({ err });
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [game, version]);
  console.log({ form: getValues() });
  const NextTab = (props) => {
    return (
      <Button onClick={() => setSelectedIndex((i) => i + 1)} {...props}>
        Next
      </Button>
    );
  };
  const PrevTab = (props) => {
    return (
      <Button onClick={() => setSelectedIndex((i) => i - 1)} {...props}>
        Previous
      </Button>
    );
  };

  const sections = [
    {
      title: "Gameserver",
      content: (
        <>
          <Section style={{ padding: "0" }}>
            <div>
              <SectionTitle>Gameserver</SectionTitle>
              <Select
                formatOptionLabel={getLabel}
                options={games}
                onChange={(option) => {
                  navigate(`/games/${option.value}`);
                }}
                placeholder={getLabel(games.find((g) => g.value === game))}
                styles={headerSelectStyles}
              />
            </div>
          </Section>
          <Section>
            <Columns>
              <Column>
                <SectionTitle>Version</SectionTitle>
                <Select
                  formatOptionLabel={getLabel}
                  options={(versions || []).map((v) => ({
                    label: v.version,
                    value: v.version,
                  }))}
                  onChange={(option) => {
                    setVersion(option.value);
                  }}
                  placeholder={getLabel({
                    label: "latest",
                    value: "latest",
                  })}
                  styles={headerSelectStyles}
                />
              </Column>
              <Column>
                {modloaders[game] && (
                  <>
                    <SectionTitle>Modloader</SectionTitle>
                    <Select
                      formatOptionLabel={getLabel}
                      options={modloaders[game]}
                      onChange={(option) => {
                        setModloader(option.value);
                      }}
                      placeholder={getLabel({
                        label: "Vanilla",
                        value: "vanilla",
                      })}
                      styles={headerSelectStyles}
                    />
                  </>
                )}
              </Column>
              <Column>
                {modloader && (
                  <>
                    <SectionTitle>Modloader Version</SectionTitle>
                    <Select
                      formatOptionLabel={getLabel}
                      options={versions[game]}
                      onChange={(option) => {
                        setModloaderVersion(option.value);
                      }}
                      placeholder={getLabel({
                        label: "Latest",
                        value: "latest",
                      })}
                      styles={headerSelectStyles}
                    />
                  </>
                )}
              </Column>
            </Columns>
          </Section>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <NextTab />
          </div>
        </>
      ),
    },
    {
      title: "Server Resources",
      content: (
        <MidSection style={{ paddingTop: 0 }}>
          <RadioGroup value={plan} onChange={setPlan}>
            <RadioGroup.Label className="sr-only">
              {plan && plan.value}
              <SectionTitle style={{ marginBottom: "1rem" }}>
                Server Size
              </SectionTitle>
            </RadioGroup.Label>
            <ChoiceContainer>
              {standardPlans.map((plan) => (
                <RadioGroup.Option key={plan.name} value={plan}>
                  {({ active, checked }) => {
                    const CheckEl = checked ? ActiveChoice : Choice;
                    return (
                      <>
                        <CheckEl className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label>
                                <ChoiceHeading
                                  style={{ marginBottom: "0.5rem" }}
                                >
                                  {plan.name}
                                </ChoiceHeading>
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={`inline ${
                                  checked ? "text-sky-100" : "text-gray-500"
                                }`}
                              >
                                <span>
                                  {plan.ram}/{plan.cpus}
                                </span>{" "}
                                <span aria-hidden="true">&middot;</span>{" "}
                                <span>{plan.disk}</span>
                              </RadioGroup.Description>
                            </div>
                          </div>
                          {checked && (
                            <Check
                              style={{
                                borderRadius: 100,
                                position: "absolute",
                                top: -10,
                                right: -10,
                                width: 24,
                              }}
                            >
                              <CheckIcon className="h-6 w-6" />
                            </Check>
                          )}
                        </CheckEl>
                      </>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </ChoiceContainer>
          </RadioGroup>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <PrevTab style={{ marginRight: "1rem" }} />
            <NextTab />
          </div>
        </MidSection>
      ),
    },
    {
      title: "Region",
      content: (
        <MidSection style={{ paddingTop: 0 }}>
          <RadioGroup value={region} onChange={setRegion}>
            <RadioGroup.Label className="sr-only">
              {region && region.value}
              <SectionTitle style={{ marginBottom: "1rem" }}>
                Region
              </SectionTitle>
            </RadioGroup.Label>
            <div className="space-y-2">
              <RadioGroup
                aria-labelledby="storage-label"
                size="lg"
                sx={{ gap: 1.5 }}
              >
                {regions.map((r) => (
                  <Sheet
                    key={r.value}
                    sx={{
                      p: 2,
                      borderRadius: "md",
                      boxShadow: "sm",
                      mb: 3,
                    }}
                  >
                    <Radio
                      label={
                        <>
                          <Flag
                            country={r.code}
                            style={{
                              display: "inline-block",
                              marginRight: "5px",
                            }}
                          />{" "}
                          {r.name}
                        </>
                      }
                      overlay
                      disableIcon
                      value={r.value}
                      slotProps={{
                        label: ({ checked }) => ({
                          sx: {
                            fontWeight: "lg",
                            fontSize: "md",
                            color: checked ? "text.primary" : "text.secondary",
                          },
                        }),
                        action: ({ checked }) => ({
                          sx: (theme) => ({
                            ...(checked && {
                              "--variant-borderWidth": "2px",
                              "&&": {
                                // && to increase the specificity to win the base :hover styles
                                borderColor: theme.vars.palette.primary[500],
                              },
                            }),
                          }),
                        }),
                      }}
                      checked={region && region.value === r.value}
                      onChange={() => setRegion(() => r)}
                    />
                  </Sheet>
                ))}
              </RadioGroup>
            </div>
          </RadioGroup>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <PrevTab style={{ marginRight: "1rem" }} />
            <NextTab />
          </div>
        </MidSection>
      ),
    },
    {
      title: "Configuration",
      content: (
        <MidSection style={{ paddingTop: "0" }}>
          <SectionTitle style={{ marginBottom: "1rem" }}>
            Configuration
          </SectionTitle>
          <FormContainer>
            <Columns>
              {config &&
                config
                  .sort((a, b) => {
                    if (a.name > b.name) {
                      return -1;
                    }
                    if (a.name < b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((c) => (
                    <Column>
                      <InputContainer>
                        <label>{capital(c.name)}</label>
                        <input
                          {...register(`config.${c.name}`)}
                          type="text"
                          placeholder={c.default || ""}
                        />
                        {c.description && <small>{c.description}</small>}
                      </InputContainer>
                    </Column>
                  ))}
            </Columns>
          </FormContainer>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <PrevTab />
          </div>
        </MidSection>
      ),
    },
  ];

  return (
    <>
      <Hero
        heading={
          <>
            Build your ultimate
            <HighlightedText
              style={{ padding: "0 0.5rem", marginLeft: "0.5rem" }}
            >
              {game}
            </HighlightedText>{" "}
            gameserver
          </>
        }
        backgroundImage={`url(${minecraftHeroSrc})`}
      />
      <Container style={{ paddingTop: "6rem" }}>
        <Columns>
          <OffsetColumnsFirst
            style={{ paddingRight: "3rem", paddingBottom: "3rem" }}
          >
            {error && error.type && (
              <Notice>
                <NoticeIcon />
                Error ({error.type}): {error.message}
              </Notice>
            )}
            <Box>
              <Stepper sx={{ width: "100%", paddingBottom: 4 }}>
                {sections.map((step, index) => (
                  <Step
                    key={index}
                    indicator={
                      <StepIndicator
                        variant={selectedIndex <= index - 1 ? "soft" : "solid"}
                        color={
                          selectedIndex <= index - 1 ? "neutral" : "primary"
                        }
                      >
                        {/*<Checkbox checked={selectedIndex > index} />*/}
                      </StepIndicator>
                    }
                    sx={{
                      "&::after": {
                        ...(selectedIndex >= index && {
                          bgcolor: "primary.solidBg",
                        }),
                      },
                    }}
                  >
                    <StepButton onClick={() => setSelectedIndex(index)}>
                      {step.title}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {sections.map((step, index) => (
              <Box
                style={{ display: selectedIndex === index ? "block" : "none" }}
              >
                {step.content}
              </Box>
            ))}
          </OffsetColumnsFirst>
          <OffsetColumnsLast>
            <Section style={{ paddingTop: 0 }}>
              <OrderDiv>
                <HighlightedText
                  style={{ fontSize: "2rem", fontWeight: "800" }}
                >
                  <h3>Your Order</h3>
                </HighlightedText>
                <OrderContent>
                  <SectionTitle>Gameserver</SectionTitle>
                  <OrderDetails>
                    <ul style={{ margin: 0 }}>
                      <li>Game (preinstalled): {game}</li>
                      <li>Version: {version}</li>
                      {modloader && (
                        <li>Modloader (preinstalled): {modloader}</li>
                      )}
                      {modloaderVersion && (
                        <li>Modloader Version: {modloaderVersion}</li>
                      )}
                      {plan && <li>Size: {plan.name}</li>}
                      {region && <li>Region: {region.name}</li>}
                      <li>Cloud URL: {process.env.REACT_APP_CLOUD_URL}</li>
                    </ul>
                  </OrderDetails>
                  <SectionTitle>Total Due Today</SectionTitle>
                  <OrderTotal>$ 0.00</OrderTotal>
                  <div style={{ margin: "15px 0", color: "red" }}>
                    {error && error.type === "tos" && (
                      <span>{error.message}</span>
                    )}
                  </div>
                  <TosDiv>
                    <Switch
                      checked={tosCheck}
                      onChange={(e) => setTosCheck(e.target.checked)}
                    />

                    <small style={{ marginLeft: "10px" }}>
                      I have read & agree to the Terms of Service
                    </small>
                  </TosDiv>
                  <SignedIn>
                    <OrderButton onClick={handleSubmit(onSubmit)}>
                      Order Now
                    </OrderButton>
                  </SignedIn>
                  <SignedOut>
                    <SignInButton
                      redirectUrl={window.location.pathname}
                      mode="modal"
                    >
                      <OrderButton>Order Now</OrderButton>
                    </SignInButton>
                  </SignedOut>
                </OrderContent>
              </OrderDiv>
            </Section>
          </OffsetColumnsLast>
        </Columns>
      </Container>
      <Footer />
    </>
  );
};
