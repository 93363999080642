import cronstrue from "cronstrue";

export const Schedule = ({ cron }) => (
  <span>
    {cronstrue.toString(cron, {
      throwExceptionOnParseError: false,
      verbose: true,
    })}
  </span>
);
