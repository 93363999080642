import { Input, Typography } from "@mui/joy";
import { useState } from "react";

export const Editable = ({ onChange, onValidate, initialValue, ...props }) => {
  const [value, setValue] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);
  return !isFocused ? (
    <Typography
      onClick={() => {
        setIsFocused(true);
      }}
      {...props}
    >
      {value.toString()}
    </Typography>
  ) : (
    <Input
      autoFocus
      value={value.toString()}
      onChange={(event) => setValue(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Escape" || event.key === "Enter") {
          event.target.blur();
        }
      }}
      onBlur={async (event) => {
        onValidate(event.target.value).then(() => {
          setIsFocused(false);
          onChange(event.target.value);
        });
      }}
      variant="soft"
      size="sm"
      {...props}
    />
  );
};
