import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header from "../headers/default.js";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 p-10 bg-center bg-cover`}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gray-900 opacity-75`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-2xl my-4 lg:text-3xl xl:text-4xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

export default ({ backgroundImage, heading, className }) => {
  return (
    <Container style={{ backgroundImage }} className={className}>
      <OpacityOverlay />
      <HeroContainer style={{}}>
        <Header mode="dark" />
        <Heading>{heading}</Heading>
      </HeroContainer>
    </Container>
  );
};
