export const JvMOptions = [
  {
    type: "Positive Integer",
    default: 20,
    name: "AdaptivePermSizeWeight",
    description: "Weight for perm gen exponential resizing, between 0 and 100",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "AdaptiveSizeDecrementScaleFactor",
    description: "Adaptive size scale down factor for shrinking",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "AdaptiveSizeMajorGCDecayTimeScale",
    description: "Time scale over which major costs decay",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "AdaptiveSizePausePolicy",
    description: "Policy for changing generation size for pause goals",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "AdaptiveSizePolicyCollectionCostMargin",
    description:
      "If collection costs are within margin, reduce both by full delta",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "AdaptiveSizePolicyGCTimeLimitThreshold",
    description: "Number of consecutive collections before gc time limit fires",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "AdaptiveSizePolicyInitializingSteps",
    description: "Number of steps where heuristics is used before data is used",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "AdaptiveSizePolicyOutputInterval",
    description:
      "Collecton interval for printing information; zero =&gt; never",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "AdaptiveSizePolicyReadyThreshold",
    description: "Number of collections before the adaptive sizing is started",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "AdaptiveSizePolicyWeight",
    description: "Weight given to exponential resizing, between 0 and 100",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "AdaptiveSizeThroughPutPolicy",
    description: "Policy for changeing generation size for throughput goals",
  },
  {
    type: "Positive Integer",
    default: 25,
    name: "AdaptiveTimeWeight",
    description: "Weight given to time in adaptive policy, between 0 and 100",
  },
  {
    type: "Boolean",
    default: false,
    name: "AdjustConcurrency",
    description:
      "call thr_setconcurrency at thread create time to avoid LWP starvation on MP systems (For Solaris Only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "AggressiveOpts",
    description: "Enable aggressive optimizations - see arguments.cpp",
  },
  {
    type: "Integer",
    default: 3,
    name: "AliasLevel",
    description:
      "0 for no aliasing, 1 for oop/field/static/array split, 2 for class split, 3 for unique instances",
  },
  {
    type: "Integer",
    default: 4,
    name: "AlignEntryCode",
    description: "aligns entry code to specified value (in bytes)",
  },
  {
    type: "Integer",
    default: -1,
    name: "AllocatePrefetchDistance",
    description: "Distance to prefetch ahead of allocation pointer",
  },
  {
    type: "Integer",
    default: 0,
    name: "AllocatePrefetchInstr",
    description: "Prefetch instruction to prefetch ahead of allocation pointer",
  },
  {
    type: "Integer",
    default: 1,
    name: "AllocatePrefetchLines",
    description: "Number of lines to prefetch ahead of allocation pointer",
  },
  {
    type: "Integer",
    default: 16,
    name: "AllocatePrefetchStepSize",
    description: "Step size in bytes of sequential prefetch instructions",
  },
  {
    type: "Integer",
    default: 1,
    name: "AllocatePrefetchStyle",
    description:
      "0 = no prefetch, 1 = prefetch instructions for each allocation, 2 = use TLAB watermark to gate allocation prefetch, 3 = use BIS instruction on Sparc for allocation prefetch",
  },
  {
    type: "Boolean",
    default: false,
    name: "AllowJNIEnvProxy",
    description: "Allow JNIEnv proxies for jdbx",
  },
  {
    type: "Boolean",
    default: false,
    name: "AllowParallelDefineClass",
    description:
      "Allow parallel defineClass requests for class loaders registering as parallel capable",
  },
  {
    type: "Boolean",
    default: false,
    name: "AllowUserSignalHandlers",
    description:
      "Do not complain if the application installs signal handlers (Solaris &amp; Linux only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysActAsServerClassMachine",
    description: "Always act like a server-class machine",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysCompileLoopMethods",
    description:
      "when using recompilation, never interpret methods containing loops",
  },
  {
    type: "Integer",
    default: 0,
    name: "AlwaysInflate",
    description: "(Unstable) Force inflation",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysLockClassLoader",
    description:
      "Require the VM to acquire the class loader lock before calling loadClass() even for class loaders registering as parallel capable",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysPreTouch",
    description: "It forces all freshly committed pages to be pre-touched.",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysRestoreFPU",
    description:
      "Restore the FPU control word after every JNI call (expensive)",
  },
  {
    type: "Boolean",
    default: false,
    name: "AlwaysTenure",
    description: "Always tenure objects in eden. (ParallelGC only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "AnonymousClasses",
    description: "support sun.misc.Unsafe.defineAnonymousClass (deprecated)",
  },
  {
    type: "Boolean",
    default: false,
    name: "AssertOnSuspendWaitFailure",
    description: "Assert/Guarantee on external suspend wait failure",
  },
  {
    type: "Integer",
    default: 0,
    name: "Atomics",
    description: "(Unsafe,Unstable) Diagnostic - Controls emission of atomics",
  },
  {
    type: "Positive Integer",
    default: 5000,
    name: "AutoGCSelectPauseMillis",
    description: "Automatic GC selection pause threshhold in ms",
  },
  {
    type: "Integer",
    default: 0,
    name: "BackEdgeThreshold",
    description:
      "Interpreter Back edge threshold at which an OSR compilation is invoked",
  },
  {
    type: "Boolean",
    default: false,
    name: "BackgroundCompilation",
    description:
      "A thread requesting compilation is not blocked during compilation",
  },
  {
    type: "Boolean",
    default: false,
    name: "BailoutToInterpreterForThrows",
    description:
      "Compiled methods which throws/catches exceptions will be deopt and intp.",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "BaseFootPrintEstimate",
    description: "Estimate of footprint other than Java Heap",
  },
  {
    type: "Integer",
    default: 0,
    name: "BCEATraceLevel",
    description: "How much tracing to do of bytecode escape analysis estimates",
  },
  {
    type: "Integer",
    default: 2,
    name: "BciProfileWidth",
    description: "number of return bci's to record in ret profile",
  },
  {
    type: "Integer",
    default: 20,
    name: "BiasedLockingBulkRebiasThreshold",
    description:
      "Threshold of number of revocations per type to try to rebias all objects in the heap of that type",
  },
  {
    type: "Integer",
    default: 40,
    name: "BiasedLockingBulkRevokeThreshold",
    description:
      "Threshold of number of revocations per type to permanently revoke biases of all objects in the heap of that type",
  },
  {
    type: "Integer",
    default: 25000,
    name: "BiasedLockingDecayTime",
    description:
      "Decay time (in milliseconds) to re-enable bulk rebiasing of a type after previous bulk rebias",
  },
  {
    type: "Integer",
    default: 4000,
    name: "BiasedLockingStartupDelay",
    description:
      "Number of milliseconds to wait before enabling biased locking",
  },
  {
    type: "Integer",
    default: 5,
    name: "BinarySwitchThreshold",
    description:
      "Minimal number of lookupswitch entries for rewriting to binary switch",
  },
  {
    type: "Boolean",
    default: false,
    name: "BindCMSThreadToCPU",
    description: "Bind CMS Thread to CPU if possible",
  },
  {
    type: "Boolean",
    default: false,
    name: "BindGCTaskThreadsToCPUs",
    description: "Bind GCTaskThreads to CPUs if possible",
  },
  {
    type: "Boolean",
    default: false,
    name: "BlockOffsetArrayUseUnallocatedBlock",
    description:
      "Maintain _unallocated_block in BlockOffsetArray (currently applicable only to CMS collector)",
  },
  {
    type: "Boolean",
    default: false,
    name: "BreakAtWarning",
    description: "Execute breakpoint upon encountering VM warning",
  },
  {
    type: "Boolean",
    default: false,
    name: "BytecodeVerificationLocal",
    description: "Enables the Java bytecode verifier for local classes",
  },
  {
    type: "Boolean",
    default: true,
    name: "BytecodeVerificationRemote",
    description: "Enables the Java bytecode verifier for remote classes",
  },
  {
    type: "Boolean",
    default: false,
    name: "CheckJNICalls",
    description: "Verify all arguments to JNI calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "CheckUnhandledOops",
    description: "Check for unhandled oops in VM code",
  },
  {
    type: "Boolean",
    default: false,
    name: "CheckZapUnusedHeapArea",
    description: "Check zapping of unused heap space",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIBreakAt",
    description: "id of compilation to break at",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIBreakAtOSR",
    description: "id of osr compilation to break at",
  },
  {
    type: "Integer",
    default: 100,
    name: "CICloneLoopTestLimit",
    description: "size limit for blocks heuristically cloned in ciTypeFlow",
  },
  {
    type: "Boolean",
    default: true,
    name: "CICompileNatives",
    description: "compile native methods if supported by the compiler",
  },
  {
    type: "Boolean",
    default: false,
    name: "CICompilerCountPerCPU",
    description: "1 compiler thread for log(N CPUs)",
  },
  {
    type: "Integer",
    default: null,
    name: "CICompilerCount",
    description: "Number of compiler threads to run",
  },
  {
    type: "Boolean",
    default: true,
    name: "CICountOSR",
    description:
      "use a separate counter when assigning ids to osr compilations",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIFireOOMAtDelay",
    description:
      "Wait for this many CI accesses to occur in all compiles before beginning to throw OutOfMemoryErrors in each compile",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIFireOOMAt",
    description:
      "Fire OutOfMemoryErrors throughout CI for testing the compiler (non-negative value throws OOM after this many CI accesses in each compile)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CIPrintCompileQueue",
    description:
      "display the contents of the compile queue whenever a compilation is enqueued",
  },
  {
    type: "Boolean",
    default: false,
    name: "CIPrintCompilerName",
    description:
      "when CIPrint is active, print the name of the active compiler",
  },
  {
    type: "Boolean",
    default: false,
    name: "CIPrintMethodCodes",
    description: "print method bytecodes of the compiled code",
  },
  {
    type: "Boolean",
    default: false,
    name: "CIPrintRequests",
    description: "display every request for compilation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CIPrintTypeFlow",
    description: "print the results of ciTypeFlow analysis",
  },
  {
    type: "Integer",
    default: 0,
    name: "CIStart",
    description: "the id of the first compilation to permit",
  },
  {
    type: "Integer",
    default: 0,
    name: "CIStartOSR",
    description:
      "the id of the first osr compilation to permit (CICountOSR must be on)",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIStop",
    description: "the id of the last compilation to permit",
  },
  {
    type: "Integer",
    default: -1,
    name: "CIStopOSR",
    description:
      "the id of the last osr compilation to permit (CICountOSR must be on)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CITimeEach",
    description: "display timing information after each successful compilation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CITime",
    description: "collect timing information for compilation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CITraceTypeFlow",
    description: "detailed per-bytecode tracing of ciTypeFlow analysis",
  },
  {
    type: "Boolean",
    default: true,
    name: "ClassUnloading",
    description: "Do unloading of classes",
  },
  {
    type: "Integer",
    default: 0,
    name: "ClearFPUAtPark",
    description: "(Unsafe,Unstable)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ClearInterpreterLocals",
    description:
      "Always clear local variables of interpreter activations upon entry",
  },
  {
    type: "Boolean",
    default: true,
    name: "ClipInlining",
    description: "clip inlining if aggregate method exceeds DesiredMethodLimit",
  },
  {
    type: "Positive Integer",
    default: 100,
    name: "CMSAbortablePrecleanMinWorkPerIteration",
    description:
      "(Temporary, subject to experimentation) Nominal minimum work per abortable preclean iteration",
  },
  {
    type: "Integer",
    default: 100,
    name: "CMSAbortablePrecleanWaitMillis",
    description:
      "(Temporary, subject to experimentation) Time that we sleep between iterations when not given enough work per iteration",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSAbortSemantics",
    description: "Whether abort-on-overflow semantics is implemented",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CMSBitMapYieldQuantum",
    description:
      "Bitmap operations should process at most this many bits between yields",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "CMSBootstrapOccupancy",
    description:
      "Percentage CMS generation occupancy at which to initiate CMS collection for bootstrapping collection stats",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSClassUnloadingEnabled",
    description: "Whether class unloading enabled when using CMS GC",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSClassUnloadingMaxInterval",
    description:
      "When CMS class unloading is enabled, the maximum CMS cycle count for which classes may not be unloaded",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSCleanOnEnter",
    description:
      "Clean-on-enter optimization for reducing number of dirty cards",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSCompactWhenClearAllSoftRefs",
    description:
      "Compact when asked to collect CMS gen with clear_all_soft_refs",
  },
  {
    type: "Positive Integer",
    default: 32,
    name: "CMSConcMarkMultiple",
    description: "Size (in cards) of CMS concurrent MT marking task",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSConcurrentMTEnabled",
    description: "Whether multi-threaded concurrent work enabled (if ParNewGC)",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMSCoordinatorYieldSleepCount",
    description:
      "number of times the coordinator GC thread will sleep while yielding before giving up and resuming GC",
  },
  {
    type: "Integer",
    default: 0,
    name: "CMSDictionaryChoice",
    description: "Use BinaryTreeDictionary as default in the CMS generation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSDumpAtPromotionFailure",
    description:
      "Dump useful information about the state of the CMS old generation upon a promotion failure.",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "CMSExpAvgFactor",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponential averages for CMS statistics.",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSExtrapolateSweep",
    description: "CMS: cushion for block demand during sweep",
  },
  {
    type: "Positive Integer",
    default: 1,
    name: "CMS_FLSPadding",
    description:
      "The multiple of deviation from mean to use for buffering against volatility in free list demand.",
  },
  {
    type: "Positive Integer",
    default: 75,
    name: "CMS_FLSWeight",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponentially decating averages for CMS FLS statistics.",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSFullGCsBeforeCompaction",
    description:
      "Number of CMS full collection done before compaction if &gt; 0",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSIncrementalDutyCycleMin",
    description:
      "Lower bound on the duty cycle when CMSIncrementalPacing is enabled (a percentage, 0-100)",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMSIncrementalDutyCycle",
    description:
      "CMS incremental mode duty cycle (a percentage, 0-100).  If CMSIncrementalPacing is enabled, then this is just the initial value",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSIncrementalMode",
    description: "Whether CMS GC should operate in incremental\\\\\\\\  mode",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSIncrementalOffset",
    description:
      "Percentage (0-100) by which the CMS incremental mode duty cycle is shifted to the right within the period between young GCs",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSIncrementalPacing",
    description:
      "Whether the CMS incremental mode duty cycle should be automatically adjusted",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMSIncrementalSafetyFactor",
    description:
      "Percentage (0-100) used to add conservatism when computing the duty cycle",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "CMSIndexedFreeListReplenish",
    description: "Replenish an indexed free list with this number of chunks",
  },
  {
    type: "Integer",
    default: -1,
    name: "CMSInitiatingOccupancyFraction",
    description:
      "Percentage CMS generation occupancy to start a CMS collection cycle. A negative value means that CMSTriggerRatio is used",
  },
  {
    type: "Integer",
    default: -1,
    name: "CMSInitiatingPermOccupancyFraction",
    description:
      "Percentage CMS perm generation occupancy to start a CMScollection cycle. A negative value means that CMSTriggerPermRatio is used",
  },
  {
    type: "Integer",
    default: 98,
    name: "CMSIsTooFullPercentage",
    description:
      "An absolute ceiling above which CMS will always consider the perm gen ripe for collection",
  },
  {
    type: "Double",
    default: 0.95,
    name: "CMSLargeCoalSurplusPercent",
    description:
      "CMS: the factor by which to inflate estimated demand of large block sizes to prevent coalescing with an adjoining block",
  },
  {
    type: "Double",
    default: 1,
    name: "CMSLargeSplitSurplusPercent",
    description:
      "CMS: the factor by which to inflate estimated demand of large block sizes to prevent splitting to supply demand for smaller blocks",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSLoopWarn",
    description: "Warn in case of excessive CMS looping",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSMaxAbortablePrecleanLoops",
    description:
      "(Temporary, subject to experimentation) Maximum number of abortable preclean iterations, if &gt; 0",
  },
  {
    type: "Integer",
    default: 5000,
    name: "CMSMaxAbortablePrecleanTime",
    description:
      "(Temporary, subject to experimentation) Maximum time in abortable preclean in ms",
  },
  {
    type: "Positive Integer",
    default: 1024,
    name: "CMSOldPLABMax",
    description:
      "Max size of CMS gen promotion lab caches per worker per blksize",
  },
  {
    type: "Positive Integer",
    default: 16,
    name: "CMSOldPLABMin",
    description:
      "Min size of CMS gen promotion lab caches per worker per blksize",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "CMSOldPLABNumRefills",
    description:
      "Nominal number of refills of CMS gen promotion lab cache per worker per block size",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMSOldPLABReactivityCeiling",
    description:
      "The clamping of the gain in the feedback loop for on-the-fly PLAB resizing during a scavenge",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "CMSOldPLABReactivityFactor",
    description:
      "The gain in the feedback loop for on-the-fly PLAB resizing during a scavenge",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSOldPLABResizeQuicker",
    description:
      "Whether to react on-the-fly during a scavenge to a sudden change in block demand rate",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "CMSOldPLABToleranceFactor",
    description:
      "The tolerance of the phase-change detector for on-the-fly PLAB resizing during a scavenge",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSOverflowEarlyRestoration",
    description: "Whether preserved marks should be restored early",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSParallelRemarkEnabled",
    description: "Whether parallel remark enabled (only if ParNewGC)",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSParallelSurvivorRemarkEnabled",
    description:
      "Whether parallel remark of survivor space enabled (effective only if CMSParallelRemarkEnabled)",
  },
  {
    type: "Positive Integer",
    default: 16,
    name: "CMSParPromoteBlocksToClaim",
    description:
      "Number of blocks to attempt to claim when refilling CMS LAB for parallel GC.",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSPermGenPrecleaningEnabled",
    description:
      "Whether concurrent precleaning enabled in perm gen (effective only when CMSPrecleaningEnabled is true)",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSPLABRecordAlways",
    description:
      "Whether to always record survivor space PLAB bdries (effective only if CMSParallelSurvivorRemarkEnabled)",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "CMSPrecleanDenominator",
    description:
      "CMSPrecleanNumerator:CMSPrecleanDenominator yields convergence ratio",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSPrecleaningEnabled",
    description: "Whether concurrent precleaning enabled",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "CMSPrecleanIter",
    description: "Maximum number of precleaning iteration passes",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "CMSPrecleanNumerator",
    description:
      "CMSPrecleanNumerator:CMSPrecleanDenominator yields convergence ratio",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSPrecleanRefLists1",
    description: "Preclean ref lists during (initial) preclean phase",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSPrecleanRefLists2",
    description: "Preclean ref lists during abortable preclean phase",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSPrecleanSurvivors1",
    description: "Preclean survivors during (initial) preclean phase",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSPrecleanSurvivors2",
    description: "Preclean survivors during abortable preclean phase",
  },
  {
    type: "Positive Integer",
    default: 1000,
    name: "CMSPrecleanThreshold",
    description: "Don't re-iterate if #dirty cards less than this",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSPrintChunksInDump",
    description:
      "In a dump enabled by CMSDumpAtPromotionFailure, include more detailed information about the free chunks.",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSPrintObjectsInDump",
    description:
      "In a dump enabled by CMSDumpAtPromotionFailure, include more detailed information about the allocated objects.",
  },
  {
    type: "Positive Integer",
    default: 1,
    name: "CMSRemarkVerifyVariant",
    description: "Choose variant (1,2) of verification following remark",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSReplenishIntermediate",
    description: "Replenish all intermediate free-list caches",
  },
  {
    type: "Positive Integer",
    default: 32,
    name: "CMSRescanMultiple",
    description: "Size (in cards) of CMS parallel rescan task",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CMSRevisitStackSize",
    description: "Size of CMS KlassKlass revisit stack",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CMSSamplingGrain",
    description:
      "The minimum distance between eden samples for CMS (see above)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSScavengeBeforeRemark",
    description: "Attempt scavenge before the CMS remark step",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "CMSScheduleRemarkEdenPenetration",
    description:
      "The Eden occupancy % at which to try and schedule remark pause",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CMSScheduleRemarkEdenSizeThreshold",
    description:
      "If Eden used is below this value, don't try to schedule remark",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "CMSScheduleRemarkSamplingRatio",
    description:
      "Start sampling Eden top at least before yg occupancy reaches 1/&lt;ratio&gt; of the size at which we plan to schedule remark",
  },
  {
    type: "Double",
    default: 1.05,
    name: "CMSSmallCoalSurplusPercent",
    description:
      "CMS: the factor by which to inflate estimated demand of small block sizes to prevent coalescing with an adjoining block",
  },
  {
    type: "Double",
    default: 1.1,
    name: "CMSSmallSplitSurplusPercent",
    description:
      "CMS: the factor by which to inflate estimated demand of small block sizes to prevent splitting to supply demand for smaller blocks",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSSplitIndexedFreeListBlocks",
    description:
      "When satisfying batched demand, split blocks from the IndexedFreeList whose size is a multiple of requested size",
  },
  {
    type: "Positive Integer",
    default: 1,
    name: "CMS_SweepPadding",
    description:
      "The multiple of deviation from mean to use for buffering against volatility in inter-sweep duration.",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMS_SweepTimerThresholdMillis",
    description:
      "Skip block flux-rate sampling for an epoch unless inter-sweep duration exceeds this threhold in milliseconds",
  },
  {
    type: "Positive Integer",
    default: 75,
    name: "CMS_SweepWeight",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponentially decaying average for inter-sweep duration",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSTestInFreeList",
    description:
      "Check if the coalesced range is already in the free lists as claimed",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSTraceIncrementalMode",
    description: "Trace CMS incremental mode",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSTraceIncrementalPacing",
    description: "Trace CMS incremental mode pacing computation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSTraceSweeper",
    description: "Trace some actions of the CMS sweeper",
  },
  {
    type: "Boolean",
    default: false,
    name: "CMSTraceThreadState",
    description: "Trace the CMS thread state (enable the trace_state() method)",
  },
  {
    type: "Integer",
    default: 80,
    name: "CMSTriggerPermRatio",
    description:
      "Percentage of MinHeapFreeRatio in the CMS perm generation that is allocated before a CMS collection cycle commences, that also collects the perm generation",
  },
  {
    type: "Integer",
    default: 80,
    name: "CMSTriggerRatio",
    description:
      "Percentage of MinHeapFreeRatio in CMS generation that is allocated before a CMS collection cycle commences",
  },
  {
    type: "Integer",
    default: 2000,
    name: "CMSWaitDuration",
    description: "Time in milliseconds that CMS thread waits for young GC",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "CMSWorkQueueDrainThreshold",
    description: "Don't drain below this size per parallel worker/thief",
  },
  {
    type: "Boolean",
    default: true,
    name: "CMSYield",
    description: "Yield between steps of concurrent mark &amp; sweep",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CMSYieldSleepCount",
    description:
      "number of times a GC thread (minus the coordinator) will sleep while yielding before giving up and resuming GC",
  },
  {
    type: "Integer",
    default: 0,
    name: "CMSYoungGenPerWorker",
    description:
      "The maximum size of young gen chosen by default per GC worker thread available",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CodeCacheExpansionSize",
    description: "Code cache expansion size (in bytes)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CodeCacheFlushingMinimumFreeSpace",
    description: "When less than X space left, start code cache cleaning",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "CodeCacheMinimumFreeSpace",
    description: "When less than X space left, we stop compiling.",
  },
  {
    type: "Positive Integer",
    default: 64,
    name: "CodeCacheSegmentSize",
    description:
      "Code cache segment size (in bytes) - smallest unit of allocation",
  },
  {
    type: "Boolean",
    default: false,
    name: "CollectGen0First",
    description: "Collect youngest generation before each full GC",
  },
  {
    type: "Boolean",
    default: true,
    name: "CompactFields",
    description: "Allocate nonstatic fields in gaps between previous fields",
  },
  {
    type: "Integer",
    default: 0,
    name: "CompilationPolicyChoice",
    description: "which compilation policy (0/1)",
  },
  {
    type: "String",
    default: "null",
    name: "CompileCommandFile",
    description: "Read compiler commands from this file [.hotspot_compiler]",
  },
  {
    type: "String list (comma separated)",
    default: "",
    name: "CompileCommand",
    description:
      "Prepend to .hotspot_compiler; e.g. log,java/lang/String.&lt;init&gt;",
  },
  {
    type: "String list (comma separated)",
    default: "",
    name: "CompileOnly",
    description: "List of methods (pkg/class.name) to restrict compilation to",
  },
  {
    type: "Boolean",
    default: true,
    name: "CompilerThreadHintNoPreempt",
    description: "(Solaris only) Give compiler threads an extra quanta",
  },
  {
    type: "Integer",
    default: -1,
    name: "CompilerThreadPriority",
    description:
      "what priority should compiler threads run at (-1 means no change)",
  },
  {
    type: "Integer",
    default: 0,
    name: "CompilerThreadStackSize",
    description: "Compiler Thread Stack Size (in Kbytes)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CompileTheWorld",
    description:
      "Compile all methods in all classes in bootstrap class path (stress test)",
  },
  {
    type: "Boolean",
    default: true,
    name: "CompileTheWorldPreloadClasses",
    description: "Preload all classes used by a class before start loading",
  },
  {
    type: "Integer",
    default: 0,
    name: "CompileThreshold",
    description:
      "number of interpreted method invocations before (re-)compiling",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "ConcGCThreads",
    description: "Number of threads concurrent gc will use",
  },
  {
    type: "Integer",
    default: 0,
    name: "ConcGCYieldTimeout",
    description:
      "If non-zero, assert that GC threads yield within this # of ms.",
  },
  {
    type: "Boolean",
    default: true,
    name: "ConvertCmpD2CmpF",
    description:
      "Convert cmpD to cmpF when one input is constant in float range",
  },
  {
    type: "Boolean",
    default: true,
    name: "ConvertFloat2IntClipping",
    description: "Convert float2int clipping idiom to integer clipping",
  },
  {
    type: "Boolean",
    default: false,
    name: "ConvertSleepToYield",
    description:
      "Converts sleep(0) to thread yield (may be off for SOLARIS to improve GUI)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ConvertYieldToSleep",
    description:
      "Converts yield to a sleep of MinSleepInterval to simulate Win32 behavior (SOLARIS only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CountBytecodes",
    description: "Count number of bytecodes executed",
  },
  {
    type: "Boolean",
    default: false,
    name: "CountCompiledCalls",
    description: "counts method invocations",
  },
  {
    type: "Integer",
    default: 500,
    name: "CounterDecayMinIntervalLength",
    description: "Min. ms. between invocation of CounterDecay",
  },
  {
    type: "Integer",
    default: 30,
    name: "CounterHalfLifeTime",
    description: "half-life time of invocation counters (in secs)",
  },
  {
    type: "Boolean",
    default: false,
    name: "CountJNICalls",
    description: "counts jni method invocations",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "CPUForCMSThread",
    description:
      "When BindCMSThreadToCPU is true, the CPU to bind CMS thread to",
  },
  {
    type: "Boolean",
    default: false,
    name: "CreateMinidumpOnCrash",
    description: "Create minidump on VM fatal error",
  },
  {
    type: "Boolean",
    default: false,
    name: "DebugDeoptimization",
    description: "Tracing various information while debugging deoptimization",
  },
  {
    type: "Boolean",
    default: false,
    name: "Debugging",
    description:
      "set when executing debug methods in debug.ccp (to prevent triggering assertions)",
  },
  {
    type: "Boolean",
    default: true,
    name: "DebugInlinedCalls",
    description:
      "If false, restricts profiled locations to the root method only",
  },
  {
    type: "Boolean",
    default: false,
    name: "DebugNonSafepoints",
    description: "Generate extra debugging info for non-safepoints in nmethods",
  },
  {
    type: "Boolean",
    default: false,
    name: "DebugVtables",
    description: "add debugging code to vtable dispatch",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "DefaultMaxRAMFraction",
    description:
      "Maximum fraction (1/n) of real memory used for maximum heap size; deprecated: to be renamed to MaxRAMFraction",
  },
  {
    type: "Integer",
    default: -1,
    name: "DefaultThreadPriority",
    description:
      "what native priority threads run at if not specified elsewhere (-1 means no change)",
  },
  {
    type: "Boolean",
    default: false,
    name: "DeferInitialCardMark",
    description:
      "When +ReduceInitialCardMarks, explicitly defer any that may arise from new_pre_store_barrier",
  },
  {
    type: "Integer",
    default: -1,
    name: "DeferPollingPageLoopCount",
    description:
      "(Unsafe,Unstable) Number of iterations in safepoint loop before changing safepoint polling page to RO",
  },
  {
    type: "Integer",
    default: 4000,
    name: "DeferThrSuspendLoopCount",
    description:
      "(Unstable) Number of times to iterate in safepoint loop before blocking VM threads",
  },
  {
    type: "Boolean",
    default: true,
    name: "DelayCompilationDuringStartup",
    description:
      "Delay invoking the compiler until main application class is loaded",
  },
  {
    type: "Boolean",
    default: false,
    name: "DeoptimizeALot",
    description: "deoptimize at every exit from the runtime system",
  },
  {
    type: "Boolean",
    default: false,
    name: "DeoptimizeRandom",
    description:
      "deoptimize random frames on random exit from the runtime system",
  },
  {
    type: "Integer",
    default: 8000,
    name: "DesiredMethodLimit",
    description: "desired max. method size (in bytecodes) after inlining",
  },
  {
    type: "Boolean",
    default: false,
    name: "DieOnSafepointTimeout",
    description: "Die upon failure to reach safepoint (see SafepointTimeout)",
  },
  {
    type: "Boolean",
    default: false,
    name: "DisableAttachMechanism",
    description: "Disable mechanism that allows tools to attach to this VM",
  },
  {
    type: "Boolean",
    default: false,
    name: "DisableExplicitGC",
    description: "Tells whether calling System.gc() does a full GC",
  },
  {
    type: "String list (comma separated)",
    default: "",
    name: "DisableIntrinsic",
    description: "do not expand intrinsics whose (internal) names appear here",
  },
  {
    type: "Boolean",
    default: false,
    name: "DisableStartThread",
    description:
      "Disable starting of additional Java threads (for debugging only)",
  },
  {
    type: "Boolean",
    default: true,
    name: "DisplayVMOutput",
    description:
      "Display all VM output on the tty, independently of LogVMOutput",
  },
  {
    type: "Boolean",
    default: false,
    name: "DisplayVMOutputToStderr",
    description: "If DisplayVMOutput is true, display all VM output to stderr",
  },
  {
    type: "Boolean",
    default: false,
    name: "DisplayVMOutputToStdout",
    description: "If DisplayVMOutput is true, display all VM output to stdout",
  },
  {
    type: "Boolean",
    default: true,
    name: "DontCompileHugeMethods",
    description: "don't compile methods &gt; HugeMethodLimit",
  },
  {
    type: "Integer",
    default: 10,
    name: "DontYieldALotInterval",
    description: "Interval between which yields will be dropped (milliseconds)",
  },
  {
    type: "Boolean",
    default: false,
    name: "DontYieldALot",
    description: "Throw away obvious excess yield calls (for SOLARIS only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "DTraceAllocProbes",
    description: "Enable dtrace probes for object allocation",
  },
  {
    type: "Boolean",
    default: false,
    name: "DTraceMethodProbes",
    description: "Enable dtrace probes for method-entry and method-exit",
  },
  {
    type: "Boolean",
    default: false,
    name: "DTraceMonitorProbes",
    description: "Enable dtrace probes for monitor events",
  },
  {
    type: "Boolean",
    default: false,
    name: "DumpSharedSpaces",
    description:
      "Special mode: JVM reads a class list, loads classes, builds shared spaces, and dumps the shared spaces to a file to be used in future JVM runs.",
  },
  {
    type: "Boolean",
    default: false,
    name: "EagerInitialization",
    description: "Eagerly initialize classes if possible",
  },
  {
    type: "Boolean",
    default: false,
    name: "EagerXrunInit",
    description:
      "Eagerly initialize -Xrun libraries; allows startup profiling, but not all -Xrun libraries may support the state of the VM at this time",
  },
  {
    type: "Integer",
    default: 0,
    name: "EmitSync",
    description:
      "(Unsafe,Unstable) Controls emission of inline sync fast-path code",
  },
  {
    type: "Boolean",
    default: true,
    name: "EnableInvokeDynamic",
    description:
      "support JSR 292 (method handles, invokedynamic, anonymous classes",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "ErgoHeapSizeLimit",
    description:
      "Maximum ergonomically set heap size (in bytes); zero means use MaxRAM / MaxRAMFraction",
  },
  {
    type: "String",
    default: "null",
    name: "ErrorFile",
    description:
      "If an error occurs, save the error data to this file [default: ./hs_err_pid%p.log] (%p replaced with pid)",
  },
  {
    type: "String",
    default: "null",
    name: "ErrorReportServer",
    description: "Override built-in error report server address",
  },
  {
    type: "Boolean",
    default: true,
    name: "EstimateArgEscape",
    description: "Analyze bytecodes to estimate escape state of arguments",
  },
  {
    type: "Integer",
    default: 2000,
    name: "EventLogLength",
    description: "maximum nof events in event log",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "ExitAfterGCNum",
    description: "If non-zero, exit after this GC.",
  },
  {
    type: "Boolean",
    default: false,
    name: "ExplicitGCInvokesConcurrentAndUnloadsClasses",
    description:
      "A System.gc() request invokes a concurrent collection and also unloads classes during such a concurrent gc cycle (effective only when UseConcMarkSweepGC)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ExplicitGCInvokesConcurrent",
    description:
      "A System.gc() request invokes a concurrent collection; (effective only when UseConcMarkSweepGC)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ExtendedDTraceProbes",
    description: "Enable performance-impacting dtrace probes",
  },
  {
    type: "Boolean",
    default: true,
    name: "FailOverToOldVerifier",
    description: "fail over to old verifier when split verifier fails",
  },
  {
    type: "Integer",
    default: null,
    name: "FastAllocateSizeLimit",
    description:
      "/* Note:  This value is zero mod 1&lt;&lt;13 for a cheap sparc set. */ Inline allocations larger than this in doublewords must go slow",
  },
  {
    type: "Integer",
    default: 8,
    name: "FastSuperclassLimit",
    description: "Depth of hardwired instanceof accelerator array",
  },
  {
    type: "Boolean",
    default: true,
    name: "FastTLABRefill",
    description: "Use fast TLAB refill code",
  },
  {
    type: "Integer",
    default: 0,
    name: "FenceInstruction",
    description: "(Unsafe,Unstable) Experimental",
  },
  {
    type: "Integer",
    default: 1,
    name: "FieldsAllocationStyle",
    description:
      "0 - type based with oops first, 1 - with oops last, 2 - oops in super and sub classes are together",
  },
  {
    type: "Boolean",
    default: true,
    name: "FillDelaySlots",
    description: "Fill delay slots (on SPARC only)",
  },
  {
    type: "Boolean",
    default: true,
    name: "FilterSpuriousWakeups",
    description:
      "Prevent spurious or premature wakeups from object.wait (Solaris only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "FLSAlwaysCoalesceLarge",
    description: "CMS: Larger free blocks are always available for coalescing",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "FLSCoalescePolicy",
    description: "CMS: Aggression level for coalescing, increasing from 0 to 4",
  },
  {
    type: "Double",
    default: 0.99,
    name: "FLSLargestBlockCoalesceProximity",
    description:
      "CMS: the smaller the percentage the greater the coalition force",
  },
  {
    type: "Boolean",
    default: false,
    name: "FLSVerifyAllHeapReferences",
    description:
      "Verify that all refs across the FLS boundary are to valid objects",
  },
  {
    type: "Boolean",
    default: false,
    name: "FLSVerifyDictionary",
    description: "Do lots of (expensive) FLS dictionary verification",
  },
  {
    type: "Boolean",
    default: false,
    name: "FLSVerifyIndexTable",
    description: "Do lots of (expensive) FLS index table verification",
  },
  {
    type: "Boolean",
    default: false,
    name: "FLSVerifyLists",
    description: "Do lots of (expensive) FreeListSpace verification",
  },
  {
    type: "Boolean",
    default: false,
    name: "ForceFloatExceptions",
    description: "Force exceptions on FP stack under/overflow",
  },
  {
    type: "Boolean",
    default: false,
    name: "ForceNUMA",
    description: "Force NUMA optimizations on single-node/UMA systems",
  },
  {
    type: "Boolean",
    default: false,
    name: "ForceTimeHighResolution",
    description: "Using high time resolution(For Win32 only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ForceUnreachable",
    description:
      "(amd64) Make all non code cache addresses to be unreachable with rip-rel forcing use of 64bit literal fixups",
  },
  {
    type: "Integer",
    default: 1,
    name: "FreqCountInvocations",
    description: "Scaling factor for branch frequencies (deprecated)",
  },
  {
    type: "Integer",
    default: 0,
    name: "FreqInlineSize",
    description: "maximum bytecode size of a frequent method to be inlined",
  },
  {
    type: "Boolean",
    default: false,
    name: "FullGCALot",
    description:
      "Force full gc at every Nth exit from the runtime system (N=FullGCALotInterval)",
  },
  {
    type: "Boolean",
    default: true,
    name: "FullProfileOnReInterpret",
    description:
      "On re-interpret unc-trap compile next at CompLevel_fast_compile",
  },
  {
    type: "Positive Integer",
    default: 64,
    name: "GCDrainStackTargetSize",
    description:
      "how many entries we'll try to leave on the stack during parallel GC",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "GCExpandToAllocateDelayMillis",
    description: "Delay in ms between expansion and allocation",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "GCHeapFreeLimit",
    description:
      "Minimum percentage of free space after a full GC before an OutOfMemoryError is thrown (used with GCTimeLimit)",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "GCLockerEdenExpansionPercent",
    description:
      "How much the GC can expand the eden by while the GC locker is active (as a percentage)",
  },
  {
    type: "Boolean",
    default: false,
    name: "GCLockerInvokesConcurrent",
    description:
      "The exit of a JNI CS necessitating a scavenge also kicks off a bkgrd concurrent collection",
  },
  {
    type: "Integer",
    default: 100,
    name: "GCOverheadReportingPeriodMS",
    description: "Reporting period for conc GC overhead reporting, in ms",
  },
  {
    type: "Boolean",
    default: false,
    name: "GCOverheadReporting",
    description: "Enables the GC overhead reporting facility",
  },
  {
    type: "Boolean",
    default: true,
    name: "GCParallelVerificationEnabled",
    description: "Enable parallel memory system verification",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "GCPauseIntervalMillis",
    description: "Time slice for MMU specification",
  },
  {
    type: "Positive Integer",
    default: 200,
    name: "GCTaskTimeStampEntries",
    description: "Number of time stamp entries per gc worker thread",
  },
  {
    type: "Positive Integer",
    default: 98,
    name: "GCTimeLimit",
    description:
      "Limit of proportion of time spent in GC before an OutOfMemory error is thrown (used with GCHeapFreeLimit)",
  },
  {
    type: "Positive Integer",
    default: 99,
    name: "GCTimeRatio",
    description: "Adaptive size policy application time to GC time ratio",
  },
  {
    type: "Boolean",
    default: true,
    name: "GenerateCompilerNullChecks",
    description: "Generate explicit null checks for loads/stores/calls",
  },
  {
    type: "Boolean",
    default: true,
    name: "GenerateRangeChecks",
    description: "Generate range checks for array accesses",
  },
  {
    type: "Boolean",
    default: true,
    name: "GenerateSynchronizationCode",
    description:
      "generate locking/unlocking code for synchronized methods and monitors",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "HeapBaseMinAddress",
    description: "OS specific low limit for heap base address",
  },
  {
    type: "Boolean",
    default: false,
    name: "HeapDumpAfterFullGC",
    description: "Dump heap to file after any major stop-world GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "HeapDumpBeforeFullGC",
    description: "Dump heap to file before any major stop-world GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "HeapDumpOnOutOfMemoryError",
    description: "Dump heap to file when java.lang.OutOfMemoryError is thrown",
  },
  {
    type: "String",
    default: "null",
    name: "HeapDumpPath",
    description:
      "When HeapDumpOnOutOfMemoryError is on, the path (filename or directory) of the dump file (defaults to java_pid&lt;pid&gt;.hprof in the working directory)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "HeapDumpSegmentSize",
    description:
      "Approximate segment size when generating a segmented heap dump",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "HeapFirstMaximumCompactionCount",
    description: "The collection count for the first maximum compaction",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "HeapMaximumCompactionInterval",
    description:
      "How often should we maximally compact the heap (not allowing any dead space)",
  },
  {
    type: "String",
    default: "null",
    name: "HPILibPath",
    description: "Specify alternate path to HPI library",
  },
  {
    type: "Integer",
    default: 8000,
    name: "HugeMethodLimit",
    description:
      "don't compile methods larger than this if +DontCompileHugeMethods",
  },
  {
    type: "Boolean",
    default: true,
    name: "IEEEPrecision",
    description: "Enables IEEE precision (for INTEL only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "IgnoreLibthreadGPFault",
    description: "Suppress workaround for libthread GP fault",
  },
  {
    type: "Boolean",
    default: false,
    name: "IgnoreRewrites",
    description:
      "Supress rewrites of bytecodes in the oopmap generator. This is unsafe!",
  },
  {
    type: "Boolean",
    default: false,
    name: "IgnoreUnrecognizedVMOptions",
    description: "Ignore unrecognized VM options",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "InitialCodeCacheSize",
    description: "Initial code cache size (in bytes)",
  },
  {
    type: "Boolean",
    default: false,
    name: "InitialCompileFast",
    description: "Initial compile at CompLevel_fast_compile",
  },
  {
    type: "Boolean",
    default: false,
    name: "InitialCompileReallyFast",
    description:
      "Initial compile at CompLevel_really_fast_compile (no profile)",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "InitialHeapSize",
    description: "Initial heap size (in bytes); zero means OldSize + NewSize",
  },
  {
    type: "Boolean",
    default: true,
    name: "InitializeJavaLangExceptionsErrors",
    description:
      "Initialize various error and exception classes - turn off for individual method debugging",
  },
  {
    type: "Boolean",
    default: true,
    name: "InitializeJavaLangString",
    description:
      "Initialize java.lang.String - turn off for individual method debugging",
  },
  {
    type: "Boolean",
    default: true,
    name: "InitializeJavaLangSystem",
    description:
      "Initialize java.lang.System - turn off for individual method debugging",
  },
  {
    type: "Positive Integer",
    default: 64,
    name: "InitialRAMFraction",
    description: "Fraction (1/n) of real memory used for initial heap size",
  },
  {
    type: "Positive Integer",
    default: 8,
    name: "InitialSurvivorRatio",
    description: "Initial ratio of eden/survivor space size",
  },
  {
    type: "Integer",
    default: 7,
    name: "InitialTenuringThreshold",
    description: "Initial value for tenuring threshold",
  },
  {
    type: "Positive Integer",
    default: 45,
    name: "InitiatingHeapOccupancyPercent",
    description:
      "Percentage of the (entire) heap occupancy to start a concurrent GC cycle. It us used by GCs that trigger a concurrent GC cycle based on the occupancy of the entire heap, not just one of the generations (e.g., G1). A value of 0 denotes 'do constant GC cycles'.",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineAccessors",
    description: "inline accessor methods (get/set)",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineArrayCopy",
    description:
      "inline arraycopy native that is known to be part of base library DLL",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineAtomicLong",
    description: "inline sun.misc.AtomicLong",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineClassNatives",
    description: "inline Class.isInstance, etc",
  },
  {
    type: "Integer",
    default: 20,
    name: "InlineFrequencyRatio",
    description: "Ratio of call site execution to caller method invocation",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineMathNatives",
    description: "inline SinD, CosD, etc.",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineNatives",
    description: "inline natives that are known to be part of base library DLL",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineObjectCopy",
    description: "inline Object.clone and Arrays.copyOf[Range] intrinsics",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineObjectHash",
    description:
      "inline Object::hashCode() native that is known to be part of base library DLL",
  },
  {
    type: "Boolean",
    default: true,
    name: "Inline",
    description: "enable inlining",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineReflectionGetCallerClass",
    description:
      "inline sun.reflect.Reflection.getCallerClass(), known to be part of base library DLL",
  },
  {
    type: "Integer",
    default: 0,
    name: "InlineSmallCode",
    description:
      "Only inline already compiled methods if their code size is less than this",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineThreadNatives",
    description: "inline Thread.currentThread, etc",
  },
  {
    type: "Integer",
    default: 50,
    name: "InlineThrowCount",
    description: "Force inlining of interpreted methods that throw this often",
  },
  {
    type: "Integer",
    default: 200,
    name: "InlineThrowMaxSize",
    description: "Force inlining of throwing methods smaller than this",
  },
  {
    type: "Boolean",
    default: true,
    name: "InlineUnsafeOps",
    description: "inline memory ops (native methods) from sun.misc.Unsafe",
  },
  {
    type: "Boolean",
    default: false,
    name: "InterceptOSException",
    description:
      "Starts debugger when an implicit OS (e.g., null) exception happens",
  },
  {
    type: "Integer",
    default: 33,
    name: "InterpreterProfilePercentage",
    description:
      "NON_TIERED number of method invocations/branches (expressed as % of CompileThreshold) before profiling in the interpreter",
  },
  {
    type: "Boolean",
    default: true,
    name: "JavaMonitorsInStackTrace",
    description:
      "Print info. about Java monitor locks when the stacks are dumped",
  },
  {
    type: "Boolean",
    default: false,
    name: "JavaObjectsInPerm",
    description:
      "controls whether Classes and interned Strings are allocated in perm.  This purely intended to allow debugging issues in production.",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority10_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority1_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority2_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority3_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority4_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority5_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority6_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority7_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority8_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Integer",
    default: -1,
    name: "JavaPriority9_To_OSPriority",
    description: "Map Java priorities to OS priorities",
  },
  {
    type: "Boolean",
    default: true,
    name: "JNIDetachReleasesMonitors",
    description: "JNI DetachCurrentThread releases monitors owned by thread",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "LargePageHeapSizeThreshold",
    description: "Use large pages if max heap is at least this big",
  },
  {
    type: "Boolean",
    default: false,
    name: "LargePagesIndividualAllocationInjectError",
    description: "Fail large pages individual allocation",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "LargePageSizeInBytes",
    description: "Large page size (0 to let VM choose the page size",
  },
  {
    type: "Boolean",
    default: true,
    name: "LazyBootClassLoader",
    description: "Enable/disable lazy opening of boot class path entries",
  },
  {
    type: "Boolean",
    default: false,
    name: "LinkWellKnownClasses",
    description: "Resolve a well known class as soon as its name is seen",
  },
  {
    type: "Boolean",
    default: true,
    name: "LoadLineNumberTables",
    description: "Tells whether the class file parser loads line number tables",
  },
  {
    type: "Boolean",
    default: true,
    name: "LoadLocalVariableTables",
    description:
      "Tells whether the class file parser loads local variable tables",
  },
  {
    type: "Boolean",
    default: true,
    name: "LoadLocalVariableTypeTables",
    description:
      "Tells whether the class file parser loads local variable type tables",
  },
  {
    type: "Boolean",
    default: false,
    name: "LogCompilation",
    description: "Log compilation activity in detail to hotspot.log or LogFile",
  },
  {
    type: "String",
    default: "null",
    name: "LogFile",
    description:
      "If LogVMOutput is on, save VM output to this file [hotspot.log]",
  },
  {
    type: "Boolean",
    default: false,
    name: "LogVMOutput",
    description: "Save VM output to hotspot.log, or to LogFile",
  },
  {
    type: "Integer",
    default: 50,
    name: "LongCompileThreshold",
    description: "Used with +TraceLongCompiles",
  },
  {
    type: "Integer",
    default: -1,
    name: "MallocCatchPtr",
    description: "Hit breakpoint when mallocing/freeing this pointer",
  },
  {
    type: "Integer",
    default: 0,
    name: "MallocVerifyInterval",
    description:
      "if non-zero, verify C heap after every N calls to malloc/realloc/free",
  },
  {
    type: "Integer",
    default: 0,
    name: "MallocVerifyStart",
    description:
      "if non-zero, start verifying C heap after Nth call to malloc/realloc/free",
  },
  {
    type: "Boolean",
    default: false,
    name: "ManagementServer",
    description: "Create JMX Management Server",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MarkStackSizeMax",
    description: "Max size of marking stack",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MarkStackSize",
    description: "Size of marking stack",
  },
  {
    type: "Integer",
    default: 4,
    name: "MarkSweepAlwaysCompactCount",
    description:
      "How often should we fully compact the heap (ignoring the dead space parameters)",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "MarkSweepDeadRatio",
    description:
      "Percentage (0-100) of the old gen allowed as dead wood. Serial mark sweep treats this as both the min and max value. CMS uses this value only if it falls back to mark sweep. Par compact uses a variable scale based on the density of the generation and treats this as the max value when the heap is either completely full or completely empty.  Par compact also has a smaller default value; see arguments.cpp.",
  },
  {
    type: "Integer",
    default: 5,
    name: "MaxBCEAEstimateLevel",
    description: "Maximum number of nested calls that are analyzed by BC EA.",
  },
  {
    type: "Integer",
    default: 150,
    name: "MaxBCEAEstimateSize",
    description: "Maximum bytecode size of a method to be analyzed by BC EA.",
  },
  {
    type: "Integer",
    default: -1,
    name: "MaxDirectMemorySize",
    description: "Maximum total size of NIO direct-buffer allocations",
  },
  {
    type: "Boolean",
    default: true,
    name: "MaxFDLimit",
    description: "Bump the number of file descriptors to max in solaris.",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MaxGCMinorPauseMillis",
    description:
      "Adaptive size policy maximum GC minor pause time goal in msec",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MaxGCPauseMillis",
    description:
      "Adaptive size policy maximum GC pause time goal in msec, or (G1 Only) the max. GC time per MMU time slice",
  },
  {
    type: "Positive Integer",
    default: 70,
    name: "MaxHeapFreeRatio",
    description: "Max percentage of heap free after GC to avoid shrinking",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MaxHeapSize",
    description: "Maximum heap size (in bytes)",
  },
  {
    type: "Integer",
    default: 9,
    name: "MaxInlineLevel",
    description: "maximum number of nested calls that are inlined",
  },
  {
    type: "Integer",
    default: 35,
    name: "MaxInlineSize",
    description: "maximum bytecode size of a method to be inlined",
  },
  {
    type: "Integer",
    default: 3,
    name: "MaxInterpretedSearchLength",
    description: "max. # interp. frames to skip when searching for recompilee",
  },
  {
    type: "Integer",
    default: 1024,
    name: "MaxJavaStackTraceDepth",
    description:
      "Max. no. of lines in the stack trace for Java exceptions (0 means all)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MaxNewSize",
    description:
      "Maximum new generation size (in bytes), 0xFFFFFFFF for 32-bits arch, 0xFFFFFFFFFFFFFFFF for 64-bits arch means set ergonomically",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MaxPermHeapExpansion",
    description: "Max expansion of permanent heap without full GC (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "MaxPermSize",
    description: "Maximum size of permanent generation (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "MaxRAMFraction",
    description:
      "Maximum fraction (1/n) of real memory used for maximum heap size",
  },
  {
    type: "Integer",
    default: 0,
    name: "MaxRAM",
    description: "Real memory size (in bytes) used to set maximum heap size",
  },
  {
    type: "Integer",
    default: 10,
    name: "MaxRecompilationSearchLength",
    description: "max. # frames to inspect searching for recompilee",
  },
  {
    type: "Integer",
    default: 1,
    name: "MaxRecursiveInlineLevel",
    description: "maximum number of nested recursive calls that are inlined",
  },
  {
    type: "Integer",
    default: 15,
    name: "MaxTenuringThreshold",
    description: "Maximum value for tenuring threshold",
  },
  {
    type: "Integer",
    default: 6,
    name: "MaxTrivialSize",
    description: "maximum bytecode size of a trivial method to be inlined",
  },
  {
    type: "Boolean",
    default: false,
    name: "MemProfiling",
    description: "Write memory usage profiling to log file",
  },
  {
    type: "Boolean",
    default: true,
    name: "MethodFlushing",
    description: "Reclamation of zombie and not-entrant methods",
  },
  {
    type: "Integer",
    default: 3,
    name: "MethodHandlePushLimit",
    description: "number of additional stack slots a method handle may push",
  },
  {
    type: "Integer",
    default: 100,
    name: "MethodHistogramCutoff",
    description: "cutoff value for method invoc. histogram (+CountCalls)",
  },
  {
    type: "Integer",
    default: 30,
    name: "MinCodeCacheFlushingInterval",
    description: "Min number of seconds between code cache cleaning sessions",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MinHeapDeltaBytes",
    description: "Min change in heap space due to GC (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 40,
    name: "MinHeapFreeRatio",
    description: "Min percentage of heap free after GC to avoid expansion",
  },
  {
    type: "Integer",
    default: 250,
    name: "MinInliningThreshold",
    description: "min. invocation count a method needs to have to be inlined",
  },
  {
    type: "Integer",
    default: 8,
    name: "MinOopMapAllocation",
    description: "Minimum number of OopMap entries in an OopMapSet",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MinPermHeapExpansion",
    description: "Min expansion of permanent heap (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "MinRAMFraction",
    description:
      "Minimum fraction (1/n) of real memory used for maxmimum heap size on systems with small physical memory size",
  },
  {
    type: "Integer",
    default: 1,
    name: "MinSleepInterval",
    description:
      "Minimum sleep() interval (milliseconds) when ConvertSleepToYield is off (used for SOLARIS)",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "MinSurvivorRatio",
    description: "Minimum ratio of young generation/survivor space size",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "MinTLABSize",
    description: "Minimum allowed TLAB size (in bytes)",
  },
  {
    type: "Integer",
    default: 0,
    name: "MonitorBound",
    description: "Bound Monitor population",
  },
  {
    type: "Boolean",
    default: false,
    name: "MonitorInUseLists",
    description: "Track Monitors for Deflation",
  },
  {
    type: "Boolean",
    default: true,
    name: "MonomorphicArrayCheck",
    description:
      "Uncommon-trap array store checks that require full type check",
  },
  {
    type: "Boolean",
    default: false,
    name: "MustCallLoadClassInternal",
    description: "Call loadClassInternal() rather than loadClass()",
  },
  {
    type: "Type",
    default: "Default",
    name: "Name",
    description: "Description",
  },
  {
    type: "Integer",
    default: 0,
    name: "NativeMonitorFlags",
    description: "(Unstable)",
  },
  {
    type: "Integer",
    default: 20,
    name: "NativeMonitorSpinLimit",
    description: "(Unstable)",
  },
  {
    type: "Integer",
    default: -1,
    name: "NativeMonitorTimeout",
    description: "(Unstable)",
  },
  {
    type: "Boolean",
    default: false,
    name: "NeedsDeoptSuspend",
    description: "True for register window machines (sparc/ia64)",
  },
  {
    type: "Boolean",
    default: false,
    name: "NeverActAsServerClassMachine",
    description: "Never act like a server-class machine",
  },
  {
    type: "Boolean",
    default: false,
    name: "NeverTenure",
    description:
      "Never tenure objects in eden, May tenure on overflow (ParallelGC only)",
  },
  {
    type: "Integer",
    default: 0,
    name: "NewCodeParameter",
    description:
      "Testing Only: Create a dedicated integer parameter before putback",
  },
  {
    type: "Integer",
    default: 2,
    name: "NewRatio",
    description: "Ratio of new/old generation sizes",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "NewSize",
    description: "Initial new generation size (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "NewSizeThreadIncrease",
    description:
      "Additional size added to desired new generation size per non-daemon thread (in bytes)",
  },
  {
    type: "Integer",
    default: 5,
    name: "NmethodSweepCheckInterval",
    description: "Compilers wake up every n seconds to possibly sweep nmethods",
  },
  {
    type: "Integer",
    default: 4,
    name: "NmethodSweepFraction",
    description: "Number of invocations of sweeper to cover all nmethods",
  },
  {
    type: "Boolean",
    default: false,
    name: "NoYieldsInMicrolock",
    description: "Disable yields in microlock",
  },
  {
    type: "Integer",
    default: 20,
    name: "NUMAChunkResizeWeight",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponentially decaying average for AdaptiveNUMAChunkSizing",
  },
  {
    type: "Integer",
    default: 256,
    name: "NUMAPageScanRate",
    description:
      "Maximum number of pages to include in the page scan procedure",
  },
  {
    type: "Integer",
    default: null,
    name: "NUMASpaceResizeRate",
    description: "Do not reallocate more that this amount per collection",
  },
  {
    type: "Boolean",
    default: false,
    name: "NUMAStats",
    description: "Print NUMA stats in detailed heap information",
  },
  {
    type: "Positive Integer",
    default: 512,
    name: "ObjArrayMarkingStride",
    description:
      "Number of ObjArray elements to push onto the marking stack before pushing a continuation entry",
  },
  {
    type: "Integer",
    default: 8,
    name: "ObjectAlignmentInBytes",
    description: "Default object alignment in bytes, 8 is minimum",
  },
  {
    type: "Positive Integer",
    default: 1024,
    name: "OldPLABSize",
    description: "Size of old gen promotion labs (in HeapWords)",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "OldPLABWeight",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponentially decaying average for resizing CMSParPromoteBlocksToClaim.",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "OldSize",
    description: "Initial tenured generation size (in bytes)",
  },
  {
    type: "Boolean",
    default: true,
    name: "OmitStackTraceInFastThrow",
    description: "Omit backtraces for some 'hot' exceptions in optimized code",
  },
  {
    type: "String list (comma separated)",
    default: "",
    name: "OnError",
    description:
      "Run user-defined commands on fatal error; see VMError.cpp for examples",
  },
  {
    type: "String list (comma separated)",
    default: "",
    name: "OnOutOfMemoryError",
    description:
      "Run user-defined commands on first java.lang.OutOfMemoryError",
  },
  {
    type: "Integer",
    default: 0,
    name: "OnStackReplacePercentage",
    description:
      "NON_TIERED number of method invocations/branches (expressed as % of CompileThreshold) before (re-)compiling OSR code",
  },
  {
    type: "Boolean",
    default: true,
    name: "OptimizeMethodHandles",
    description: "when constructing method handles, try to improve them",
  },
  {
    type: "Integer",
    default: 0,
    name: "OptoLoopAlignment",
    description: "Align inner loops to zero relative to this modulus",
  },
  {
    type: "Integer",
    default: -1,
    name: "OSROnlyBCI",
    description: "OSR only at this bci.  Negative values mean exclude that bci",
  },
  {
    type: "Integer",
    default: 10,
    name: "ParallelGCBufferWastePct",
    description: "wasted fraction of parallel allocation buffer.",
  },
  {
    type: "Boolean",
    default: false,
    name: "ParallelGCRetainPLAB",
    description:
      "Retain parallel allocation buffers across scavenges; -- disabled because this currently conflicts with parallel card scanning under certain conditions",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "ParallelGCThreads",
    description: "Number of parallel threads parallel gc will use",
  },
  {
    type: "Boolean",
    default: false,
    name: "ParallelGCVerbose",
    description: "Verbose output for parallel GC.",
  },
  {
    type: "Positive Integer",
    default: 50,
    name: "ParallelOldDeadWoodLimiterMean",
    description:
      "The mean used by the par compact dead wood limiter (a number between 0-100).",
  },
  {
    type: "Positive Integer",
    default: 80,
    name: "ParallelOldDeadWoodLimiterStdDev",
    description:
      "The standard deviation used by the par compact dead wood limiter (a number between 0-100).",
  },
  {
    type: "Boolean",
    default: false,
    name: "ParallelOldGCSplitALot",
    description:
      "Provoke splitting (copying data from a young gen space to multiple destination spaces)",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "ParallelOldGCSplitInterval",
    description: "How often to provoke splitting a young gen space",
  },
  {
    type: "Boolean",
    default: true,
    name: "ParallelRefProcBalancingEnabled",
    description: "Enable balancing of reference processing queues",
  },
  {
    type: "Boolean",
    default: false,
    name: "ParallelRefProcEnabled",
    description: "Enable parallel reference processing whenever possible",
  },
  {
    type: "Integer",
    default: 50,
    name: "ParGCArrayScanChunk",
    description:
      "Scan a subset and push remainder, if array is bigger than this",
  },
  {
    type: "Integer",
    default: 256,
    name: "ParGCCardsPerStrideChunk",
    description:
      "The number of cards in each chunk of the parallel chunks used during card table scanning",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "ParGCDesiredObjsFromOverflowList",
    description:
      "The desired number of objects to claim from the overflow list",
  },
  {
    type: "Integer",
    default: 2,
    name: "ParGCStridesPerThread",
    description:
      "The number of strides per worker thread that we divide up the card table scanning work into",
  },
  {
    type: "Boolean",
    default: true,
    name: "ParGCTrimOverflow",
    description:
      "Eagerly trim the local overflow lists (when ParGCUseLocalOverflow",
  },
  {
    type: "Boolean",
    default: false,
    name: "ParGCUseLocalOverflow",
    description: "Instead of a global overflow list, use local overflow stacks",
  },
  {
    type: "Boolean",
    default: false,
    name: "PauseAtExit",
    description:
      "Pause and wait for keypress on exit if a debugger is attached",
  },
  {
    type: "Boolean",
    default: false,
    name: "PauseAtStartup",
    description:
      "Causes the VM to pause at startup time and wait for the pause file to be removed (default: ./vm.paused.&lt;pid&gt;)",
  },
  {
    type: "String",
    default: "null",
    name: "PauseAtStartupFile",
    description:
      "The file to create and for whose removal to await when pausing at startup. (default: ./vm.paused.&lt;pid&gt;)",
  },
  {
    type: "Positive Integer",
    default: 1,
    name: "PausePadding",
    description: "How much buffer to keep for pause time",
  },
  {
    type: "Integer",
    default: 200,
    name: "PerBytecodeRecompilationCutoff",
    description: "Per-BCI limit on repeated recompilation (-1=&gt;'Inf')",
  },
  {
    type: "Integer",
    default: 4,
    name: "PerBytecodeTrapLimit",
    description: "Limit on traps (of one kind) at a particular BCI",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfAllowAtExitRegistration",
    description: "Allow registration of atexit() methods",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfBypassFileSystemCheck",
    description: "Bypass Win32 file system criteria checks (Windows Only)",
  },
  {
    type: "Integer",
    default: null,
    name: "PerfDataMemorySize",
    description:
      "Size of performance data memory region. Will be rounded up to a multiple of the native os page size.",
  },
  {
    type: "Integer",
    default: 50,
    name: "PerfDataSamplingInterval",
    description: "Data sampling interval in milliseconds",
  },
  {
    type: "String",
    default: "null",
    name: "PerfDataSaveFile",
    description:
      "Save PerfData memory to the specified absolute pathname, %p in the file name if present will be replaced by pid",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfDataSaveToFile",
    description: "Save PerfData memory to hsperfdata_&lt;pid&gt; file on exit",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfDisableSharedMem",
    description: "Store performance data in standard memory",
  },
  {
    type: "Integer",
    default: 1024,
    name: "PerfMaxStringConstLength",
    description: "Maximum PerfStringConstant string length before truncation",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfTraceDataCreation",
    description: "Trace creation of Performance Data Entries",
  },
  {
    type: "Boolean",
    default: false,
    name: "PerfTraceMemOps",
    description: "Trace PerfMemory create/attach/detach calls",
  },
  {
    type: "Integer",
    default: 400,
    name: "PerMethodRecompilationCutoff",
    description:
      "After recompiling N times, stay in the interpreter (-1=&gt;'Inf')",
  },
  {
    type: "Integer",
    default: 100,
    name: "PerMethodTrapLimit",
    description: "Limit on traps (of one kind) in a method (includes inlines)",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "PermGenPadding",
    description: "How much buffer to keep for perm gen sizing",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "PermMarkSweepDeadRatio",
    description:
      "Percentage (0-100) of the perm gen allowed as dead wood. See MarkSweepDeadRatio for collector-specific comments.",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "PermSize",
    description: "Initial size of permanent generation (in bytes)",
  },
  {
    type: "Positive Integer",
    default: 75,
    name: "PLABWeight",
    description:
      "Percentage (0-100) used to weight the current sample when computing exponentially decaying average for ResizePLAB.",
  },
  {
    type: "Boolean",
    default: true,
    name: "PoisonOSREntry",
    description: "Detect abnormal calls to OSR code",
  },
  {
    type: "Boolean",
    default: true,
    name: "PostSpinYield",
    description: "Yield after inner spinning loop",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "PreallocatedOutOfMemoryErrorCount",
    description: "Number of OutOfMemoryErrors preallocated with backtrace",
  },
  {
    type: "Integer",
    default: 10,
    name: "PreBlockSpin",
    description:
      "Number of times to spin in an inflated lock before going to an OS lock",
  },
  {
    type: "Boolean",
    default: false,
    name: "PreferInterpreterNativeStubs",
    description:
      "Use always interpreter stubs for native methods invoked via interpreter",
  },
  {
    type: "Integer",
    default: -1,
    name: "PrefetchCopyIntervalInBytes",
    description:
      "How far ahead to prefetch destination area (&lt;= 0 means off)",
  },
  {
    type: "Integer",
    default: -1,
    name: "PrefetchFieldsAhead",
    description:
      "How many fields ahead to prefetch in oop scan (&lt;= 0 means off)",
  },
  {
    type: "Integer",
    default: -1,
    name: "PrefetchScanIntervalInBytes",
    description: "How far ahead to prefetch scan area (&lt;= 0 means off)",
  },
  {
    type: "Integer",
    default: 0,
    name: "PreInflateSpin",
    description: "Number of times to spin wait before inflation",
  },
  {
    type: "Boolean",
    default: false,
    name: "PreserveAllAnnotations",
    description:
      "Preserve RuntimeInvisibleAnnotations as well as RuntimeVisibleAnnotations",
  },
  {
    type: "Positive Integer",
    default: 1024,
    name: "PreserveMarkStackSize",
    description: "Size for stack used in promotion failure handling",
  },
  {
    type: "Boolean",
    default: false,
    name: "PreSpinYield",
    description: "Yield before inner spinning loop",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "PretenureSizeThreshold",
    description:
      "Maximum size in bytes of objects allocated in DefNew generation; zero means no maximum",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintAdapterHandlers",
    description: "Print code generated for i2c/c2i adapters",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintAdaptiveSizePolicy",
    description: "Print information about AdaptiveSizePolicy",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintAssembly",
    description: "Print assembly code (using external disassembler.so)",
  },
  {
    type: "String",
    default: "null",
    name: "PrintAssemblyOptions",
    description: "Options string passed to disassembler.so",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintBiasedLockingStatistics",
    description: "Print statistics of biased locking in JVM",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintBytecodeHistogram",
    description: "Print histogram of the executed bytecodes",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintBytecodePairHistogram",
    description: "Print histogram of the executed bytecode pairs",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintClassHistogramAfterFullGC",
    description: "Print a class histogram after any major stop-world GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintClassHistogramBeforeFullGC",
    description: "Print a class histogram before any major stop-world GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintClassHistogram",
    description: "Print a histogram of class instances",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCMSInitiationStatistics",
    description: "Statistics for initiating a CMS collection",
  },
  {
    type: "Integer",
    default: 0,
    name: "PrintCMSStatistics",
    description: "Statistics for CMS",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCodeCache2",
    description: "Print detailed info on the compiled_code cache when exiting",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCodeCacheExtension",
    description: "Print extension of code cache",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCommandLineFlags",
    description: "Print flags specified on command line or set by ergonomics",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCompilation",
    description: "Print compilations",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintCompressedOopsMode",
    description: "Print compressed oops base address and encoding mode",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintConcurrentLocks",
    description: "Print java.util.concurrent locks in thread dump",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintDebugInfo",
    description: "Print debug information for all nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintDependencies",
    description: "Print dependency information for all nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintDominators",
    description: "Print out dominator trees for GVN",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintDTraceDOF",
    description: "Print the DTrace DOF passed to the system for JSDT probes",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintExceptionHandlers",
    description:
      "Print exception handler tables for all nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintFlagsFinal",
    description: "Print all VM flags after argument and ergonomic processing",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintFlagsInitial",
    description: "Print all VM flags before argument processing and exit VM",
  },
  {
    type: "Integer",
    default: 0,
    name: "PrintFLSCensus",
    description: "Census for CMS' FreeListSpace",
  },
  {
    type: "Integer",
    default: 0,
    name: "PrintFLSStatistics",
    description: "Statistics for CMS' FreeListSpace",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCApplicationConcurrentTime",
    description: "Print the time the application has been running",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCApplicationStoppedTime",
    description: "Print the time the application has been stopped",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCDateStamps",
    description: "Print date stamps at garbage collect",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCDetails",
    description: "Print more details at garbage collect",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGC",
    description: "Print message at garbage collect",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCTaskTimeStamps",
    description: "Print timestamps for individual gc worker thread tasks",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintGCTimeStamps",
    description: "Print timestamps at garbage collect",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintHeapAtGCExtended",
    description:
      "Prints extended information about the layout of the heap when -XX:+PrintHeapAtGC is set",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintHeapAtGC",
    description: "Print heap layout before and after each GC",
  },
  {
    type: "Boolean",
    default: true,
    name: "PrintHeapAtSIGBREAK",
    description: "Print heap layout in response to SIGBREAK",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintInlining",
    description: "prints inlining optimizations",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintInterpreter",
    description: "Prints the generated interpreter code",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintIntrinsics",
    description: "prints attempted and successful inlining of intrinsics",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintJNIGCStalls",
    description:
      "Print diagnostic message when GC is stalled by JNI critical section",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintJNIResolving",
    description: "Used to implement -v:jni",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintJVMWarnings",
    description: "Prints warnings for unimplemented JVM functions",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMalloc",
    description: "print all malloc/free calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMallocStatistics",
    description: "print malloc/free statistics",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMethodData",
    description: "Print the results of +ProfileInterpreter at end of run",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMethodFlushing",
    description: "print the nmethods being flushed",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMethodHandleStubs",
    description: "Print generated stub code for method handles",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintMiscellaneous",
    description:
      "Prints uncategorized debugging information (requires +Verbose)",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintNativeNMethods",
    description: "Print assembly code for native nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintNMethods",
    description: "Print assembly code for nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintOldPLAB",
    description: "Print (old gen) promotion labs sizing decisions",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintOopAddress",
    description: "Always print the location of the oop",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintParallelOldGCPhaseTimes",
    description:
      "Print the time taken by each parallel old gc phase. PrintGCDetails must also be enabled.",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintPLAB",
    description: "Print (survivor space) promotion labs sizing decisions",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintPromotionFailure",
    description:
      "Print additional diagnostic information following promotion failure",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintReferenceGC",
    description:
      "Print times spent handling reference objects during GC (enabled only when PrintGCDetails)",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintRelocations",
    description: "Print relocation information for all nmethods when generated",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintRevisitStats",
    description: "Print revisit (klass and MDO) stack related information",
  },
  {
    type: "Integer",
    default: 300,
    name: "PrintSafepointStatisticsCount",
    description:
      "total number of safepoint statistics collected before printing them out",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintSafepointStatistics",
    description: "print statistics about safepoint synchronization",
  },
  {
    type: "Integer",
    default: -1,
    name: "PrintSafepointStatisticsTimeout",
    description:
      "print safepoint statistics only when safepoint takes more than PrintSafepointSatisticsTimeout in millis",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintSharedSpaces",
    description: "Print usage of shared spaces",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintSignatureHandlers",
    description: "Print code generated for native method signature handlers",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintStubCode",
    description: "Print generated stub code",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintTenuringDistribution",
    description: "Print tenuring age information",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintTieredEvents",
    description: "Print tiered events notifications",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintTLAB",
    description: "Print various TLAB related information",
  },
  {
    type: "Boolean",
    default: true,
    name: "PrintVMMessages",
    description: "Print vm messages on console",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintVMOptions",
    description: "Print flags that appeared on the command line",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintVMQWaitTime",
    description: "Prints out the waiting time in VM operation queue",
  },
  {
    type: "Boolean",
    default: false,
    name: "PrintVtables",
    description: "print vtables when printing klass",
  },
  {
    type: "Boolean",
    default: true,
    name: "PrintWarnings",
    description: "Prints JVM warnings to output stream",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "ProcessDistributionStride",
    description: "Stride through processors when distributing processes",
  },
  {
    type: "Boolean",
    default: true,
    name: "ProfileDynamicTypes",
    description: "do extra type profiling and use it more aggressively",
  },
  {
    type: "Boolean",
    default: false,
    name: "ProfileInterpreter",
    description: "Profile at the bytecode level during interpretation",
  },
  {
    type: "Boolean",
    default: false,
    name: "ProfileIntervals",
    description:
      "Prints profiles for each interval (see ProfileIntervalsTicks)",
  },
  {
    type: "Integer",
    default: 100,
    name: "ProfileIntervalsTicks",
    description:
      "# of ticks between printing of interval profile (+ProfileIntervals)",
  },
  {
    type: "Integer",
    default: 20,
    name: "ProfileMaturityPercentage",
    description:
      "number of method invocations/branches (expressed as % of CompileThreshold) before using the method's profile",
  },
  {
    type: "Integer",
    default: 1024,
    name: "ProfilerNodeSize",
    description: "Size in K to allocate for the Profile Nodes of each thread",
  },
  {
    type: "Integer",
    default: 25,
    name: "ProfilerNumberOfCompiledMethods",
    description: "# of compiled methods to show in profile",
  },
  {
    type: "Integer",
    default: 25,
    name: "ProfilerNumberOfInterpretedMethods",
    description: "# of interpreted methods to show in profile",
  },
  {
    type: "Integer",
    default: 25,
    name: "ProfilerNumberOfRuntimeStubNodes",
    description: "# of runtime stub nodes to show in profile",
  },
  {
    type: "Integer",
    default: 25,
    name: "ProfilerNumberOfStubMethods",
    description: "# of stub methods to show in profile",
  },
  {
    type: "Integer",
    default: 15,
    name: "ProfilerPCTickThreshold",
    description: "Number of ticks in a PC buckets to be a hotspot",
  },
  {
    type: "Boolean",
    default: false,
    name: "ProfilerPrintByteCodeStatistics",
    description: "Prints byte code statictics when dumping profiler output",
  },
  {
    type: "Boolean",
    default: false,
    name: "ProfilerRecordPC",
    description: "Collects tick for each 16 byte interval of compiled code",
  },
  {
    type: "Boolean",
    default: false,
    name: "ProfileVM",
    description:
      "Profiles ticks that fall within VM (either in the VM Thread or VM code called through stubs)",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "PromotedPadding",
    description: "How much buffer to keep for promotion failure",
  },
  {
    type: "Positive Integer",
    default: 1000,
    name: "PromotionFailureALotCount",
    description:
      "Number of promotion failures occurring at ParGCAllocBuffer refill attempts (ParNew) or promotion attempts (other young collectors)",
  },
  {
    type: "Positive Integer",
    default: 5,
    name: "PromotionFailureALotInterval",
    description: "Total collections between promotion failures alot",
  },
  {
    type: "Boolean",
    default: true,
    name: "ProtectionDomainVerification",
    description:
      "Verifies protection domain before resolution in system dictionary",
  },
  {
    type: "Integer",
    default: -1,
    name: "PSAdaptiveSizePolicyResizeVirtualSpaceAlot",
    description: "Resize the virtual spaces of the young or old generations",
  },
  {
    type: "Boolean",
    default: false,
    name: "PSAdjustTenuredGenForMinorPause",
    description: "Adjust tenured generation to achive a minor pause goal",
  },
  {
    type: "Boolean",
    default: false,
    name: "PSAdjustYoungGenForMajorPause",
    description: "Adjust young generation to achive a major pause goal",
  },
  {
    type: "Boolean",
    default: true,
    name: "PSChunkLargeArrays",
    description: "true: process large arrays in chunks",
  },
  {
    type: "Integer",
    default: 0,
    name: "QueuedAllocationWarningCount",
    description:
      "Number of times an allocation that queues behind a GC will retry before printing a warning",
  },
  {
    type: "Boolean",
    default: true,
    name: "RangeCheckElimination",
    description: "Split loop iterations to eliminate range checks",
  },
  {
    type: "Integer",
    default: 0,
    name: "ReadPrefetchInstr",
    description: "Prefetch instruction to prefetch ahead",
  },
  {
    type: "Integer",
    default: 100,
    name: "ReadSpinIterations",
    description: "Number of read attempts before a yield (spin inner loop)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ReduceSignalUsage",
    description: "Reduce the use of OS signals in Java and/or the VM",
  },
  {
    type: "Integer",
    default: 0,
    name: "RefDiscoveryPolicy",
    description: "Whether reference-based(0) or referent-based(1)",
  },
  {
    type: "Boolean",
    default: true,
    name: "ReflectionWrapResolutionErrors",
    description:
      "Temporary flag for transition to AbstractMethodError wrapped in InvocationTargetException. See 6531596",
  },
  {
    type: "Boolean",
    default: true,
    name: "RegisterFinalizersAtInit",
    description:
      "Register finalizable objects at end of Object.&lt;init&gt; or after allocation",
  },
  {
    type: "Boolean",
    default: true,
    name: "RegisterReferences",
    description:
      "Tells whether the VM should register soft/weak/final/phantom references",
  },
  {
    type: "Boolean",
    default: false,
    name: "RelaxAccessControlCheck",
    description: "Relax the access control checks in the verifier",
  },
  {
    type: "Boolean",
    default: false,
    name: "RequireSharedSpaces",
    description: "Require shared spaces in the permanent generation",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "ReservedCodeCacheSize",
    description:
      "Reserved code cache size (in bytes) - maximum code cache size",
  },
  {
    type: "Boolean",
    default: true,
    name: "ResizeOldPLAB",
    description: "Dynamically resize (old gen) promotion labs",
  },
  {
    type: "Boolean",
    default: true,
    name: "ResizePLAB",
    description: "Dynamically resize (survivor space) promotion labs",
  },
  {
    type: "Boolean",
    default: false,
    name: "ResizeTLAB",
    description: "Dynamically resize tlab size for threads",
  },
  {
    type: "Boolean",
    default: false,
    name: "RestoreMXCSROnJNICalls",
    description: "Restore MXCSR when returning from JNI calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "RewriteBytecodes",
    description: "Allow rewriting of bytecodes (bytecodes are not immutable)",
  },
  {
    type: "Boolean",
    default: false,
    name: "RewriteFrequentPairs",
    description:
      "Rewrite frequently used bytecode pairs into a single bytecode",
  },
  {
    type: "Boolean",
    default: false,
    name: "RotateCMSCollectionTypes",
    description: "Rotate the CMS collections among concurrent and STW",
  },
  {
    type: "Boolean",
    default: false,
    name: "SafepointALot",
    description:
      "Generates a lot of safepoints. Works with GuaranteedSafepointInterval",
  },
  {
    type: "Integer",
    default: 2000,
    name: "SafepointSpinBeforeYield",
    description: "(Unstable)",
  },
  {
    type: "Integer",
    default: 10000,
    name: "SafepointTimeoutDelay",
    description: "Delay in milliseconds for option SafepointTimeout",
  },
  {
    type: "Boolean",
    default: false,
    name: "SafepointTimeout",
    description:
      "Time out and warn or fail after SafepointTimeoutDelay milliseconds if failed to reach safepoint",
  },
  {
    type: "Boolean",
    default: true,
    name: "ScavengeBeforeFullGC",
    description:
      "Scavenge youngest generation before each full GC, used with UseParallelGC",
  },
  {
    type: "Integer",
    default: 1,
    name: "ScavengeRootsInCode",
    description:
      "0: do not allow scavengable oops in the code cache; 1: allow scavenging from the code cache; 2: emit as many constants as the compiler can see",
  },
  {
    type: "Boolean",
    default: false,
    name: "ScavengeWithObjectsInToSpace",
    description: "Allow scavenges to occur when to_space contains objects.",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SegmentedHeapDumpThreshold",
    description:
      "Generate a segmented heap dump (JAVA PROFILE 1.0.2 format) when the heap usage is larger than this",
  },
  {
    type: "Integer",
    default: 0,
    name: "SelfDestructTimer",
    description:
      "Will cause VM to terminate after a given time (in minutes) (0 means off)",
  },
  {
    type: "Boolean",
    default: true,
    name: "SerializeVMOutput",
    description: "Use a mutex to serialize output to tty and hotspot.log",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SharedDummyBlockSize",
    description: "Size of dummy block used to shift heap addresses (in bytes)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SharedMiscCodeSize",
    description: "Size of the shared code area adjacent to the heap (in bytes)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SharedMiscDataSize",
    description: "Size of the shared data area adjacent to the heap (in bytes)",
  },
  {
    type: "Boolean",
    default: true,
    name: "SharedOptimizeColdStart",
    description:
      "At dump time, order shared objects to achieve better cold startup time.",
  },
  {
    type: "Integer",
    default: 2,
    name: "SharedOptimizeColdStartPolicy",
    description:
      "Reordering policy for SharedOptimizeColdStart 0=favor classload-time locality, 1=balanced, 2=favor runtime locality",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SharedReadOnlySize",
    description: "Size of read-only space in permanent generation (in bytes)",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "SharedReadWriteSize",
    description: "Size of read-write space in permanent generation (in bytes)",
  },
  {
    type: "Boolean",
    default: false,
    name: "SharedSkipVerify",
    description:
      "Skip assert() and verify() which page-in unwanted shared objects.",
  },
  {
    type: "Boolean",
    default: false,
    name: "ShowMessageBoxOnError",
    description: "Keep process alive on VM fatal error",
  },
  {
    type: "Boolean",
    default: false,
    name: "ShowSafepointMsgs",
    description: "Show msg. about safepoint synch.",
  },
  {
    type: "Boolean",
    default: false,
    name: "SoftMatchFailure",
    description:
      "If the DFA fails to match a node, print a message and bail out",
  },
  {
    type: "Integer",
    default: 1000,
    name: "SoftRefLRUPolicyMSPerMB",
    description: "Number of milliseconds per MB of free space in the heap",
  },
  {
    type: "Boolean",
    default: true,
    name: "SpecialArraysEquals",
    description: "special version of Arrays.equals(char[],char[])",
  },
  {
    type: "Boolean",
    default: true,
    name: "SpecialStringCompareTo",
    description: "special version of string compareTo",
  },
  {
    type: "Boolean",
    default: true,
    name: "SpecialStringEquals",
    description: "special version of string equals",
  },
  {
    type: "Boolean",
    default: true,
    name: "SpecialStringIndexOf",
    description: "special version of string indexOf",
  },
  {
    type: "Boolean",
    default: true,
    name: "SplitIfBlocks",
    description:
      "Clone compares and control flow through merge points to fold some branches",
  },
  {
    type: "Integer",
    default: 100,
    name: "StackPrintLimit",
    description: "number of stack frames to print in VM-level stack dump",
  },
  {
    type: "Integer",
    default: 0,
    name: "StackRedPages",
    description: "Number of red zone (unrecoverable overflows) pages",
  },
  {
    type: "Integer",
    default: 0,
    name: "StackShadowPages",
    description:
      "Number of shadow zone (for overflow checking) pages this should exceed the depth of the VM and native call stack",
  },
  {
    type: "Boolean",
    default: true,
    name: "StackTraceInThrowable",
    description: "Collect backtrace in throwable when exception happens",
  },
  {
    type: "Integer",
    default: 0,
    name: "StackYellowPages",
    description: "Number of yellow zone (recoverable overflows) pages",
  },
  {
    type: "Boolean",
    default: false,
    name: "StartAttachListener",
    description: "Always start Attach Listener at VM startup",
  },
  {
    type: "Integer",
    default: 200,
    name: "StarvationMonitorInterval",
    description: "Pause between each check in ms",
  },
  {
    type: "Integer",
    default: 0,
    name: "StopInterpreterAt",
    description: "Stops interpreter execution at specified bytecode number",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressCodeBuffers",
    description: "Exercise code buffer expansion and other rare state changes",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressLdcRewrite",
    description: "Force ldc -&gt; ldc_w rewrite during RedefineClasses",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressMethodComparator",
    description: "run the MethodComparator on all loaded methods",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressMethodHandleWalk",
    description: "Process all method handles with MethodHandleWalk",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressNonEntrant",
    description: "Mark nmethods non-entrant at registration",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressReflectiveCode",
    description: "Use inexact types at allocations, etc., to test reflection",
  },
  {
    type: "Boolean",
    default: false,
    name: "StressTieredRuntime",
    description: "Alternate client and server compiler on compile requests",
  },
  {
    type: "Positive Integer",
    default: 1009,
    name: "StringTableSize",
    description: "Number of buckets in the interned String table",
  },
  {
    type: "Boolean",
    default: false,
    name: "SuppressFatalErrorMessage",
    description: "Do NO Fatal Error report [Avoid deadlock]",
  },
  {
    type: "Positive Integer",
    default: 3,
    name: "SurvivorPadding",
    description: "How much buffer to keep for survivor overflow",
  },
  {
    type: "Integer",
    default: 8,
    name: "SurvivorRatio",
    description: "Ratio of eden/survivor space size",
  },
  {
    type: "Integer",
    default: 50,
    name: "SuspendRetryCount",
    description: "Maximum retry count for an external suspend request",
  },
  {
    type: "Integer",
    default: 5,
    name: "SuspendRetryDelay",
    description: "Milliseconds to delay per retry (* current_retry_count)",
  },
  {
    type: "Integer",
    default: 0,
    name: "SyncFlags",
    description: "(Unsafe,Unstable) Experimental Sync flags",
  },
  {
    type: "String",
    default: "null",
    name: "SyncKnobs",
    description: "(Unstable) Various monitor synchronization tunables",
  },
  {
    type: "Integer",
    default: 0,
    name: "SyncVerbose",
    description: "(Unstable)",
  },
  {
    type: "Integer",
    default: 10,
    name: "TargetPLABWastePct",
    description:
      "target wasted space in last buffer as pct of overall allocation",
  },
  {
    type: "Integer",
    default: 50,
    name: "TargetSurvivorRatio",
    description: "Desired percentage of survivor space used after scavenge",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "TenuredGenerationSizeIncrement",
    description: "Adaptive size percentage change in tenured generation",
  },
  {
    type: "Positive Integer",
    default: 2,
    name: "TenuredGenerationSizeSupplementDecay",
    description: "Decay factor to TenuredGenerationSizeIncrement",
  },
  {
    type: "Positive Integer",
    default: 80,
    name: "TenuredGenerationSizeSupplement",
    description: "Supplement to TenuredGenerationSizeIncrement used at startup",
  },
  {
    type: "Integer",
    default: 0,
    name: "ThreadPriorityPolicy",
    description:
      "0 : Normal. VM chooses priorities that are appropriate for normal applications. On Solaris NORM_PRIORITY and above are mapped to normal native priority. Java priorities below NORM_PRIORITY map to lower native priority values. On Windows applications are allowed to use higher native priorities. However, with ThreadPriorityPolicy=0, VM will not use the highest possible native priority, THREAD_PRIORITY_TIME_CRITICAL, as it may interfere with system threads. On Linux thread priorities are ignored because the OS does not support static priority in SCHED_OTHER scheduling class which is the only choice for non-root, non-realtime applications. 1 : Aggressive. Java thread priorities map over to the entire range of native thread priorities. Higher Java thread priorities map to higher native thread priorities. This policy should be used with care, as sometimes it can cause performance degradation in the application and/or the entire system. On Linux this policy requires root privilege.",
  },
  {
    type: "Boolean",
    default: false,
    name: "ThreadPriorityVerbose",
    description: "print priority changes",
  },
  {
    type: "Positive Integer",
    default: null,
    name: "ThreadSafetyMargin",
    description:
      "Thread safety margin is used on fixed-stack LinuxThreads (on Linux/x86 only) to prevent heap-stack collision. Set to 0 to disable this feature",
  },
  {
    type: "Integer",
    default: 0,
    name: "ThreadStackSize",
    description: "Thread Stack Size (in Kbytes)",
  },
  {
    type: "Positive Integer",
    default: 10,
    name: "ThresholdTolerance",
    description: "Allowed collection cost difference between generations",
  },
  {
    type: "Integer",
    default: 10,
    name: "Tier0BackedgeNotifyFreqLog",
    description: "Interpreter (tier 0) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 7,
    name: "Tier0InvokeNotifyFreqLog",
    description: "Interpreter (tier 0) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 200,
    name: "Tier0ProfilingStartPercentage",
    description:
      "Start profiling in interpreter if the counters exceed tier 3 thresholds by the specified percentage",
  },
  {
    type: "Integer",
    default: 14,
    name: "Tier2BackedgeNotifyFreqLog",
    description: "C1 without MDO (tier 2) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 0,
    name: "Tier2BackEdgeThreshold",
    description: "Back edge threshold at which tier 2 compilation is invoked",
  },
  {
    type: "Integer",
    default: 0,
    name: "Tier2CompileThreshold",
    description: "threshold at which tier 2 compilation is invoked",
  },
  {
    type: "Integer",
    default: 11,
    name: "Tier2InvokeNotifyFreqLog",
    description: "C1 without MDO (tier 2) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 13,
    name: "Tier3BackedgeNotifyFreqLog",
    description:
      "C1 with MDO profiling (tier 3) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 7000,
    name: "Tier3BackEdgeThreshold",
    description:
      "Back edge threshold at which tier 3 OSR compilation is invoked",
  },
  {
    type: "Integer",
    default: 2000,
    name: "Tier3CompileThreshold",
    description:
      "Threshold at which tier 3 compilation is invoked (invocation minimum must be satisfied.",
  },
  {
    type: "Integer",
    default: 2,
    name: "Tier3DelayOff",
    description:
      "If C2 queue size is less than this amount per compiler thread allow methods compiled at tier 2 transition to tier 3",
  },
  {
    type: "Integer",
    default: 5,
    name: "Tier3DelayOn",
    description:
      "If C2 queue size grows over this amount per compiler thread stop compiling at tier 3 and start compiling at tier 2",
  },
  {
    type: "Integer",
    default: 200,
    name: "Tier3InvocationThreshold",
    description:
      "Compile if number of method invocations crosses this threshold",
  },
  {
    type: "Integer",
    default: 10,
    name: "Tier3InvokeNotifyFreqLog",
    description:
      "C1 with MDO profiling (tier 3) invocation notification frequency.",
  },
  {
    type: "Integer",
    default: 5,
    name: "Tier3LoadFeedback",
    description:
      "Tier 3 thresholds will increase twofold when C1 queue size reaches this amount per compiler thread",
  },
  {
    type: "Integer",
    default: 100,
    name: "Tier3MinInvocationThreshold",
    description: "Minimum invocation to compile at tier 3",
  },
  {
    type: "Integer",
    default: 40000,
    name: "Tier4BackEdgeThreshold",
    description:
      "Back edge threshold at which tier 4 OSR compilation is invoked",
  },
  {
    type: "Integer",
    default: 15000,
    name: "Tier4CompileThreshold",
    description:
      "Threshold at which tier 4 compilation is invoked (invocation minimum must be satisfied.",
  },
  {
    type: "Integer",
    default: 5000,
    name: "Tier4InvocationThreshold",
    description:
      "Compile if number of method invocations crosses this threshold",
  },
  {
    type: "Integer",
    default: 3,
    name: "Tier4LoadFeedback",
    description:
      "Tier 4 thresholds will increase twofold when C2 queue size reaches this amount per compiler thread",
  },
  {
    type: "Integer",
    default: 600,
    name: "Tier4MinInvocationThreshold",
    description: "Minimum invocation to compile at tier 4",
  },
  {
    type: "Boolean",
    default: false,
    name: "TieredCompilation",
    description: "Enable tiered compilation",
  },
  {
    type: "Integer",
    default: 50,
    name: "TieredCompileTaskTimeout",
    description:
      "Kill compile task if method was not used within given timeout in milliseconds",
  },
  {
    type: "Integer",
    default: 25,
    name: "TieredRateUpdateMaxTime",
    description: "Maximum rate sampling interval (in milliseconds)",
  },
  {
    type: "Integer",
    default: 1,
    name: "TieredRateUpdateMinTime",
    description: "Minimum rate sampling interval (in milliseconds)",
  },
  {
    type: "Integer",
    default: 4,
    name: "TieredStopAtLevel",
    description: "Stop at given compilation level",
  },
  {
    type: "Boolean",
    default: false,
    name: "TimeCompilationPolicy",
    description: "Time the compilation policy",
  },
  {
    type: "Boolean",
    default: false,
    name: "TimeLivenessAnalysis",
    description: "Time computation of bytecode liveness analysis",
  },
  {
    type: "Boolean",
    default: false,
    name: "TimeOopMap2",
    description: "Time calls to GenerateOopMap::compute_map() individually",
  },
  {
    type: "Boolean",
    default: false,
    name: "TimeOopMap",
    description: "Time calls to GenerateOopMap::compute_map() in sum",
  },
  {
    type: "Positive Integer",
    default: 35,
    name: "TLABAllocationWeight",
    description: "Allocation averaging weight",
  },
  {
    type: "Positive Integer",
    default: 64,
    name: "TLABRefillWasteFraction",
    description: "Max TLAB waste at a refill (internal fragmentation)",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "TLABSize",
    description: "Starting TLAB size (in bytes); zero means set ergonomically",
  },
  {
    type: "Boolean",
    default: true,
    name: "TLABStats",
    description: "Print various TLAB related information",
  },
  {
    type: "Positive Integer",
    default: 4,
    name: "TLABWasteIncrement",
    description: "Increment allowed waste at slow allocation",
  },
  {
    type: "Positive Integer",
    default: 1,
    name: "TLABWasteTargetPercent",
    description: "Percentage of Eden that can be wasted",
  },
  {
    type: "Positive Integer",
    default: 1024,
    name: "TotalHandleAllocationLimit",
    description:
      "Threshold for total handle allocation when +TraceHandleAllocation is used",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceAdaptiveGCBoundary",
    description: "Trace young-old boundary moves",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceBiasedLocking",
    description: "Trace biased locking in JVM",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceBlockOffsetTable",
    description: "Print BlockOffsetTable maps",
  },
  {
    type: "Integer",
    default: 0,
    name: "TraceBytecodesAt",
    description: "Traces bytecodes starting with specified bytecode number",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceBytecodes",
    description: "Trace bytecode execution",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCallFixup",
    description: "traces all call fixups",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCardTableModRefBS",
    description: "Print CardTableModRefBS maps",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClassInitialization",
    description: "Trace class initialization",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClassLoadingPreorder",
    description: "Trace all classes loaded in order referenced (not loaded)",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClassLoading",
    description: "Trace all classes loaded",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClassResolution",
    description: "Trace all constant pool resolutions (for debugging)",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClassUnloading",
    description: "Trace unloading of classes",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceClearedExceptions",
    description: "Prints when an exception is forcibly cleared",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCMSState",
    description: "Trace the state of the CMS collection",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCompilationPolicy",
    description: "Trace compilation policy",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCompiledIC",
    description: "Trace changes of compiled IC",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCompileTriggered",
    description: "Trace compile triggered",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceCreateZombies",
    description: "trace creation of zombie nmethods",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceDeoptimization",
    description: "Trace deoptimization",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceDependencies",
    description: "Trace dependencies",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceDerivedPointers",
    description: "Trace traversal of derived pointers on stack",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceExceptions",
    description: "Trace exceptions",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceFinalizerRegistration",
    description: "Trace registration of final references",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceFrequencyInlining",
    description: "Trace frequency based inlining",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceGCTaskManager",
    description: "Trace actions of the GC task manager",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceGCTaskQueue",
    description: "Trace actions of the GC task queues",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceGCTaskThread",
    description: "Trace actions of the GC task threads",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceGen0Time",
    description: "Trace accumulated time for Gen 0 collection",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceGen1Time",
    description: "Trace accumulated time for Gen 1 collection",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceHandleAllocation",
    description:
      "Prints out warnings when suspicious many handles are allocated",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceICBuffer",
    description: "Trace usage of IC buffer",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceICs",
    description: "Trace inline cache changes",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceInlineCacheClearing",
    description: "Trace clearing of inline caches in nmethods",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceInvokeDynamic",
    description: "trace internal invoke dynamic operations",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceItables",
    description: "Trace initialization and use of itables",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceIterativeGVN",
    description: "Print progress during Iterative Global Value Numbering",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceJavaAssertions",
    description: "Trace java language assertions",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceJNICalls",
    description: "Trace JNI calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceJNIHandleAllocation",
    description: "Trace allocation/deallocation of JNI handle blocks",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceJumps",
    description: "Trace assembly jumps in thread ring buffer",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceJVMTIObjectTagging",
    description: "Trace JVMTI object tagging calls",
  },
  {
    type: "String",
    default: "null",
    name: "TraceJVMTI",
    description: "Trace flags for JVMTI functions and events",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceLivenessGen",
    description: "Trace the generation of liveness analysis information",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceLoaderConstraints",
    description: "Trace loader constraints",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceLongCompiles",
    description:
      "Print out every time compilation is longer than a given threashold",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceMethodHandles",
    description: "trace internal method handle operations",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceMethodReplacement",
    description: "Print when methods are replaced do to recompilation",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceMonitorInflation",
    description: "Trace monitor inflation in JVM",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceMonitorMismatch",
    description: "Trace monitor matching failures during OopMapGeneration",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceNewOopMapGenerationDetailed",
    description: "Trace OopMapGeneration: print detailed cell states",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceNewOopMapGeneration",
    description: "Trace OopMapGeneration",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceNMethodInstalls",
    description: "Trace nmethod intallation",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceOopMapGeneration",
    description: "Shows oopmap generation",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceOopMapRewrites",
    description: "Trace rewritting of method oops during oop map generation",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceOSRBreakpoint",
    description: "Trace OSR Breakpoint",
  },
  {
    type: "Boolean",
    default: false,
    name: "TracePageSizes",
    description: "Trace page size selection and usage.",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceParallelOldGCCompactionPhase",
    description: "Trace parallel old gc compaction phase",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceParallelOldGCDensePrefix",
    description: "Trace parallel old gc dense prefix computation",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceParallelOldGCMarkingPhase",
    description: "Trace parallel old gc marking phase",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceParallelOldGCSummaryPhase",
    description: "Trace parallel old gc summary phase",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceParallelOldGCTasks",
    description: "Trace multithreaded GC activity",
  },
  {
    type: "Boolean",
    default: false,
    name: "TracePcPatching",
    description: "Trace usage of frame::patch_pc",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceProtectionDomainVerification",
    description: "Trace protection domain verifcation",
  },
  {
    type: "Integer",
    default: 0,
    name: "TraceRedefineClasses",
    description: "Trace level for JVMTI RedefineClasses",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceRedundantCompiles",
    description:
      "Have compile broker print when a request already in the queue is requested again",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceReferenceGC",
    description: "Trace handling of soft/weak/final/phantom references",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceRegionTasksQueuing",
    description: "Trace the queuing of the region tasks",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceRelocator",
    description: "Trace the bytecode relocator",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceSafepointCleanupTime",
    description:
      "print the break down of clean up tasks performed during safepoint",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceSafepoint",
    description: "Trace safepoint operations",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceStartupTime",
    description: "Trace setup time",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceSuspendWaitFailures",
    description: "Trace external suspend wait failures",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceThreadEvents",
    description: "Trace all thread events",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceTriggers",
    description: "Trace triggers",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceVMOperation",
    description: "Trace vm operations",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceWorkGang",
    description: "Trace activities of work gangs",
  },
  {
    type: "Boolean",
    default: false,
    name: "TraceZapUnusedHeapArea",
    description: "Trace zapping of unused heap space",
  },
  {
    type: "Boolean",
    default: false,
    name: "TransmitErrorReport",
    description: "Enable error report transmission on erroneous termination",
  },
  {
    type: "Boolean",
    default: true,
    name: "TypeProfileCasts",
    description: "treat casts like calls for purposes of type profiling",
  },
  {
    type: "Integer",
    default: 90,
    name: "TypeProfileMajorReceiverPercent",
    description: "% of major receiver type to all profiled receivers",
  },
  {
    type: "Integer",
    default: 2,
    name: "TypeProfileWidth",
    description: "number of receiver types to record in call/cast profile",
  },
  {
    type: "Integer",
    default: 0,
    name: "UnguardOnExecutionViolation",
    description:
      "Unguard page and retry on no-execute fault (Win32 only) 0=off, 1=conservative, 2=aggressive",
  },
  {
    type: "Boolean",
    default: false,
    name: "UnlinkSymbolsALot",
    description:
      "unlink unreferenced symbols from the symbol table at safepoints",
  },
  {
    type: "Boolean",
    default: false,
    name: "UnlockDiagnosticVMOptions",
    description:
      "Enable normal processing of flags relating to field diagnostics",
  },
  {
    type: "Boolean",
    default: false,
    name: "UnsyncloadClass",
    description:
      "Unstable: VM calls loadClass unsynchronized. Custom class loader  must call VM synchronized for findClass and defineClass.",
  },
  {
    type: "Boolean",
    default: true,
    name: "UpdateHotSpotCompilerFileOnError",
    description:
      "Should the system attempt to update the compiler file when an error occurs?",
  },
  {
    type: "Boolean",
    default: true,
    name: "Use24BitFP",
    description: "use FP instructions that produce 24-bit precise results",
  },
  {
    type: "Boolean",
    default: true,
    name: "Use24BitFPMode",
    description: "Set 24-bit FPU mode on a per-compile basis",
  },
  {
    type: "Boolean",
    default: false,
    name: "Use486InstrsOnly",
    description: "Use 80486 Compliant instruction subset",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseAdaptiveGCBoundary",
    description: "Allow young-old boundary to move",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveGenerationSizePolicyAtMajorCollection",
    description: "Use adaptive young-old sizing policies at major collections",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveGenerationSizePolicyAtMinorCollection",
    description: "Use adaptive young-old sizing policies at minor collections",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveNUMAChunkSizing",
    description: "Enable adaptive chunk sizing for NUMA",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveSizeDecayMajorGCCost",
    description: "Adaptive size decays the major cost for long major intervals",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveSizePolicyFootprintGoal",
    description: "Use adaptive minimum footprint as a goal",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAdaptiveSizePolicy",
    description: "Use adaptive generation sizing policies",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseAdaptiveSizePolicyWithSystemGC",
    description: "Use statistics from System.GC for adaptive size policy",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseAddressNop",
    description: "Use '0F 1F [addr]' NOP instructions on x86 cpus",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseAltSigs",
    description:
      "Use alternate signals instead of SIGUSR1 &amp; SIGUSR2 for VM internal signals (Solaris only)",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseAsyncConcMarkSweepGC",
    description:
      "Use Asynchronous Concurrent Mark-Sweep GC in the old generation",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseAutoGCSelectPolicy",
    description: "Use automatic collection selection policy",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseBiasedLocking",
    description: "Enable biased locking in JVM",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseBoundThreads",
    description: "Bind user level threads to kernel threads (for SOLARIS only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCASForSwap",
    description:
      "Do not use swap instructions, but only CAS (in a loop) on SPARC",
  },
  { type: "Boolean", default: true, name: "UseCHA", description: "enable CHA" },
  {
    type: "Boolean",
    default: true,
    name: "UseCMSAdaptiveFreeLists",
    description: "Use Adaptive Free Lists in the CMS generation",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCMSBestFit",
    description: "Use CMS best fit allocation strategy",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCMSCollectionPassing",
    description: "Use passing of collection from background to foreground",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCMSCompactAtFullCollection",
    description: "Use mark sweep compact at full collections",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCMSInitiatingOccupancyOnly",
    description:
      "Only use occupancy as a crierion for starting a CMS collection",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCodeCacheFlushing",
    description: "Attempt to clean the code cache before shutting off compiler",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCompiler",
    description: "use compilation",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCompilerSafepoints",
    description: "Stop at safepoints in compiled code",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCompressedOops",
    description:
      "Use 32-bit object references in 64-bit VM. lp64_product means flag is always constant in 32 bit VM",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseConcMarkSweepGC",
    description: "Use Concurrent Mark-Sweep GC in the old generation",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCondCardMark",
    description: "Check for already marked card before updating card table",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseCounterDecay",
    description: "adjust recompilation counters",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseCountLeadingZerosInstruction",
    description: "Use count leading zeros instruction",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseDetachedThreads",
    description:
      "Use detached threads that are recycled upon termination (for SOLARIS only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseFakeTimers",
    description: "Tells whether the VM should use system time or a fake timer",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseFastAccessorMethods",
    description: "Use fast method entry code for accessor methods",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseFastEmptyMethods",
    description: "Use fast method entry code for empty methods",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseFastJNIAccessors",
    description: "Use optimized versions of Get&lt;Primitive&gt;Field",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseFastSignatureHandlers",
    description: "Use fast signature handlers for native calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseG1GC",
    description: "Use the Garbage-First garbage collector",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseGCOverheadLimit",
    description:
      "Use policy to limit of proportion of time spent in GC before an OutOfMemory error is thrown",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseGCTaskAffinity",
    description: "Use worker affinity when asking for GCTasks",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseHeavyMonitors",
    description: "use heavyweight instead of lightweight Java monitors",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseIncDec",
    description: "Use INC, DEC instructions on x86",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseInlineCaches",
    description: "Use Inline Caches for virtual calls",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseInterpreter",
    description: "Use interpreter for non-compiled methods",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseLargePagesIndividualAllocation",
    description: "Allocate large pages individually for better affinity",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseLargePages",
    description: "Use large page memory",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseLoopCounter",
    description: "Increment invocation counter on backward branch",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseLoopSafepoints",
    description: "Generate Safepoint nodes in every loop",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseLWPSynchronization",
    description:
      "Use LWP-based instead of libthread-based synchronization (SPARC only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseMallocOnly",
    description: "use only malloc/free for allocation (no resource area/arena)",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseMaximumCompactionOnSystemGC",
    description:
      "In the Parallel Old garbage collector maximum compaction for a system GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseMembar",
    description: "(Unstable) Issues membars on thread state transitions",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNewCode2",
    description: "Testing Only: Use the new version while testing",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNewCode3",
    description: "Testing Only: Use the new version while testing",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNewCode",
    description: "Testing Only: Use the new version while testing",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNewLongLShift",
    description: "Use optimized bitwise shift left",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseNewReflection",
    description:
      "Temporary flag for transition to reflection based on dynamic bytecode generation in 1.4; can no longer be turned off in 1.4 JDK, and is unneeded in 1.3 JDK, but marks most places VM changes were needed",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNiagaraInstrs",
    description: "Use Niagara-efficient instruction subset",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseNUMA",
    description: "Use NUMA if available",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseOnStackReplacement",
    description: "calls runtime if invoc. counter overflows in loop",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseOSErrorReporting",
    description: "Let VM fatal error propagate to the OS (ie. WER on Windows)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseParallelGC",
    description: "Use the Parallel Scavenge garbage collector",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseParallelOldGC",
    description: "Use the Parallel Old garbage collector",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseParNewGC",
    description: "Use parallel threads in the new generation.",
  },
  {
    type: "Boolean",
    default: false,
    name: "UsePopCountInstruction",
    description: "Use population count instruction",
  },
  {
    type: "Boolean",
    default: true,
    name: "UsePPCLWSYNC",
    description: "Use lwsync instruction if true, else use slower sync",
  },
  {
    type: "Boolean",
    default: true,
    name: "UsePrefetchQueue",
    description: "Use the prefetch queue during PS promotion",
  },
  {
    type: "Boolean",
    default: true,
    name: "UsePrivilegedStack",
    description: "Enable the security JVM functions",
  },
  {
    type: "Boolean",
    default: true,
    name: "UsePSAdaptiveSurvivorSizePolicy",
    description: "Use adaptive survivor sizing policies",
  },
  {
    type: "Boolean",
    default: false,
    name: "UsePthreads",
    description:
      "Use pthread-based instead of libthread-based synchronization (SPARC only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseRelocIndex",
    description: "use an index to speed random access to relocations",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseRicochetFrames",
    description:
      "use ricochet stack frames for method handle combination, if the platform supports them",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseSerialGC",
    description: "Use the serial garbage collector",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseSharedSpaces",
    description: "Use shared spaces in the permanent generation",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseSignalChaining",
    description:
      "Use signal-chaining to invoke signal handlers installed by the application (Solaris &amp; Linux only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseSpinning",
    description: "Use spinning in monitor inflation and before entry",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseSplitVerifier",
    description: "use split verifier with StackMapTable attributes",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseSSE42Intrinsics",
    description: "SSE4.2 versions of intrinsics",
  },
  {
    type: "Integer",
    default: 99,
    name: "UseSSE",
    description: "Highest supported SSE instructions set on x86/x64",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseStackBanging",
    description:
      "use stack banging for stack overflow checks (required for proper StackOverflow handling; disable only to measure cost of stackbanging)",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseStoreImmI16",
    description: "Use store immediate 16-bits value instruction on x86",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseStrictFP",
    description: "use strict fp if modifier strictfp is set",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseStringCache",
    description: "Enable String cache capabilities on String.java",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseThreadPriorities",
    description: "Use native thread priorities",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseTLAB",
    description: "Use thread-local object allocation",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseTypeProfile",
    description: "Check interpreter profile for historically monomorphic calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseUnalignedLoadStores",
    description: "Use SSE2 MOVDQU instruction for Arraycopy",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseV8InstrsOnly",
    description: "Use SPARC-V8 Compliant instruction subset",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseVectoredExceptions",
    description:
      "Temp Flag - Use Vectored Exceptions rather than SEH (Windows Only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseVMInterruptibleIO",
    description:
      "(Unstable, Solaris-specific) Thread interrupt before or with EINTR for I/O operations results in OS_INTRPT. The default value of this flag is true for JDK 6 and earliers",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseXMMForArrayCopy",
    description: "Use SSE2 MOVQ instruction for Arraycopy",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseXmmI2D",
    description: "Use SSE2 CVTDQ2PD instruction to convert Integer to Double",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseXmmI2F",
    description: "Use SSE2 CVTDQ2PS instruction to convert Integer to Float",
  },
  {
    type: "Boolean",
    default: true,
    name: "UseXmmLoadAndClearUpper",
    description: "Load low part of XMM register and clear upper part",
  },
  {
    type: "Boolean",
    default: false,
    name: "UseXmmRegToRegMoveAll",
    description:
      "Copy all XMM register bits when moving value between registers",
  },
  {
    type: "Integer",
    default: 50,
    name: "V8AtomicOperationUnderLockSpinCount",
    description: "Number of times to spin wait on a v8 atomic operation lock",
  },
  {
    type: "Boolean",
    default: false,
    name: "Verbose",
    description: "Prints additional debugging information from other modes",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyActivationFrameSize",
    description:
      "Verify that activation frame didn't become smaller than its minimal size",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyAdapterSharing",
    description: "Verify that the code for shared adapters is the equivalent",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyAfterGC",
    description: "Verify memory system after GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyAliases",
    description: "perform extra checks on the results of alias analysis",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyBeforeExit",
    description: "Verify system before exiting",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyBeforeGC",
    description: "Verify memory system before GC",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyBeforeIteration",
    description: "Verify memory system before JVMTI iteration",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyBlockOffsetArray",
    description: "Do (expensive!) block offset array verification",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyCompiledCode",
    description:
      "Include miscellaneous runtime verifications in nmethod code; default off because it disturbs nmethod size heuristics",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyDataPointer",
    description: "Verify the method data pointer during interpreter profiling",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyDependencies",
    description: "Exercise and verify the compilation dependency mechanism",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyDuringGC",
    description: "Verify memory system during GC (between phases)",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyFPU",
    description: "Verify FPU state (check for NaN's, etc.)",
  },
  {
    type: "Integer",
    default: 0,
    name: "VerifyGCLevel",
    description: "Generation level at which to start +VerifyBefore/AfterGC",
  },
  {
    type: "Positive Integer",
    default: 0,
    name: "VerifyGCStartAt",
    description: "GC invoke count where +VerifyBefore/AfterGC kicks in",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyIterativeGVN",
    description:
      "Verify Def-Use modifications during sparse Iterative Global Value Numbering",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyJNIFields",
    description: "Verify jfieldIDs for instance fields",
  },
  {
    type: "Boolean",
    default: true,
    name: "VerifyMergedCPBytecodes",
    description: "Verify bytecodes after RedefineClasses constant pool merging",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyMethodHandles",
    description: "perform extra checks when constructing method handles",
  },
  {
    type: "Boolean",
    default: true,
    name: "VerifyObjectStartArray",
    description: "Verify GC object start array if verify before/after",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyOops",
    description: "Do plausibility checks for oops",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyReflectionBytecodes",
    description:
      "Force verification of 1.4 reflection bytecodes. Does not work in situations like that described in 4486457 or for constructors generated for serialization, so can not be enabled in product.",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyRememberedSets",
    description: "Verify GC remembered sets",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyStackAtCalls",
    description: "Verify that the stack pointer is unchanged after calls",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyStack",
    description:
      "Verify stack of each thread when it is entering a runtime call",
  },
  {
    type: "Boolean",
    default: false,
    name: "VerifyThread",
    description: "Watch the thread register for corruption (SPARC only)",
  },
  {
    type: "Boolean",
    default: false,
    name: "VMThreadHintNoPreempt",
    description: "(Solaris only) Give VM thread an extra quanta",
  },
  {
    type: "Integer",
    default: -1,
    name: "VMThreadPriority",
    description: "what priority should VM threads run at (-1 means no change)",
  },
  {
    type: "Integer",
    default: 0,
    name: "VMThreadStackSize",
    description: "Non-Java Thread Stack Size (in Kbytes)",
  },
  {
    type: "Boolean",
    default: false,
    name: "WizardMode",
    description: "Prints much more debugging information",
  },
  {
    type: "Positive Integer",
    default: 20,
    name: "YoungGenerationSizeIncrement",
    description: "Adaptive size percentage change in young generation",
  },
  {
    type: "Positive Integer",
    default: 8,
    name: "YoungGenerationSizeSupplementDecay",
    description: "Decay factor to YoungedGenerationSizeSupplement",
  },
  {
    type: "Positive Integer",
    default: 80,
    name: "YoungGenerationSizeSupplement",
    description: "Supplement to YoungedGenerationSizeIncrement used at startup",
  },
  {
    type: "Positive Integer",
    default: 4096,
    name: "YoungPLABSize",
    description: "Size of young gen promotion labs (in HeapWords)",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZapDeadCompiledLocals",
    description: "Zap dead locals in compiler frames",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZapFillerObjects",
    description: "Zap filler objects with 0xDEAFBABE",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZapJNIHandleArea",
    description: "Zap freed JNI handle space with 0xFEFEFEFE",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZapResourceArea",
    description: "Zap freed resource/arena space with 0xABABABAB",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZapUnusedHeapArea",
    description: "Zap unused heap space with 0xBAADBABE",
  },
  {
    type: "Boolean",
    default: false,
    name: "ZeroTLAB",
    description: "Zero out the newly created TLAB",
  },
];
