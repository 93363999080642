import LegalTemplate from "./LegalTemplate";
import Markdown from "components/misc/Markdown";

import policy from "policy/terms-of-service.md";

export default () => (
  <LegalTemplate>
    <h1>Terms of Service</h1>
    <Markdown content={policy} />
  </LegalTemplate>
);
