import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";

import HomePage from "pages/HomePage";
import BuildGameserverPage from "pages/BuildGameserverPage";
import GameserverPage from "pages/GameserverPage";

import PrivacyPolicyPage from "pages/legal/PrivacyPolicyPage";
import TermsOfServicePage from "pages/legal/TermsOfServicePage";

import "./logger";

const CLERK_PUBLIC_KEY = process.env.REACT_APP_CLERK_PUBLIC_KEY;

const LegalRouter = () => (
  <Routes>
    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="terms-of-service" element={<TermsOfServicePage />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default function App() {
  return (
    <ClerkProvider publishableKey={CLERK_PUBLIC_KEY}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/games/:game" element={<BuildGameserverPage />} />
          <Route
            path="/gameservers/:gameserverId"
            element={<GameserverPage />}
          />
          <Route
            path="/gameservers/:gameserverId"
            element={<GameserverPage />}
          />
          <Route path="/legal/*" element={<LegalRouter />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ClerkProvider>
  );
}
