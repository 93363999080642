import timeUntil from "time-until";
import * as timeago from "timeago.js";

export const TimeAgo = ({ time }) => (
  <span>{time ? timeago.format(time) : "Never"}</span>
);

export const TimeUntil = ({ time }) => {
  return <span>{time ? timeUntil(new Date(time)).string : "Never"}</span>;
};
