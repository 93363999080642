import React, { useCallback, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/default.js";
import LinearProgress from "@mui/joy/LinearProgress";

import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import { Terminal } from "primereact/terminal";
import { TerminalService } from "primereact/terminalservice";
import {
  deleteGameserver,
  pauseGameserver,
  getGameserverLogs,
  sendGameserverCommand,
  useGameserverLogs,
  unpauseGameserver,
  useGameserverDetails,
  getGameserverBackup,
  getQueuePositionGameserver,
  getGameserverEvents,
  startGameserver,
  restartGameserver,
  stopGameserver,
  getGameserverBackups,
  restoreGameserverBackup,
  deleteGameserverBackup,
  getGameserverTasks,
  getGameserverCredential,
  getGameserverFileContent,
  updateGameserverFileContent,
  getGameserverFiles,
  deleteGameserverFile,
  newGameserverBackup,
  newGameserverTask,
  deleteGameserverTask,
  updateGameserverDetails,
  getGameserverFileConfig,
  runGameserverFileConfig,
  getGameserverManifest,
  installGameserverMod,
  disableGameserverTask,
  enableGameserverTask,
  restoreGameserverTimemachine,
  downloadGameserverBackup,
} from "data/gameserver";
import { useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import ScrollToBottom from "react-scroll-to-bottom";
import styled from "styled-components";
import GameIcon from "components/misc/GameIcon";
import { SyncLoader } from "react-spinners";
import { Link } from "react-router-dom";
import {
  Chip,
  TabList,
  TabPanel,
  Table,
  Tabs,
  Tab,
  Switch,
  Tooltip,
  Dropdown,
  MenuButton,
  Button,
  Menu,
  MenuItem,
  ListDivider,
  Input,
  Grid,
  FormControl,
  FormLabel,
  Card,
  CardActions,
  CardContent,
  Box,
  Breadcrumbs,
  Link as LinkHref,
  Typography,
  Checkbox,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  Select,
  Option,
  FormHelperText,
  Autocomplete,
  Textarea,
  createFilterOptions,
  Sheet,
  IconButton,
} from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  Add,
  Cancel,
  CopyAll,
  Delete,
  DeleteOutline,
  Download,
  Edit,
  Folder,
  ImportExport,
  InfoOutlined,
  InsertDriveFileOutlined,
  InstallDesktopOutlined,
  LinkOutlined,
  MoreHorizOutlined,
  Pause,
  PlayCircle,
  RestartAlt,
  Save,
  Stop,
  TableChartOutlined,
  TerminalOutlined,
  VisibilityOff,
} from "@mui/icons-material";
import prettyBytes from "pretty-bytes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { H4, H5 } from "components/typography/heading";
import { Paragraph } from "components/typography/paragraph";
import ReactCodeMirror from "@uiw/react-codemirror";
import { TimeAgo, TimeUntil } from "components/typography/timeago";
import { Controller, useForm } from "react-hook-form";
import { Schedule } from "components/typography/schedule";
import { JvMOptions } from "data/ref/JvmOptions";
import { Editable } from "components/misc/Editable";

import { Roarr as logger } from "roarr";
import { serverProperties } from "data/ref/minecraft/server.properties";
import { title } from "case";
import { fuzzy } from "fast-fuzzy";
import { getPlayerDetails } from "data/tools";
import debounce from "debounce";
import { getModVersionsForModloader, getMods } from "data/mods";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ChipOrb } from "components/misc/ChipOrb";

const Section = tw.section`w-2/3 m-auto mb-10`;
const TerminalContainer = styled(tw.div`px-5 py-3 bg-gray-800 rounded`)`
  font-size: 14px;
  font-family: monospace;
  color: white;
`;
const StyledTerminal = styled(Terminal)`
  height: 30px;
`;
const TerminalScroller = styled(ScrollToBottom)`
  height: 500px;
  margin-bottom: 5px;
  > div {
    overflow-x: hidden;
  }
`;
const GameserverIcon = styled.img`
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-right: 10px;
`;
const GameserverTitle = styled.h1`
  ${tw`mb-5`}
  span:not(.loading-button-desc) {
    ${tw`text-3xl`}
  }
`;

const SectionTitle = tw.h3`
  text-2xl
  mb-4
`;

const LoadingSection = ({ message, buttons, noLoader, children }) => (
  <Section style={{ padding: "4rem", textAlign: "center" }}>
    <h2 style={{ marginBottom: "2rem" }}>{message}</h2>
    {noLoader ? "" : <SyncLoader color="rgb(66,153,225)" />}
    {buttons || ""}
    {children}
  </Section>
);

const Error = tw.div`flex-1 w-full mb-5 mt-5 flex-auto rounded px-4 py-3 sm:px-5 sm:py-4 bg-red-600 text-white flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-red-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const ErrorIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const LoadingButton = ({ onClick, as, children, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const Component = as ? as : Button;
  return (
    <Component
      loading={loading}
      onClick={async (e) => {
        setLoading(true);
        try {
          await onClick(e);
        } finally {
          setLoading(false);
        }
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};

const LoadingSwitch = ({ onChange, as, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const Component = as ? as : Switch;
  return (
    <Component
      disabled={loading}
      onChange={async (e) => {
        setLoading(true);
        try {
          await onChange(e);
        } finally {
          setLoading(false);
        }
      }}
      {...rest}
    />
  );
};

const GameserverBackupTable = ({ gameserver, token, onDelete }) => {
  const [backups, setBackups] = useState(false);
  const [quota, setQuota] = useState(false);
  const [nonce, setNonce] = useState(Date.now());
  const refreshBackups = () => {
    setNonce(Date.now());
  };
  const quotaPct = quota ? Math.ceil((quota.size / quota.limit) * 100) : 0;
  useEffect(() => {
    getGameserverBackups(gameserver.id, token).then((res) => {
      setBackups(() => res.backups);
      setQuota(() => res.quota);
    });
  }, [gameserver.id, token, nonce]);
  const onGameserverBackupRestore = (backupId) => async () => {
    await restoreGameserverBackup(gameserver.id, backupId, token);
  };
  const onGameserverBackupDownload = (backupId) => async () => {
    await downloadGameserverBackup(gameserver.id, backupId, token).then(
      (blob) => {
        const fileLink = document.createElement("a");
        fileLink.href = window.URL.createObjectURL(blob);
        fileLink.download = `${backupId}.tar.gz`;
        fileLink.click();
      }
    );
  };
  const onGameserverBackupDelete = (backupId) => async () => {
    await deleteGameserverBackup(gameserver.id, backupId, token);
    refreshBackups();
  };
  return (
    <div>
      {quota && (
        <div style={{ marginBottom: "10px" }}>
          {/*<h5 style={{ marginBottom: "10px" }}>
            Backup Quota Used <Chip size="sm">{quotaPct}%</Chip>
      </h5>*/}
          <LinearProgress
            size="lg"
            determinate
            value={quotaPct > 100 ? 100 : quotaPct}
            color={
              quotaPct > 85 ? (quotaPct > 95 ? "danger" : "warning") : "primary"
            }
          />
          <small>
            {`${prettyBytes(quota.size)} / ${prettyBytes(quota.limit)}`} disk
            used
          </small>
        </div>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: "5%" }}></th>
            <th style={{ width: "20%" }}>ID</th>
            <th style={{ width: "20%" }}>Size</th>
            <th style={{ width: "20%" }}>Time</th>
            <th style={{ width: "35%" }}></th>
          </tr>
        </thead>
        <tbody>
          {backups === false ? (
            <tr>
              <td colSpan={5} width={100}>
                <LoadingSection message="Fetching backups" />
              </td>
            </tr>
          ) : (
            (backups || []).map((row) => (
              <tr key={row.id}>
                <td>
                  <Tooltip title={row.status} arrow placement="top">
                    <Chip
                      color={
                        row.status === "success"
                          ? "success"
                          : row.status === "pending"
                          ? "primary"
                          : "danger"
                      }
                      size="sm"
                      variant="solid"
                      style={{
                        padding: "0px 7px",
                        display: "inline",
                        marginRight: "5px",
                      }}
                    />
                  </Tooltip>
                </td>
                <td>{row.id}</td>
                <td>{prettyBytes(row.size)}</td>
                <td>
                  <TimeAgo time={row.createdAt} />
                </td>
                <td style={{ textAlign: "right" }}>
                  <LoadingButton
                    variant="soft"
                    color="primary"
                    size="sm"
                    style={{ marginRight: "10px" }}
                    onClick={onGameserverBackupDownload(row.id)}
                  >
                    <Download /> Download
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    size="sm"
                    style={{ marginRight: "10px" }}
                    onClick={onGameserverBackupRestore(row.id)}
                    disabled={[
                      "terminating",
                      "terminated",
                      "pausing",
                      "paused",
                    ].includes(gameserver.status)}
                  >
                    <ImportExport /> Restore
                  </LoadingButton>
                  <LoadingButton
                    variant="soft"
                    color="danger"
                    size="sm"
                    onClick={onGameserverBackupDelete(row.id)}
                  >
                    <DeleteOutline />
                  </LoadingButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

const GameserverTasksTable = ({
  gameserver,
  token,
  refreshToken,
  onDelete,
}) => {
  onDelete = onDelete || {};
  const [quota, setQuota] = useState(false);
  const [tasks, setTasks] = useState(false);
  const [nonce, setNonce] = useState(Date.now());
  //eslint-disable-next-line
  const refreshTasks = () => {
    setNonce(Date.now());
  };
  useEffect(() => {
    getGameserverTasks(gameserver.id, token).then((res) => {
      setTasks(() => res.tasks);
      setQuota(() => res.quota);
    });
  }, [gameserver.id, token, refreshToken, nonce]);

  const quotaPct = quota ? Math.ceil((quota.size / quota.limit) * 100) : 0;

  const onClickDisableTask = (task) => async () => {
    await disableGameserverTask(gameserver.id, task.id, token);
    await refreshTasks();
  };

  const onClickEnableTask = (task) => async () => {
    await enableGameserverTask(gameserver.id, task.id, token);
    await refreshTasks();
  };

  return (
    <div>
      {quota && (
        <div style={{ marginBottom: "10px" }}>
          {/*<h5 style={{ marginBottom: "10px" }}>
            Task Quota Used <Chip size="sm">{quotaPct}%</Chip>
      </h5>*/}
          <LinearProgress
            size="lg"
            determinate
            value={quotaPct > 100 ? 100 : quotaPct}
            color={
              quotaPct > 85 ? (quotaPct > 95 ? "danger" : "warning") : "primary"
            }
          />
          <small>{`${quota.size} / ${quota.limit} tasks`}</small>
        </div>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: "5%" }}></th>
            <th>Type</th>
            <th style={{ width: "20%" }}>Schedule</th>
            <th>Last Run</th>
            <th>Next Run</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tasks === false ? (
            <tr>
              <td colSpan={6} width={100}>
                <LoadingSection message="Fetching tasks" />
              </td>
            </tr>
          ) : (
            (tasks || []).map((row) => (
              <tr key={row.id}>
                <td>
                  {" "}
                  <Chip
                    color={
                      row.status === "active"
                        ? "success"
                        : row.status === "disabled"
                        ? "neutral"
                        : "danger"
                    }
                    size="sm"
                    variant="solid"
                    style={{
                      padding: "0px 7px",
                      display: "inline",
                      marginRight: "5px",
                    }}
                  />
                </td>
                <td>{row.type}</td>
                <td>
                  <Tooltip
                    title={`Cron: ${row.schedule}`}
                    arrow
                    placement="bottom"
                  >
                    <Schedule cron={row.schedule} />
                  </Tooltip>
                </td>
                <td>
                  <Tooltip arrow placement="bottom" title={row.lastRun}>
                    <TimeAgo time={row.lastRun} />
                  </Tooltip>
                </td>
                <td>
                  <Tooltip arrow placement="bottom" title={row.nextRun}>
                    <TimeUntil time={row.nextRun} />
                  </Tooltip>
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.status === "active" ? (
                    <LoadingButton
                      color="neutral"
                      size="sm"
                      onClick={onClickDisableTask(row)}
                      sx={{ marginRight: "10px" }}
                    >
                      <Pause /> Disable Task
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      color="success"
                      size="sm"
                      onClick={onClickEnableTask(row)}
                      sx={{ marginRight: "10px" }}
                    >
                      <PlayCircle /> Enable Task
                    </LoadingButton>
                  )}
                  <LoadingButton
                    variant="soft"
                    color="danger"
                    size="sm"
                    onClick={async () => {
                      await deleteGameserverTask(gameserver.id, row.id, token);
                      refreshTasks();
                    }}
                  >
                    <DeleteOutline />
                  </LoadingButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

const GameserverEventsTable = ({ gameserver, token, onDelete }) => {
  onDelete = onDelete || {};
  const [events, setEvents] = useState(false);
  const [nonce, setNonce] = useState(Date.now());
  //eslint-disable-next-line
  const refreshEvents = () => {
    setNonce(Date.now());
  };
  useEffect(() => {
    getGameserverEvents(gameserver.id, token).then((res) =>
      setEvents(() => res)
    );
  }, [gameserver.id, token, nonce]);

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: "5%" }}></th>
          <th style={{ width: "20%" }}>Type</th>
          <th>Message</th>
          <th style={{ width: "20%" }}>Time</th>
        </tr>
      </thead>
      <tbody>
        {events === false ? (
          <tr>
            <td colSpan={5} width={100}>
              <LoadingSection message="Fetching events" />
            </td>
          </tr>
        ) : (
          (events || []).map((row) => (
            <tr key={row.timestamp}>
              <td>
                <Chip
                  color={row.type === "error" ? "danger" : "success"}
                  size="sm"
                  variant="solid"
                  style={{
                    padding: "0px 7px",
                    display: "inline",
                    marginRight: "5px",
                  }}
                />
              </td>
              <td>{row.type}</td>
              <td>{row.message}</td>
              <td>
                <TimeAgo time={row.timestamp} />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

const GameserverConsole = ({ gameserver, token }) => {
  const [initialLogs, setInitalLogs] = useState([]);
  const [lines, setLines] = useState([]);
  const [terminalBanner, setTerminalBanner] = useState("Connecting...");
  const [time, setTime] = useState(Date.now());
  const onMessage = (e) => {
    if (e.type === "message" && e.data) {
      const message = JSON.parse(e.data).message;
      setLines((l) => [...l, ...message.split("\n")]);
      setTime(Date.now());
    }
  };
  useGameserverLogs(
    gameserver && gameserver.id,
    { onMessage, onOpen: () => setTerminalBanner("Connected.") },
    token
  );
  useEffect(() => {
    const getInitalLogs = async () => {
      if (gameserver && gameserver.id && lines.length === 0) {
        const logs = await getGameserverLogs(
          gameserver && gameserver.id,
          token
        );
        if (logs) {
          setLines(() => logs.split("\n"));
        }
      }
    };
    getInitalLogs();
  }, [lines, gameserver, token]);

  return (
    <div>
      <TerminalContainer>
        {lines.length === 0 && initialLogs.length === 0 && (
          <p>{terminalBanner}</p>
        )}
        <TerminalScroller>
          {[...(lines.length === 0 ? initialLogs : []), ...lines].map((l) => (
            <p>{l}</p>
          ))}
        </TerminalScroller>
        {gameserver && gameserver.status === "online" && (
          <StyledTerminal welcomeMessage={""} prompt="$" />
        )}
      </TerminalContainer>
      <div style={{ marginTop: "1rem", textAlign: "right" }}>
        <span>
          Last log received: <TimeAgo time={time} />
        </span>
        <Button
          onClick={() => {
            setInitalLogs(() => []);
            setLines(() => []);
          }}
          style={{ marginLeft: "10px" }}
          size="sm"
        >
          Reload Logs
        </Button>
      </div>
    </div>
  );
};

export const SFTPCredentials = ({ gameserver, token }) => {
  const [reveal, setReveal] = useState(false);
  const [credential, setCredential] = useState();
  useEffect(() => {
    if (!reveal) {
      return;
    }
    getGameserverCredential(gameserver.id, "sftp", token).then((res) =>
      setCredential(() => res)
    );
  }, [gameserver.id, token, reveal]);
  const sftpPort =
    gameserver && gameserver.ports.find((p) => p.name === "sftp");
  return (
    <Paragraph>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Connection details</FormLabel>
        <Input
          readOnly
          value={sftpPort && `sftp://${sftpPort.ip}:${sftpPort.port}`}
          endDecorator={
            <CopyToClipboard text={`sftp://${sftpPort.ip}:${sftpPort.port}`}>
              <Tooltip arrow placement="top" title="Click to copy to clipboard">
                <Button size="md">
                  <CopyAll /> Copy to clipboard
                </Button>
              </Tooltip>
            </CopyToClipboard>
          }
        />
      </FormControl>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Username</FormLabel>
        <Input
          readOnly
          value="gameserver"
          endDecorator={
            <CopyToClipboard text="gameserver">
              <Tooltip arrow placement="top" title="Click to copy to clipboard">
                <Button size="md">
                  <CopyAll /> Copy to clipboard
                </Button>
              </Tooltip>
            </CopyToClipboard>
          }
        />
      </FormControl>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Password</FormLabel>
        <Input
          readOnly
          value={credential && credential.password}
          endDecorator={
            reveal && credential && credential.password ? (
              <CopyToClipboard text={credential.password}>
                <Tooltip
                  arrow
                  placement="top"
                  title="Click to copy to clipboard"
                >
                  <Button size="md">
                    <CopyAll /> Copy to clipboard
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            ) : (
              <LoadingButton
                size="md"
                onClick={() => setReveal(true)}
                loading={reveal && !credential}
              >
                <VisibilityOff /> Reveal
              </LoadingButton>
            )
          }
        />
      </FormControl>
    </Paragraph>
  );
};

export const MySQLCredentials = ({ gameserver, token }) => {
  const [reveal, setReveal] = useState(false);
  const [credential, setCredential] = useState();
  useEffect(() => {
    if (!reveal) {
      return;
    }
    getGameserverCredential(gameserver.id, "mysql", token).then((res) =>
      setCredential(() => res)
    );
  }, [gameserver.id, token, reveal]);
  const mysqlPort =
    gameserver && gameserver.ports.find((p) => p.name === "mysql");
  return (
    <Paragraph>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Connection details</FormLabel>
        <Input
          readOnly
          value={
            mysqlPort &&
            `mysql://${mysqlPort.ip}:${mysqlPort.port}/ogh-${gameserver.name}`
          }
          endDecorator={
            <CopyToClipboard
              text={`mysql://${mysqlPort.ip}:${mysqlPort.port}/ogh-${gameserver.name}`}
            >
              <Tooltip arrow placement="top" title="Click to copy to clipboard">
                <Button size="md">
                  <CopyAll /> Copy to clipboard
                </Button>
              </Tooltip>
            </CopyToClipboard>
          }
        />
      </FormControl>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Database</FormLabel>
        <Input
          readOnly
          value={`ogh-${gameserver.name}`}
          endDecorator={
            <CopyToClipboard text={`ogh-${gameserver.name}`}>
              <Tooltip arrow placement="top" title="Click to copy to clipboard">
                <Button size="md">
                  <CopyAll /> Copy to clipboard
                </Button>
              </Tooltip>
            </CopyToClipboard>
          }
        />
      </FormControl>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Username</FormLabel>
        <Input
          readOnly
          value="root"
          endDecorator={
            <CopyToClipboard text="root">
              <Tooltip arrow placement="top" title="Click to copy to clipboard">
                <Button size="md">
                  <CopyAll /> Copy to clipboard
                </Button>
              </Tooltip>
            </CopyToClipboard>
          }
        />
      </FormControl>
      <FormControl style={{ marginBottom: "1rem" }}>
        <FormLabel>Password</FormLabel>
        <Input
          readOnly
          value={credential && credential.password}
          endDecorator={
            reveal && credential && credential.password ? (
              <CopyToClipboard text={credential.password}>
                <Tooltip
                  arrow
                  placement="top"
                  title="Click to copy to clipboard"
                >
                  <Button size="md">
                    <CopyAll /> Copy to clipboard
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            ) : (
              <LoadingButton
                size="md"
                onClick={() => setReveal(true)}
                loading={reveal && !credential}
              >
                <VisibilityOff /> Reveal
              </LoadingButton>
            )
          }
        />
      </FormControl>
    </Paragraph>
  );
};

const FileBrowser = ({ gameserver, token }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dir, setDir] = useState(searchParams.get("dir") || "");
  const [loading, setLoading] = useState(false);
  const [quota, setQuota] = useState(false);
  const [files, setFiles] = useState([]);
  const [nonce, setNonce] = useState(Date.now());

  const refreshFiles = () => setNonce(Date.now());

  useEffect(
    () => setSearchParams({ ...searchParams, tab: "filebrowser", dir }),
    [searchParams, setSearchParams, dir]
  );

  useEffect(() => {
    setLoading(true);
    getGameserverFiles(gameserver.id, dir, token).then((res) => {
      if (res.files) {
        const f = res.files.sort((a, b) => {
          if (a.type === "directory" && b.type !== "directory") {
            return -1;
          }
          return a.name - b.name;
          /*return (
            new Date(a.modified).getTime() - new Date(b.modified).getTime()
          );*/
        });
        setFiles(() => f);
        setQuota(() => res.quota);
      } else {
        setFiles(() => res.file);
        setQuota(() => res.quota);
      }
      setLoading(false);
    });
  }, [gameserver.id, dir, token, nonce]);
  const TableRow = ({ file }) => {
    const [dropdown, setDropdown] = useState(false);
    const ref = useRef();
    const bind = {
      ref,
      onMouseDown: (event) => {
        if (event.button === 2) {
          event.preventDefault();
          setDropdown({ x: event.clientX, y: event.clientY });
        }
      },
      onContextMenu: (event) => event.preventDefault(),
    };
    const DropdownItem = (props) => (
      <MenuItem
        size="sm"
        style={{ fontSize: "12px", padding: "0 0.5rem" }}
        sx={{
          "&.MuiMenuItem-root:hover": {
            bgcolor: "transparent",
          },
        }}
        variant="plain"
        {...props}
      />
    );
    const RowDropdown = ({ file, onAction, ...props }) => {
      const buttonRef = useRef();
      const [anchorEl, setAnchorEl] = useState();
      useEffect(() => {
        if (props.anchorEl) {
          return;
        }
        if (buttonRef.current) {
          setAnchorEl(buttonRef.current);
        }
      }, [buttonRef, props.anchorEl]);
      return (
        <div onMouseLeave={props.onMouseLeave}>
          <Dropdown {...props}>
            <MenuButton
              ref={buttonRef}
              variant="plain"
              size="sm"
              style={{ display: props.hideButton ? "none" : "inline-flex" }}
              component={IconButton}
            >
              <MoreHorizOutlined />
            </MenuButton>
            <Menu
              anchorEl={props.anchorEl || anchorEl}
              disablePortal
              keepMounted
              placement="bottom-start"
            >
              <DropdownItem>{file.name}</DropdownItem>
              {file.type === "file" && (
                <DropdownItem
                  onClick={() => onAction({ action: "edit", file })}
                >
                  <Button variant="text" fullWidth>
                    <Edit sx={{ width: "16px" }} /> Edit file
                  </Button>
                </DropdownItem>
              )}
              <DropdownItem>
                <LoadingButton
                  onClick={() => onAction({ action: "delete", file })}
                  color="danger"
                  variant="soft"
                  size="sm"
                  fullWidth
                >
                  <DeleteOutline /> Delete file
                </LoadingButton>
              </DropdownItem>
            </Menu>
          </Dropdown>
        </div>
      );
    };

    const ele = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: dropdown && dropdown.y,
        right: dropdown && dropdown.c,
        bottom: dropdown && dropdown.y,
        left: dropdown && dropdown.x,
      }),
    };

    const onAction = async ({ action }) => {
      logger.debug({ action, file });
      if (action === "edit" && file.type === "file") {
        setDir(file.file);
      } else if (action === "delete") {
        await deleteGameserverFile(gameserver.id, file.file, token);
        await refreshFiles();
      }
    };

    return (
      <>
        <Grid container columns={26} {...bind}>
          <Grid md={1} sx={{ textAlign: "center" }}>
            <Checkbox />
          </Grid>
          <Grid md={14}>
            <div style={{ marginRight: "10px" }}>
              <span>
                {file.type === "directory" ? (
                  <Folder />
                ) : (
                  <InsertDriveFileOutlined />
                )}
              </span>
              <span onClick={() => setDir(file.file)}>{file.name}</span>
            </div>
            <RowDropdown
              open={dropdown !== false}
              hideButton
              sx={{ padding: 0, fontSize: "12px" }}
              onMouseLeave={() => {
                setDropdown(() => false);
              }}
              anchorEl={ele}
              onAction={onAction}
              file={file}
            />
          </Grid>
          <Grid md={6} sx={{ textAlign: "right" }}>
            <span>{prettyBytes(file.size)}</span>
          </Grid>
          <Grid md={4} sx={{ textAlign: "right" }}>
            <TimeAgo time={file.updated} />
          </Grid>
          <Grid md={1} sx={{ textAlign: "center" }}>
            <RowDropdown onAction={onAction} file={file} />
          </Grid>
        </Grid>
      </>
    );
  };

  const quotaPct = quota ? Math.ceil((quota.size / quota.limit) * 100) : 0;

  return (
    <div>
      <Grid container>
        <Grid sm={6}>
          <H4>File Manager</H4>
        </Grid>
        <Grid sm={6}>
          {quota && (
            <div style={{ marginBottom: "10px" }}>
              <LinearProgress
                size="lg"
                determinate
                value={quotaPct > 100 ? 100 : quotaPct}
                color={
                  quotaPct > 85
                    ? quotaPct > 95
                      ? "danger"
                      : "warning"
                    : "primary"
                }
              />
              <small>
                {`${prettyBytes(quota.size)} / ${prettyBytes(quota.limit)}`}{" "}
                disk used
              </small>
            </div>
          )}
        </Grid>
      </Grid>
      <FileBreadcrumb
        path={dir}
        onClick={(path) => {
          setDir(path);
        }}
      />
      {loading ? (
        <LoadingSection />
      ) : !Array.isArray(files) ? (
        <FileEditor gameserver={gameserver} path={dir} token={token} />
      ) : (
        <Card size="sm">
          <Grid
            container
            columns={26}
            sx={{ borderBottom: "1px dashed #eee", paddingLeft: 0 }}
          >
            <Grid
              md={1}
              sx={{
                textAlign: "center",
              }}
            >
              <Checkbox />
            </Grid>
            <Grid md={14}>
              <H5 sx={{ fontWeight: "700" }}>Name</H5>
            </Grid>
            <Grid md={6} sx={{ textAlign: "right" }}>
              <H5 sx={{ fontWeight: "700" }}>Size</H5>
            </Grid>
            <Grid md={4} sx={{ textAlign: "right" }}>
              {" "}
              <H5 sx={{ fontWeight: "700" }}>Modified</H5>
            </Grid>
            <Grid md={1} sx={{ textAlign: "center" }}></Grid>
          </Grid>
          {files.map((file) => (
            <TableRow file={file} />
          ))}
        </Card>
      )}
    </div>
  );
};

const FileBreadcrumb = ({ path, onClick }) => {
  const splits = path.split("/");
  const last = splits.pop();
  const back = splits.join("/"); // eslint-disable-line
  const paths = splits.reduce(
    (acc, val, i) => [...acc, `${acc[i - 1] ? `${acc[i - 1]}/` : ""}${val}`],
    []
  );
  return (
    <Breadcrumbs aria-label="breadcrumbs" sx={{ paddingTop: "0" }}>
      <Typography></Typography>
      <LinkHref color="neutral" as={"span"} onClick={() => onClick("")}>
        root
      </LinkHref>
      {splits.map((item, i) => (
        <LinkHref
          key={item}
          color="neutral"
          as={"span"}
          onClick={() => onClick(paths[i])}
        >
          {item}
        </LinkHref>
      ))}
      <Typography>{last}</Typography>
    </Breadcrumbs>
  );
};

const FileEditor = ({ gameserver, path, token }) => {
  const [value, setValue] = useState("Loading content...");
  useEffect(() => {
    getGameserverFileContent(gameserver.id, path, token).then((res) =>
      setValue(() => res || "")
    );
  }, [gameserver.id, path, token]);

  const onFileChange = useCallback((val) => {
    setValue(val);
  }, []);

  const onSaveChanges = () => {
    updateGameserverFileContent(gameserver.id, path, value, token);
  };

  const toolBar = (
    <>
      <Button
        size="sm"
        color="neutral"
        variant="soft"
        sx={{ marginRight: "10px" }}
      >
        <Cancel /> Cancel Changes
      </Button>
      <Button size="sm" onClick={onSaveChanges}>
        <Save /> Save Changes
      </Button>
    </>
  );
  return (
    <Box>
      <Grid container sx={{ alignItems: "center", marginBottom: "0.5rem" }}>
        <Grid sm={12} sx={{ textAlign: "right" }}>
          {toolBar}
        </Grid>
      </Grid>

      <ReactCodeMirror
        value={value || ""}
        onChange={onFileChange}
        style={{ maxHeight: "80vh", overflowY: "scroll" }}
      />
      <Grid container sx={{ textAlign: "right", marginTop: "1rem" }}>
        <Grid sm={12}>{toolBar}</Grid>
      </Grid>
    </Box>
  );
};

const tabCallback = (tab, value, setTab) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (node) => {
      if (node !== null) {
        node.addEventListener("click", () => {
          setTab(value);
        });
        if (tab === value) {
          node.click();
        }
      }
    },
    [tab, setTab, value]
  );
};

const TaskParameters = ({ type, errors, register }) => {
  console.log({ errors });
  if (type === "command") {
    return (
      <>
        <FormControl>
          <FormLabel>Command</FormLabel>
          <Input
            placeholder="/command, /reset etc..."
            {...register("parameters.command", {
              required: "Command is required",
            })}
          />
          {errors.parameters && errors.parameters.command && (
            <FormHelperText>
              <Paragraph sx={{ color: "red" }}>
                <InfoOutlined />
                {errors.parameters.command.message}
              </Paragraph>
            </FormHelperText>
          )}
        </FormControl>
      </>
    );
  }
  return "";
};

const NewScheduledTaskModal = ({ onSubmit, ...props }) => {
  const { register, getValues, setValue, formState, trigger } = useForm();
  const { errors } = formState;
  const [type, setType] = useState("");
  const onSubmitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const values = await getValues();
      await onSubmit(values);
    }
  };
  const typeRegister = register("type", { required: "Type is required" });
  const typeOnChange = (e, val) => {
    setType(val);
    setValue("type", val, { shouldValidate: true, shouldDirty: true });
  };
  return (
    <Modal {...props}>
      <ModalDialog>
        <DialogTitle>Create a new scheduled task</DialogTitle>
        <DialogContent>Choose a task and a schedule</DialogContent>
        <form>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Task Type</FormLabel>
              <Select
                placeholder="Choose..."
                error={errors.type}
                {...{ typeRegister, onChange: typeOnChange }}
              >
                <Option value="backup">Backup</Option>
                <Option value="command">Command</Option>
              </Select>
              {errors.type && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.type.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Schedule</FormLabel>
              <Select
                placeholder="Choose..."
                error={errors.schedule}
                {...register("schedule", { required: "Schedule is required" })}
              >
                <Option value="*/30 * * * *">Every 30 mins</Option>
                <Option value="0 * * * *">Every hour</Option>
                <Option value="0 */6 * * *">Every six hours</Option>
                <Option value="0 0 * * *">Once a day</Option>
                <Option value="0 0 * * 0">Once a week</Option>
                <Option value="0 0 1 * *">Once a month</Option>
              </Select>
              {errors.schedule && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.schedule.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <TaskParameters {...{ type, errors, register }} />
            <LoadingButton onClick={onSubmitClick}>Submit</LoadingButton>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const NewJavaOptionModal = ({ onSubmit, ...props }) => {
  const { register, watch, setValue, getValues, formState, trigger } =
    useForm();
  const optionWatcher = watch("option");
  const defaultOptionValue = JvMOptions.find((o) => o.name === optionWatcher);
  const { errors } = formState;
  const onSubmitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const values = await getValues();
      await onSubmit(values);
    }
  };
  const { onChange: notMountedOptionOnChange, ...optionRegister } = register(
    "option",
    { required: "Option is required" }
  );
  const filter = createFilterOptions();
  return (
    <Modal {...props}>
      <ModalDialog minWidth={600} maxWidth={600}>
        <DialogTitle>Add a new Java Option</DialogTitle>
        <DialogContent>Choose an option</DialogContent>
        <form>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Java Option</FormLabel>
              <Autocomplete
                placeholder="Choose option..."
                error={errors.option}
                options={JvMOptions.map((o) => ({ label: o.name, id: o.name }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                {...optionRegister}
                onChange={(e, value) => {
                  if (value && typeof value === "string") {
                    setValue("option", value);
                  } else if (value && value.id) {
                    setValue("option", value.id);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.id
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue);
                  }
                  return filtered;
                }}
              />
              {defaultOptionValue && (
                <FormHelperText>
                  <Paragraph textColor="neutral">
                    {defaultOptionValue.description}
                  </Paragraph>
                </FormHelperText>
              )}
              {errors.option && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.option.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Value</FormLabel>
              <Input
                placeholder={`${defaultOptionValue?.default}`}
                error={errors.value}
                {...register("value")}
              />
              {errors.value && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.value.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton onClick={onSubmitClick}>Submit</LoadingButton>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

const parseJvmOpts = (optionsString) => {
  const log = logger.child({ namespace: "parseJvmOpts" });
  log.debug(optionsString);

  if (optionsString === "") {
    return [];
  }
  const options = ` ${optionsString.trim()}`.split(" -");

  const res = options.reduce((acc, val) => {
    const option = {};
    if (val.substr(0, 3) === "XX:") {
      option.class = "advanced";
      if (val.substr(0, 4) === "XX:+" || val.substr(0, 4) === "XX:-") {
        option.type = "Boolean";
        option.name = val.replace("XX:+", "").replace("XX:-", "");
        option.value = val.indexOf(":+") !== -1;
      } else {
        option.type = "String";
        const [n, v] = val.replace("XX:", "").split("=");
        option.name = n;
        option.value = v;
      }
    } else if (val.substr(0, 1) === "X") {
      option.class = "non-standard";
      if (val.indexOf("=") !== -1) {
        const [n, v] = val.replace(/^X/, "").split("=");
        option.name = n.replace(/^-/, "");
        option.value = v.replace(/^-/, "");
      } else if (val.indexOf(":") !== -1) {
        const [n, v] = val.replace(/^X/, "").split(":");
        option.name = n.replace(/^-/, "");
        option.value = v.replace(/^-/, "");
      } else {
        option.name = val.replace(/^-/, "");
        option.value = "";
      }
    } else {
      option.class = "standard";
      if (val.indexOf("=") !== -1) {
        const [n, v] = val.replace(/^X/, "").split("=");
        option.name = n.replace(/^-/, "");
        option.value = v.replace(/^-/, "");
      } else if (val.indexOf(":") !== -1) {
        const [n, v] = val.split(":");
        option.name = n.replace(/^-/, "");
        option.value = v.replace(/^-/, "");
      } else {
        option.name = val.replace(/^-/, "");
        option.value = "";
      }
    }
    if (option.name === "") {
      return acc;
    }
    return [...acc, option];
  }, []);
  return res;
};

const stringifyJvmOpts = (opts) => {
  if (opts.length === 0) {
    return "";
  }
  const res = opts
    .reduce(
      (acc, val) =>
        `${acc} -${val.class === "non-standard" ? "X" : ""}${
          val.class === "advanced" ? "XX:" : ""
        }${val.type === "Boolean" ? (val.value ? "+" : "-") : ""}${val.name}${
          val.value && val.type !== "Boolean"
            ? val.class === "advanced"
              ? "="
              : ":"
            : ""
        }${val.type !== "Boolean" ? val.value : ""}`,
      ""
    )
    .trim();
  if (res === "-") {
    return "";
  }
  return res;
};

const jvmPresets = {
  "-XX:+UseG1GC -XX:+ParallelRefProcEnabled -XX:MaxGCPauseMillis=200 -XX:+UnlockExperimentalVMOptions -XX:+DisableExplicitGC -XX:+AlwaysPreTouch -XX:G1NewSizePercent=30 -XX:G1MaxNewSizePercent=40 -XX:G1HeapRegionSize=8M -XX:G1ReservePercent=20 -XX:G1HeapWastePercent=5 -XX:G1MixedGCCountTarget=4 -XX:InitiatingHeapOccupancyPercent=15 -XX:G1MixedGCLiveThresholdPercent=90 -XX:G1RSetUpdatingPauseTimePercent=5 -XX:SurvivorRatio=32 -XX:+PerfDisableSharedMem -XX:MaxTenuringThreshold=1 -Dusing.aikars.flags:https://mcflags.emc.gs -Daikars.new.flags:true":
    "aikars",
  "": "none",
};

const getCurrentJvmPreset = (optionsString) => {
  if (jvmPresets[optionsString]) {
    return jvmPresets[optionsString];
  }
  if (optionsString !== "") {
    return "custom";
  }
};

const getJvmPresetString = (presetName) => {
  const preset = Object.entries(jvmPresets).find(
    ([key, val]) => val === presetName
  );
  if (!preset) {
    return "";
  }
  return preset[0];
};

export const JVMOptions = ({
  gameserver,
  token,
  openNewJvmOptionModal,
  setOpenNewJvmOptionModal,
  onConfigSave,
}) => {
  const optionsString =
    (gameserver.configuration && gameserver.configuration.JDK_JAVA_OPTIONS) ||
    "";
  const [options, setOptions] = useState([]);
  const [presetOption, setPresetOption] = useState("");

  useEffect(() => {
    setOptions(parseJvmOpts(optionsString));
    if (presetOption === "" && optionsString !== "") {
      const setPreset = getCurrentJvmPreset(optionsString);
      logger.debug({ setPreset });
      setPresetOption(setPreset);
    }
  }, [presetOption, optionsString]);

  const onChange = async (option, value) => {
    logger.debug({ option, value });
    const opts = [...options];
    const existing = opts.find((o) => o.name === option);
    const opt = JvMOptions.find((o) => o.name === option);
    if (existing) {
      existing.value = existing.type === "Boolean" ? value === "true" : value;
    } else if (opt) {
      opts.push({
        ...opt,
        class: "advanced",
        value: opt.type === "Boolean" ? value === "true" : value,
      });
    } else {
      opts.push({
        name: option,
        class: "standard",
        value,
      });
    }
    await onConfigSave(stringifyJvmOpts(opts));
    setOptions(() => opts);
  };

  const removeOption = async (option) => {
    const filtered = options.filter((o) => o.name !== option);
    await onConfigSave(stringifyJvmOpts(filtered));
    setOptions(() => filtered);
  };

  const onPresetApply = async () => {
    const preset = getJvmPresetString(presetOption).trim();
    await onConfigSave(preset);
    setOptions(() => parseJvmOpts(preset));
  };

  const presetDefault = getCurrentJvmPreset(optionsString);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <H4>Available Presets</H4>
        <Stack direction="row" spacing={1}>
          <Select
            sx={{ width: "300px" }}
            defaultValue={presetDefault}
            onChange={(event, value) => {
              setPresetOption(value);
            }}
          >
            <Option value="aikars">Aikars</Option>
            <Option value="none">None</Option>
            <Option value="custom">Custom</Option>
          </Select>
          {presetOption !== presetDefault && presetOption !== "custom" && (
            <LoadingButton onClick={() => onPresetApply()}>
              <Save /> Apply Changes
            </LoadingButton>
          )}
        </Stack>
        {presetOption !== presetDefault && getJvmPresetString(presetOption) && (
          <Sheet
            variant="outlined"
            sx={{
              borderRadius: "5px",
              marginTop: "10px",
              padding: "0.5rem 1rem",
            }}
          >
            <Paragraph sx={{ margin: 0 }}>
              <pre style={{ overflowY: "scroll", overflowX: "none" }}>
                {getJvmPresetString(presetOption)}
              </pre>
            </Paragraph>
          </Sheet>
        )}
      </div>
      <div>
        <H4>Current JVM Options</H4>
        <Table>
          <thead>
            <tr>
              <th style={{ width: "45%" }}>Option Name</th>
              <th style={{ width: "45%" }}>Option Value</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {(options || []).map((option, i) => (
              <tr key={i}>
                <td>{option.name}</td>
                <td>
                  {typeof option.value !== "undefined" && (
                    <Editable
                      initialValue={option.value}
                      onChange={(value) => {
                        onChange(option.name, value);
                      }}
                      onValidate={async (val) => {
                        if (option.type === "Boolean") {
                          if (!["true", "false"].includes(val.toLowerCase())) {
                            throw new Error("Invalid option value");
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td>
                  <LoadingButton
                    color="danger"
                    size="sm"
                    onClick={() => removeOption(option.name)}
                  >
                    <DeleteOutline /> Remove
                  </LoadingButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <NewJavaOptionModal
          onSubmit={(change) => {
            onChange(change.option, change.value);
            setOpenNewJvmOptionModal(false);
          }}
          onClose={() => setOpenNewJvmOptionModal(false)}
          open={openNewJvmOptionModal}
        />
      </div>
    </>
  );
};

export const JvmOptionsTab = ({ gameserver, token }) => {
  const [openNewJvmOptionModal, setOpenNewJvmOptionModal] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [advancedModeString, setAdvancedModeString] = useState("");

  const onConfigSave = async (configString) => {
    logger.debug({ configString });
    if (gameserver.configuration.JDK_JAVA_OPTIONS !== configString) {
      await updateGameserverDetails(
        gameserver.id,
        {
          configuration: {
            JDK_JAVA_OPTIONS: configString,
            _JAVA_OPTIONS: configString,
          },
        },
        token
      );
    }
    gameserver.configuration.JDK_JAVA_OPTIONS = configString;
    gameserver.configuration._JAVA_OPTIONS = configString;
  };
  return (
    <div>
      <Grid container>
        <Grid sm={6}>
          <H4>Java / JVM Options</H4>
        </Grid>
        <Grid sm={6} sx={{ textAlign: "right" }}>
          {!advancedMode ? (
            <Button
              onClick={() => setAdvancedMode(true)}
              sx={{ marginRight: "10px" }}
              variant="soft"
              color="neutral"
            >
              <TerminalOutlined /> Advanced Mode
            </Button>
          ) : (
            <Button
              onClick={() => setAdvancedMode(false)}
              sx={{ marginRight: "10px" }}
              color="neutral"
            >
              <TableChartOutlined /> Table Mode
            </Button>
          )}
          {!advancedMode && (
            <Button onClick={() => setOpenNewJvmOptionModal(true)}>
              <Add /> New Java Option
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid sm={12}>
          <Paragraph>
            Providing custom JVM options can help tune/tweak your{" "}
            {gameserver.game} server, potentially enabling better performance
            and reliability. This is considered an advanced/power user feature
            and we cannot provide support to custom JVM arguments/options.
          </Paragraph>
        </Grid>
      </Grid>
      {!advancedMode && (
        <JVMOptions
          {...{
            gameserver,
            token,
            openNewJvmOptionModal,
            setOpenNewJvmOptionModal,
            onConfigSave,
          }}
        />
      )}
      {advancedMode && (
        <div>
          <H5 sx={{ fontWeight: 800, margin: "10px 0" }}>
            Advanced JVM Option Editor
          </H5>
          <Textarea
            sx={{ fontSize: "14px" }}
            variant="outlined"
            minRows={10}
            defaultValue={
              (gameserver.configuration &&
                gameserver.configuration.JDK_JAVA_OPTIONS) ||
              ""
            }
            onChange={(event) => {
              setAdvancedModeString(event.target.value);
            }}
          />
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <LoadingButton onClick={() => onConfigSave(advancedModeString)}>
              <Save /> Save Changes
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

export const ServerPropertiesEditor = ({ gameserver, token }) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const { control, register, getValues, setValue, formState } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQueryFilter = searchParams.get("filter") || "";
  const [filter, setFilter] = useState(searchQueryFilter);

  useEffect(() => {
    if (formState.isDirty) {
      return;
    }
    getGameserverFileContent(gameserver.id, "server.properties", token).then(
      async (res) => {
        const lines = res?.split("\n").filter((l) => !l.startsWith("#"));
        if (!lines) {
          return;
        }
        const v = lines.reduce((acc, val) => {
          const [k, ...v] = val.split("=");
          if (!serverProperties.find((p) => p.name === k)) {
            return acc;
          }
          return { ...acc, [k]: v.join("=") };
        }, {});
        const configRes = await getGameserverFileConfig(
          gameserver.id,
          "server.properties",
          token
        );
        for (const [key, val] of Object.entries(configRes.config || {})) {
          v[key] = val;
        }
        for (const [key, val] of Object.entries(v)) {
          setValue(key, val);
        }
        setValues(() => v);
        setLoading(false);
      }
    );
  }, [gameserver, token, setValue, formState.isDirty]);

  useEffect(() => {
    if (filter === "") {
      return;
    }
    setSearchParams({ tab: searchParams.get("tab"), filter });
  }, [setSearchParams, searchParams, filter]);

  const getInput = (p, props) => {
    if (p.type === "Boolean") {
      return (
        <Controller
          name={p.name}
          control={control}
          {...props}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(e, value) => onChange(value)}
              {...props}
            >
              <Option value="true">Enabled</Option>
              <Option value="false">Disabled</Option>
            </Select>
          )}
        />
      );
    }
    if (p.type === "Enum") {
      return (
        <Controller
          name={p.name}
          control={control}
          {...props}
          render={({ field: { onChange, value } }) => {
            const dbOc = debounce(onChange, 100);
            return (
              <Select
                value={value}
                onChange={(e, value) => dbOc(value)}
                {...props}
              >
                {p.values.map((v) => (
                  <Option value={v.toString()}>{v}</Option>
                ))}
              </Select>
            );
          }}
        />
      );
    }
    return <Input {...register(p.name)} {...props} />;
  };

  const onSave = async () => {
    const values = serverProperties.reduce(
      (acc, val) => ({
        ...acc,
        [val.name]: getValues(val.name),
      }),
      {}
    );
    await runGameserverFileConfig(
      gameserver.id,
      "server.properties",
      values,
      token
    );
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid sm={12}>
          {" "}
          <H4>Server Properties</H4>
          <Paragraph>
            The server.properties file is where your base server's
            configurations and settings are stored. You can filter properties
            below.
          </Paragraph>
        </Grid>
        <Grid sm={4} sx={{ textAlign: "right" }}></Grid>
      </Grid>

      {loading ? (
        <LoadingSection message={"Loading configuration"} />
      ) : (
        <>
          <Grid container spacing={0} sx={{ margin: "1rem 0 2rem 0" }}>
            <Grid sm={6}>
              <Stack direction="row" spacing={1}>
                <FormHelperText>Filter:</FormHelperText>
                <Input
                  onChange={(e) => setFilter(() => e.target.value)}
                  placeholder="world-seed, level-name ..."
                  size="sm"
                  defaultValue={filter}
                />
              </Stack>
            </Grid>
            <Grid sm={6} sx={{ textAlign: "right" }}>
              {formState.isDirty && (
                <LoadingButton size="sm" onClick={onSave}>
                  <Save /> Save Changes
                </LoadingButton>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {serverProperties
              .filter((p) => {
                if (filter === "") return true;
                return (
                  fuzzy(filter, p.name) > 0.6 ||
                  fuzzy(filter, p.description) > 0.6
                );
              })
              .map((p) => (
                <Grid md={6}>
                  <FormControl>
                    <FormLabel>
                      {title(p.name).replace("Rcon", "RCON")}
                    </FormLabel>
                    {getInput(p, {
                      defaultValue: values[p.name] && values[p.name].toString(),
                    })}
                    <FormHelperText sx={{ marginBottom: "0.5rem" }}>
                      {p.description}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </div>
  );
};

const MinecraftUsernameToPlayerUserModal = ({
  title,
  onSubmit,
  token,
  ...props
}) => {
  const { register, getValues, formState, trigger, setError, reset } =
    useForm();
  const { errors } = formState;

  const onSubmitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const values = await getValues();
      const username = values.username;
      try {
        const player = await getPlayerDetails("minecraft", username, token);
        await onSubmit(player);
        reset();
      } catch (err) {
        setError("username", {
          type: "custom",
          message: "Invalid minecraft username",
        });
      }
    }
  };
  return (
    <Modal {...props}>
      <ModalDialog minWidth={600} maxWidth={600}>
        <DialogTitle>{title}</DialogTitle>
        <form>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Minecraft Username</FormLabel>
              <Input
                placeholder={`Notch, beem, jeb_ ...`}
                error={errors.username}
                {...register("username", { required: "Username is required" })}
              />
              {errors.username && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.username.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton onClick={onSubmitClick}>Submit</LoadingButton>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export const WhitelistUserModal = (props) => (
  <MinecraftUsernameToPlayerUserModal title="Whitelist a User" {...props} />
);

export const WhitelistPlayerListEditor = ({ gameserver, token }) => {
  const filename = "whitelist.json";
  return (
    <PlayerListEditor
      {...{ gameserver, token, filename }}
      ChildModal={BanUserModal}
      listName="whitelist"
      loadList={async () => {
        let listFile = [];
        const res = await getGameserverFileContent(
          gameserver.id,
          filename,
          token
        );
        if (res) {
          try {
            listFile = JSON.parse(res);
            if (!Array.isArray(listFile)) {
              listFile = [];
            }
          } catch (err) {
            console.log(err);
          }
        }
        return listFile;
      }}
      saveList={async (action, player, list) => {
        if (action === "add") {
          await sendGameserverCommand(
            gameserver.id,
            `whitelist add ${player.name}`,
            token
          );
        } else if (action === "remove") {
          await sendGameserverCommand(
            gameserver.id,
            `whitelist remove ${player.name}`,
            token
          );
        }
      }}
      body={
        <>
          <H4>Whitelist</H4>
          <Paragraph>
            The whitelist.json file is a server configuration file that stores
            the usernames of players who have been whitelisted on a server.
            Adding an entry here will enable the whitelist on your server, you
            can disable it on the{" "}
            <LinkHref
              component={Link}
              to="?tab=minecraft%3Aserver_properties&filter=whitelist"
              reloadDocument
            >
              server.properties configuration page
            </LinkHref>
            .
          </Paragraph>
        </>
      }
    />
  );
};

export const PlayerListEditor = ({
  ChildModal,
  filename,
  loadList,
  saveList,
  listName,
  body,
  gameserver,
  token,
}) => {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (!loading) {
      return;
    }
    loadList().then((list) => {
      setList(() => list);
      setLoading(false);
    });
  }, [loadList, loading, gameserver, token]);

  const onModalSubmit = async (player) => {
    const newList = list.reduce(
      (acc, val) => {
        return [...acc.filter((v) => v.uuid !== val.uuid), val];
      },
      [{ uuid: player.id, name: player.username }]
    );
    await saveList("add", { uuid: player.id, name: player.username }, newList);
    setList(() => newList);
    setModalOpen(false);
  };

  const removeFromList = async (player) => {
    const newList = list.filter((v) => v.uuid !== player.uuid);
    await saveList("remove", player, newList);
    setList(() => newList);
  };

  return (
    <div>
      <Grid container>
        <Grid sm={8}>{body}</Grid>
        <Grid sm={4} sx={{ textAlign: "right" }}>
          {filename && (
            <Button
              as={Link}
              to={`?tab=filebrowser&dir=${filename}`}
              variant="soft"
              color="neutral"
              reloadDocument
            >
              <Edit /> Edit file
            </Button>
          )}
          {!loading && (
            <Button
              onClick={() => setModalOpen(true)}
              sx={{ marginLeft: "10px" }}
            >
              <Add /> Add user to {listName}
            </Button>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <LoadingSection message={`Loading ${listName}`} />
      ) : (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "45%" }}>Username</th>
              <th style={{ width: "45%" }}>UUID</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {(list || []).map((row, i) => (
              <tr key={i}>
                <td>{row.name}</td>
                <td>{row.uuid}</td>
                <td>
                  <LoadingButton
                    color="danger"
                    size="sm"
                    onClick={() => removeFromList(row)}
                  >
                    <DeleteOutline /> Remove
                  </LoadingButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <ChildModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={onModalSubmit}
        {...{ token }}
      />
    </div>
  );
};

export const BanUserModal = (props) => (
  <MinecraftUsernameToPlayerUserModal title="Ban a User" {...props} />
);

export const BannedPlayerListEditor = ({ gameserver, token }) => {
  const filename = "banned-players.json";
  return (
    <PlayerListEditor
      {...{ gameserver, token, filename }}
      ChildModal={BanUserModal}
      listName="ban list"
      loadList={async () => {
        let listFile = [];
        const res = await getGameserverFileContent(
          gameserver.id,
          filename,
          token
        );
        if (res) {
          try {
            listFile = JSON.parse(res);
            if (!Array.isArray(listFile)) {
              listFile = [];
            }
          } catch (err) {
            console.log(err);
          }
        }
        return listFile;
      }}
      saveList={async (action, player, list) => {
        if (action === "add") {
          await sendGameserverCommand(
            gameserver.id,
            `ban ${player.name}`,
            token
          );
        } else if (action === "remove") {
          await sendGameserverCommand(
            gameserver.id,
            `pardon ${player.name}`,
            token
          );
        }
      }}
      body={
        <>
          <H4>Banned Players</H4>
          <Paragraph>
            The banned-players.json file is a server configuration file that
            stores the usernames of players who have been banned on a server.
            Adding an entry here will prevent the user from joining the server .
          </Paragraph>
        </>
      }
    />
  );
};

export const OpUserModal = (props) => (
  <MinecraftUsernameToPlayerUserModal
    title="Add a Server Operator"
    {...props}
  />
);

export const OpPlayerListEditor = ({ gameserver, token }) => {
  const filename = "ops.json";
  return (
    <PlayerListEditor
      {...{ gameserver, token, filename }}
      ChildModal={BanUserModal}
      listName="operator list"
      loadList={async () => {
        let listFile = [];
        const res = await getGameserverFileContent(
          gameserver.id,
          filename,
          token
        );
        if (res) {
          try {
            listFile = JSON.parse(res);
            if (!Array.isArray(listFile)) {
              listFile = [];
            }
          } catch (err) {
            console.log(err);
          }
        }
        return listFile;
      }}
      saveList={async (action, list, player) => {
        if (action === "add") {
          await sendGameserverCommand(
            gameserver.id,
            `op ${player.name}`,
            token
          );
        } else if (action === "remove") {
          await sendGameserverCommand(
            gameserver.id,
            `deop ${player.name}`,
            token
          );
        }
      }}
      body={
        <>
          <H4>Server Operators</H4>
          <Paragraph>
            The ops.json file is a server configuration file that stores the
            usernames of players who have made admins on a server. All operators
            will have the same level of access to the server.
          </Paragraph>
        </>
      }
    />
  );
};

const ModInstallerVersionModal = ({
  mod,
  gameserver,
  gameVersion,
  token,
  onSubmit,
  open,
  ...props
}) => {
  const [versions, setVersions] = useState([]);
  const { register, setValue, watch, getValues, formState, trigger } =
    useForm();
  const versionWatcher = watch("version");
  const [modloader, setModloader] = useState(
    gameserver && gameserver.configuration && gameserver.configuration.MODLOADER
      ? gameserver.configuration.MODLOADER || ""
      : ""
  );

  useEffect(() => {
    if (!open || !mod || !modloader || !gameVersion) {
      return;
    }
    setValue("version", "");
    getModVersionsForModloader(
      gameserver.game,
      mod.slug,
      gameVersion,
      modloader,
      token
    ).then((res) => setVersions(() => res.versions));
  }, [open, modloader, gameserver.game, token, mod, gameVersion, setValue]);

  useEffect(() => {
    if (!open || !versions.length === 0 || !gameVersion) {
      return;
    }
    console.log({ versionWatcher });
    if (!versionWatcher) {
      setValue("version", versions[0], { shouldTouch: true });
    }
  }, [open, versionWatcher, versions, gameVersion, setValue]);

  const onSubmitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const values = await getValues();
      const install = { ...values, slug: mod.slug, modloader, gameVersion };
      await onSubmit(mod, install);
    }
  };

  const { errors } = formState;

  if (!mod || !mod.versions) {
    return "";
  }

  return (
    <Modal open={open} {...props}>
      <ModalDialog minWidth={600} maxWidth={600}>
        <DialogTitle>Install Mod</DialogTitle>
        <DialogContent>Choose an available version</DialogContent>
        <form>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Game Version</FormLabel>
              <Select defaultValue={gameVersion} disabled>
                {mod.game_versions.map((v) => (
                  <Option value={v}>{v}</Option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Modloader</FormLabel>
              <Select
                defaultValue={modloader}
                onChange={(e, val) => setModloader(val)}
              >
                {mod.modloaders.map((v) => (
                  <Option value={v}>{v}</Option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Version</FormLabel>
              <Select
                placeholder="Choose version..."
                error={errors.version}
                options={versions.map((v) => ({ label: v, id: v }))}
                {...register("version", { required: "Version is required" })}
                onChange={(e, value) => setValue("version", value)}
              >
                {versions.map((v) => (
                  <Option value={v}>{v}</Option>
                ))}
              </Select>
              {errors.version && (
                <FormHelperText>
                  <Paragraph sx={{ color: "red" }}>
                    <InfoOutlined />
                    {errors.version.message}
                  </Paragraph>
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton onClick={onSubmitClick}>Submit</LoadingButton>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export const ModInstaller = ({ gameserver, token }) => {
  const [loading, setLoading] = useState(true);
  const [installerModalOpen, setInstallerModalOpen] = useState(false);
  const [installerModalMod, setInstallerModalMod] = useState();
  const [mods, setMods] = useState([]);
  const [gameVersion, setGameVersion] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const searchQueryFilter = searchParams.get("filter") || "";
  const searchQuerySort = searchParams.get("sort") || "downloads";
  const [filter, setFilter] = useState(searchQueryFilter);
  const [sort, setSort] = useState(searchQuerySort);

  useEffect(() => {
    if (!gameserver || !gameserver.version) {
      return;
    }
    if (gameserver.version === "latest") {
      getGameserverManifest(gameserver.game, "latest").then((res) => {
        setGameVersion(res.version);
      });
    } else {
      setGameVersion(gameserver.version);
    }
  }, [gameserver, gameserver.game, gameVersion]);

  useEffect(() => {
    if (gameserver && gameserver.game) {
      getMods(gameserver.game, token).then((res) => {
        if (!gameserver.configuration || !gameserver.configuration.MODLOADER) {
          setMods(() => res);
        } else {
          setMods(() =>
            res.filter((m) =>
              m.modloaders.includes(gameserver.configuration.MODLOADER)
            )
          );
        }
        setLoading(false);
      });
    }
  }, [token, gameserver, gameserver.game]);

  useEffect(() => {
    if (filter === "") {
      return;
    }
    setSearchParams({
      tab: searchParams.get("tab"),
      sort: searchParams.get("sort") || "",
      filter,
    });
  }, [setSearchParams, searchParams, filter]);

  useEffect(() => {
    if (sort === "") {
      return;
    }
    setSearchParams({
      tab: searchParams.get("tab"),
      filter: searchParams.get("filter") || "",
      sort,
    });
  }, [setSearchParams, searchParams, sort]);

  const onInstallerSubmit = async (mod, install) => {
    console.log({ mod, install });

    console.log({ install });

    await installGameserverMod(gameserver.id, install, token);
    setInstallerModalOpen(false);
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid sm={12}>
          {" "}
          <H4>One-Click Mod Installer</H4>
          <Paragraph>
            We have a collection of available mods, ready to be installed in one
            click. By default we search for mods linked to your game version
            (e.g. for games like minecraft). This can be overriden, so you can
            install any mod.
          </Paragraph>
        </Grid>
        <Grid sm={4} sx={{ textAlign: "right" }}></Grid>
      </Grid>

      {loading ? (
        <LoadingSection message={"Loading mods"} />
      ) : (
        <>
          <Grid container spacing={0} sx={{ margin: "1rem 0 2rem 0" }}>
            <Grid sm={6}>
              <Stack direction="row" spacing={1}>
                <FormHelperText>Filter:</FormHelperText>
                <Input
                  onChange={(e) => setFilter(() => e.target.value)}
                  placeholder="mod name, tags, author..."
                  size="sm"
                  defaultValue={filter}
                />
                <FormHelperText>Sort by:</FormHelperText>
                <Select
                  onChange={(e, val) => setSort(val)}
                  value={sort}
                  sx={{ fontSize: "14px" }}
                >
                  <Option value="downloads">Download count</Option>
                </Select>
              </Stack>
            </Grid>
            <Grid sm={6} justifyContent="flex-end">
              <Stack direction="row" spacing={1}></Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {(mods || [])
              // eslint-disable-next-line array-callback-return
              .sort((a, b) => {
                if (!sort || sort === "downloads") {
                  return b.downloads - a.downloads;
                }
              })
              .filter((p) => {
                if (
                  p.game_versions.length > 0 &&
                  !p.game_versions.includes(gameVersion)
                ) {
                  return false;
                }
                if (filter === "") return true;
                return (
                  fuzzy(filter, p.title) > 0.6 ||
                  fuzzy(filter, p.description) > 0.6
                );
              })
              .map((p) => (
                <Grid md={3}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography level="title-lg">{p.title}</Typography>
                      <Typography
                        level="body-sm"
                        sx={{
                          minHeight: "60px",
                        }}
                      >
                        {p.description}
                      </Typography>
                      <Typography
                        level="body-sm"
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        <Chip
                          variant="outlined"
                          color="primary"
                          startDecorator={<InstallDesktopOutlined />}
                          sx={{ marginTop: "10px" }}
                          size="sm"
                        >
                          {p.downloads} downloads
                        </Chip>
                      </Typography>
                      <Typography
                        level="body-sm"
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        {p.tags.map((t) => (
                          <Chip size="sm" variant="soft">
                            {t}
                          </Chip>
                        ))}
                      </Typography>
                    </CardContent>
                    <CardActions buttonFlex="0 1 120px">
                      <Button
                        color="primary"
                        onClick={() => {
                          setInstallerModalMod(p);
                          setInstallerModalOpen(() => true);
                        }}
                      >
                        <Download /> Install
                      </Button>
                      <Button variant="outlined" color="neutral">
                        Details
                      </Button>
                    </CardActions>
                  </Card>{" "}
                </Grid>
              ))}
          </Grid>
        </>
      )}
      <ModInstallerVersionModal
        mod={installerModalMod}
        open={installerModalOpen}
        onClose={() => setInstallerModalOpen(false)}
        onSubmit={onInstallerSubmit}
        {...{ gameVersion, gameserver, token }}
      />
    </div>
  );
};

export const TimeMachineRestorePointPicker = ({ onSubmit }) => {
  const { register, getValues, setValue, formState, trigger } = useForm();
  const { errors } = formState;

  const onSubmitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const values = await getValues();
      await onSubmit(values.datetime);
    }
  };

  return (
    <div>
      <FormLabel>Restore Point</FormLabel>
      <Stack spacing={2} direction="row">
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{
                ".MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  fieldset: {
                    borderColor: "#cdd7e1",
                  },
                  "&:hover  fieldset": {
                    borderColor: "#cdd7e1",
                  },
                },
                ".MuiInputBase-input": {
                  padding: "0.5rem 0.5rem 0.5rem 0.75rem",
                },
              }}
              {...register("datetime", {
                required: "Date and time is required",
              })}
              onChange={(e, val) => setValue("datetime", e["$d"])}
            />
          </LocalizationProvider>
        </FormControl>
        <LoadingButton onClick={onSubmitClick}>Submit</LoadingButton>
      </Stack>
      {errors.datetime && (
        <FormHelperText>
          <Paragraph sx={{ color: "red" }}>
            <InfoOutlined />
            {errors.datetime.message}
          </Paragraph>
        </FormHelperText>
      )}
    </div>
  );
};

const getGameTabs = (game, { gameserver, token }, { tabs, setTab }) => {
  if (game === "minecraft") {
    return {
      "minecraft:server_properties": {
        tab: (
          <Tab
            variant="plain"
            color="neutral"
            indicatorInset
            value={"minecraft:server_properties"}
            ref={tabs["minecraft:server_properties"]}
            onClick={() => setTab("minecraft:server_properties")}
          >
            Server
          </Tab>
        ),
        content: (
          <TabPanel
            value={"minecraft:server_properties"}
            style={{ paddingTop: 0, background: "white" }}
          >
            <ServerPropertiesEditor {...{ gameserver, token }} />
          </TabPanel>
        ),
      },
      "minecraft:whitelist_json": {
        tab: (
          <Tab
            variant="plain"
            color="neutral"
            indicatorInset
            value={"minecraft:whitelist_json"}
            ref={tabs["minecraft:whitelist_json"]}
            onClick={() => setTab("minecraft:whitelist_json")}
          >
            Whitelist
          </Tab>
        ),
        content: (
          <TabPanel
            value={"minecraft:whitelist_json"}
            style={{ paddingTop: 0, background: "white" }}
          >
            <WhitelistPlayerListEditor {...{ gameserver, token }} />
          </TabPanel>
        ),
      },
      "minecraft:banned_players_json": {
        tab: (
          <Tab
            variant="plain"
            color="neutral"
            indicatorInset
            value={"minecraft:banned_players_json"}
            ref={tabs["minecraft:banned_players_json"]}
            onClick={() => setTab("minecraft:banned_players_json")}
          >
            Ban List
          </Tab>
        ),
        content: (
          <TabPanel
            value={"minecraft:banned_players_json"}
            style={{ paddingTop: 0, background: "white" }}
          >
            <BannedPlayerListEditor {...{ gameserver, token }} />
          </TabPanel>
        ),
      },
      "minecraft:ops_json": {
        tab: (
          <Tab
            variant="plain"
            color="neutral"
            indicatorInset
            value={"minecraft:ops_json"}
            ref={tabs["minecraft:ops_json"]}
            onClick={() => setTab("minecraft:ops_json")}
          >
            Server Ops
          </Tab>
        ),
        content: (
          <TabPanel
            value={"minecraft:ops_json"}
            style={{ paddingTop: 0, background: "white" }}
          >
            <OpPlayerListEditor {...{ gameserver, token }} />
          </TabPanel>
        ),
      },
      java_opts: {
        tab: (
          <Tab
            variant="plain"
            color="neutral"
            indicatorInset
            value={"jvm_opts"}
            ref={tabs.jvm_opts}
            onClick={() => setTab("jvm_opts")}
          >
            Java Options
          </Tab>
        ),
        content: (
          <TabPanel
            value={"jvm_opts"}
            style={{ paddingTop: 0, background: "white" }}
          >
            <JvmOptionsTab {...{ gameserver, token }} />
          </TabPanel>
        ),
      },
    };
  }
  return {};
};

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabQueryParam = searchParams.get("tab") || "overview";

  const [tab, setTab] = useState(tabQueryParam);

  const [rr, setRR] = useState(5000);
  const [nonce, setNonce] = useState("0");
  const [token, setToken] = useState("");
  const [queue, setQueue] = useState(false);
  const [openNewScheduledTaskModal, setOpenNewScheduledTaskModal] =
    useState(false);

  const { gameserverId } = useParams();
  const { getToken } = useAuth();

  const [gameserver, setGameserver] = useState();
  const gameserverReq = useGameserverDetails(gameserverId, nonce);

  const tabs = [
    "overview",
    "mods",
    "minecraft:server_properties",
    "minecraft:whitelist_json",
    "minecraft:banned_players_json",
    "minecraft:ops_json",
    "jvm_opts",
    "console",
    "filebrowser",
    "sftp",
    "mysql",
    "tasks",
    "timemachine",
    "backups",
    "ports",
    "events",
  ].reduce(
    (acc, val) => ({ ...acc, [val]: tabCallback(tab, val, setTab) }),
    {}
  );

  const gameTabs = getGameTabs(
    gameserver ? gameserver.game : "",
    { gameserver, token },
    { tabs, setTab }
  );

  const refreshGameserver = () => {
    setRR(1000);
    setNonce(Date.now().toString());
  };

  useEffect(() => {
    if (tabQueryParam === tab) {
      return;
    }
    setSearchParams({ ...searchParams, tab });
  }, [searchParams, tabQueryParam, setSearchParams, tab]);

  useEffect(() => {
    getToken({ template: "default" }).then((t) => setToken(() => t));
  }, [getToken]);

  useEffect(() => {
    if (gameserverReq.isLoading) {
      return;
    }
    if (!gameserverReq.data) {
      return;
    }
    if (gameserverReq.data.status === "terminated") {
      setRR(0);
    }
    setGameserver(() => gameserverReq.data);
  }, [gameserverReq.isLoading, gameserverReq.data, token]);

  useEffect(() => {
    if (!gameserverReq.data) {
      return;
    }
    if (gameserverReq.data.status === "pending") {
      getQueuePositionGameserver(token).then((q) => {
        if (q && q.position > -1) {
          setQueue(`${q.position + 1} of ${q.total}`);
        } else {
          setQueue(false);
        }
      });
    } else {
      setQueue(false);
    }
  }, [gameserverReq.data, token, nonce]);

  useEffect(() => {
    if (rr === 0) {
      return;
    }
    const t = setTimeout(() => refreshGameserver(), rr);
    return () => clearTimeout(t);
  }, [rr, nonce]);

  useEffect(() => {
    if (rr === 0 || rr > 10000) {
      return;
    }
    const t = setTimeout(() => setRR(30000), rr);
    return () => clearTimeout(t);
  }, [rr]);

  useEffect(() => {
    const commandHandler = (text) => {
      sendGameserverCommand(gameserver && gameserver.id, text, token);
      TerminalService.emit("clear");
    };
    TerminalService.on("command", commandHandler);
    return () => {
      TerminalService.off("command", commandHandler);
    };
  }, [gameserver, token]);

  const onDeleteButtonPress = async () => {
    await deleteGameserver(gameserver && gameserver.id, token);
    refreshGameserver();
  };

  const onRawLogButtonPress = async () => {
    const logData = await getGameserverLogs(gameserver && gameserver.id, token);
    const blob = await fetch(`data:text/plain;base64,${btoa(logData)}`).then(
      (res) => res.blob()
    );
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  const onDownloadDataButtonPress = async () => {
    const blob = await getGameserverBackup(
      gameserver && gameserver.id,
      "latest",
      token
    );
    const blobUrl = URL.createObjectURL(blob);
    window.location.assign(blobUrl);
  };

  const onPauseButtonPress = async () => {
    await pauseGameserver(gameserver && gameserver.id, token);
    refreshGameserver();
  };

  const onUnpauseButtonPress = async () => {
    await unpauseGameserver(gameserver && gameserver.id, token);
    refreshGameserver();
  };

  const onGameserverStartButtonPress = async () => {
    await startGameserver(gameserver && gameserver.id, token);
    setTab("console");
    refreshGameserver();
  };

  const onGameserverRestartButtonPress = async () => {
    await restartGameserver(gameserver && gameserver.id, token);
    refreshGameserver();
  };

  const onGameserverStopButtonPress = async () => {
    await stopGameserver(gameserver && gameserver.id, token);
    refreshGameserver();
  };

  const onGameserverTimemachineRestoreSubmit = async (restorePoint) => {
    console.log({ restorePoint });
    await restoreGameserverTimemachine(gameserver.id, restorePoint, token);
    setTab("overview");
    await refreshGameserver();
  };

  const serverPort =
    gameserver &&
    gameserver.ports &&
    gameserver.ports.find((p) => p.name === "server");
  const created = gameserver ? new Date(gameserver.createdAt) : new Date();
  const expiry = new Date(created.getTime() + 3600000);

  if (!gameserver || !gameserver) {
    return <LoadingSection message="Fetching gameserver details" />;
  }

  const bodySwitchStatus = {
    pending: (
      <LoadingSection message="Your gameserver is currently pending">
        {queue ? (
          <Paragraph style={{ marginTop: "2rem", fontSize: "12px" }}>
            You are in queue position {queue}
          </Paragraph>
        ) : (
          ""
        )}
      </LoadingSection>
    ),
    scheduling: (
      <LoadingSection message="Your gameserver is currently scheduling" />
    ),
    installing: (
      <LoadingSection message="Your gameserver is currently installing" />
    ),
    stopping: <LoadingSection message="Your gameserver is stopping" />,
    starting: <LoadingSection message="Your gameserver is starting" />,
    paused: (
      <LoadingSection
        message="Your gameserver is currently paused"
        buttons={
          <LoadingButton style={{}} onClick={onUnpauseButtonPress} size="lg">
            Unpause Gameserver
          </LoadingButton>
        }
        noLoader
      />
    ),
    offline: (
      <LoadingSection
        message="Your gameserver is currently offline"
        buttons={
          <LoadingButton
            style={{}}
            onClick={onGameserverStartButtonPress}
            size="lg"
          >
            Start Gameserver
          </LoadingButton>
        }
        noLoader
      />
    ),
    terminating: (
      <LoadingSection message="Your gameserver is currently terminating" />
    ),
    terminated: (
      <LoadingSection
        message="Your gameserver has been terminated"
        buttons={
          <>
            <LoadingButton
              style={{ marginRight: "10px" }}
              onClick={onRawLogButtonPress}
              size="lg"
            >
              Download Logs
            </LoadingButton>
            <LoadingButton
              style={{}}
              onClick={onDownloadDataButtonPress}
              size="lg"
            >
              Download Data
            </LoadingButton>
          </>
        }
        noLoader
      />
    ),
  };

  const stats =
    gameserver.status === "online" && gameserver.usage ? (
      <section style={{ marginBottom: "20px" }}>
        <div
          style={{ width: "48%", marginRight: "2%", display: "inline-block" }}
        >
          <h5 style={{ marginBottom: "10px" }}>
            CPU <Chip size="sm">{gameserver.usage.cpuUsage}%</Chip>
          </h5>
          <LinearProgress
            size="lg"
            determinate
            value={
              gameserver.usage.cpuUsage > 100 ? 100 : gameserver.usage.cpuUsage
            }
          />
          <small>
            {`${gameserver.usage.cpu} / ${gameserver.limits.cpu} cores`}
          </small>
        </div>
        <div style={{ width: "50%", display: "inline-block" }}>
          <h5 style={{ marginBottom: "10px" }}>
            Memory <Chip size="sm">{gameserver.usage.memUsage}%</Chip>
          </h5>
          <LinearProgress
            determinate
            value={
              gameserver.usage.memUsage > 100 ? 100 : gameserver.usage.memUsage
            }
            size="lg"
          ></LinearProgress>
          <small>
            {`${prettyBytes(Number(gameserver.usage.memBytes))} / ${prettyBytes(
              Number(gameserver.limits.mem)
            )}`}
          </small>
        </div>
      </section>
    ) : (
      ""
    );

  return (
    <>
      <Header />
      <Section>
        <GameserverTitle>
          <span>
            <GameserverIcon
              src={GameIcon(gameserver.game)}
              alt={`${gameserver.game}-icon`}
            />
            {gameserver.name}
          </span>
          {!["online", "offline"].includes(gameserver.status) ? (
            <>
              <ChipOrb
                color1={
                  ["starting", "installing", "updating"].includes(
                    gameserver.status
                  )
                    ? "success"
                    : ["terminating", "error", "scheduler-error"].includes(
                        gameserver.status
                      )
                    ? "danger"
                    : "neutral"
                }
                color2={
                  gameserver.status === "terminated"
                    ? "neutral"
                    : ["error", "scheduler-error"].includes(gameserver.status)
                    ? "danger"
                    : "light"
                }
                size="sm"
                variant="solid"
                style={{
                  padding: "0px 10px",
                  display: "inline-block",
                  marginLeft: "10px",
                  marginTop: "-10px",
                }}
                sx={{ transition: "background-color 1000ms linear" }}
              />
              <span
                style={{
                  fontSize: "14px",
                  position: "relative",
                  left: "5px",
                  top: "-5px",
                }}
              >
                {gameserver.status}
              </span>
            </>
          ) : (
            <LoadingSwitch
              size="lg"
              color={gameserver.status === "online" ? "success" : "neutral"}
              checked={gameserver.status === "online"}
              disabled={!["online", "offline"].includes(gameserver.status)}
              onChange={(e) => {
                if (e.target.checked) {
                  return onGameserverStartButtonPress();
                } else {
                  return onGameserverStopButtonPress();
                }
              }}
              style={{ marginLeft: "10px" }}
            />
          )}

          <div style={{ float: "right" }}>
            <Dropdown>
              <MenuButton
                slotProps={{
                  root: { variant: "outlined", color: "neutral" },
                }}
                style={{ paddingLeft: "5px" }}
              >
                <MoreVert />
                <span style={{ fontSize: "14px" }}>Actions</span>
              </MenuButton>
              <Menu>
                <MenuItem
                  component={`button`}
                  onClick={onGameserverStartButtonPress}
                  style={{
                    display: gameserver.status === "online" ? "none" : "flex",
                  }}
                >
                  Start Gameserver
                </MenuItem>
                <MenuItem
                  component={`button`}
                  onClick={onGameserverRestartButtonPress}
                  style={{
                    display: gameserver.status === "online" ? "flex" : "none",
                  }}
                >
                  Restart Gameserver
                </MenuItem>
                <MenuItem
                  component={`button`}
                  onClick={onGameserverStopButtonPress}
                  style={{
                    display: gameserver.status === "online" ? "flex" : "none",
                  }}
                >
                  Stop Gameserver
                </MenuItem>
                <MenuItem
                  component={`button`}
                  onClick={onPauseButtonPress}
                  style={{
                    display:
                      gameserver.status !== "terminated" &&
                      gameserver.status !== "terminating" &&
                      gameserver.status !== "stopping" &&
                      gameserver.status !== "paused"
                        ? "flex"
                        : "none",
                  }}
                >
                  Pause Gameserver
                </MenuItem>
                <MenuItem
                  component={`button`}
                  onClick={onUnpauseButtonPress}
                  style={{
                    display: gameserver.status === "paused" ? "flex" : "none",
                  }}
                >
                  Unpause Gameserver
                </MenuItem>
                <ListDivider />
                <MenuItem component={`button`} onClick={onRawLogButtonPress}>
                  View Raw Log
                </MenuItem>
                <MenuItem
                  component={`button`}
                  onClick={onDownloadDataButtonPress}
                  style={{
                    display:
                      gameserver.status === "terminated" ? "flex" : "none",
                  }}
                >
                  Download Data
                </MenuItem>
                <MenuItem
                  component={Button}
                  onClick={onDeleteButtonPress}
                  variant="soft"
                  color="danger"
                  style={{
                    display: ["terminated"].includes(gameserver.status)
                      ? "none"
                      : "flex",
                  }}
                >
                  Terminate Gameserver
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>
          {gameserver.status !== "terminated" && (
            <LoadingButton
              style={{ float: "right", marginRight: "1rem" }}
              onClick={onDeleteButtonPress}
              color="danger"
            >
              <Tooltip arrow title="Delete gameserver">
                <Delete />
              </Tooltip>
            </LoadingButton>
          )}
          {gameserver.status !== "terminated" &&
            gameserver.status !== "terminating" &&
            gameserver.status !== "stopping" &&
            gameserver.status !== "paused" && (
              <LoadingButton
                style={{ float: "right", marginRight: "10px" }}
                onClick={onPauseButtonPress}
                color="neutral"
              >
                <Tooltip arrow title="Pause gameserver">
                  <Pause />
                </Tooltip>
              </LoadingButton>
            )}
          {gameserver.status === "online" && (
            <LoadingButton
              onClick={onGameserverRestartButtonPress}
              style={{ float: "right", marginRight: "10px" }}
              variant="outlined"
            >
              <Tooltip arrow title="Restart gameserver">
                <RestartAlt />
              </Tooltip>
            </LoadingButton>
          )}

          {gameserver.status === "online" && (
            <LoadingButton
              onClick={onGameserverStopButtonPress}
              style={{ float: "right", marginRight: "10px" }}
            >
              <Tooltip arrow title="Stop gameserver">
                <Stop />
              </Tooltip>
            </LoadingButton>
          )}
          {gameserver.status === "offline" && (
            <LoadingButton
              style={{ float: "right", marginRight: "10px" }}
              onClick={onGameserverStartButtonPress}
              color="success"
            >
              <Tooltip arrow title="Start Gameserver">
                <PlayCircle />
              </Tooltip>
            </LoadingButton>
          )}
          {gameserver.status === "online" && (
            <LoadingButton
              style={{ float: "right", marginRight: "10px" }}
              onClick={() => {
                setTab("console");
              }}
              color="neutral"
              variant="soft"
            >
              <Tooltip arrow title="Gameserver console">
                <TerminalOutlined />
              </Tooltip>
            </LoadingButton>
          )}
        </GameserverTitle>
        <div>
          {gameserver && gameserver.status === "online" && serverPort && (
            <span style={{ marginRight: "1rem" }}>
              <Tooltip
                placement="top"
                variant="outlined"
                title="Connect to your gameserver"
                arrow
              >
                <span style={{ fontSize: "14px" }}>
                  <LinkOutlined sx={{ transform: "rotate(45deg)" }} />{" "}
                  {serverPort.ip}:{serverPort.port}
                </span>
              </Tooltip>
              <Button
                variant="solid"
                size="xs"
                color="primary"
                sx={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  padding: "0.2rem 0.4rem",
                }}
              >
                Connect
              </Button>
            </span>
          )}
          {expiry && (
            <span css={[tw`text-gray-500 text-sm`]}>
              <TimeUntil time={expiry} /> remaining
              <Tooltip
                title="This is a time limited trial gameserver"
                placement="bottom"
                arrow
              >
                <InfoOutlined
                  color="neutral"
                  sx={{
                    marginLeft: "5px",
                    marginBottom: "4px",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </span>
          )}
        </div>
      </Section>
      <Section style={{ width: "75%" }}>
        {gameserver && gameserver.status === "error" && (
          <Error>
            <ErrorIcon />
            Your gameserver has crashed and will automatically attempt to
            restart. Check the logs for more information
          </Error>
        )}
        <Tabs
          orientation="vertical"
          size="md"
          defaultValue={0}
          sx={{ display: "block", width: "100%" }}
        >
          <Grid container columns={36}>
            <Grid sm={4}>
              <TabList
                disableUnderline
                sx={{ background: "white", width: "100%" }}
              >
                <SectionTitle
                  style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                >
                  <b>{gameserver.game.toUpperCase()}</b>
                </SectionTitle>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"overview"}
                  ref={tabs.overview}
                  onClick={() => setTab("overview")}
                >
                  Overview
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"mods"}
                  ref={tabs.mods}
                  onClick={() => setTab("mods")}
                >
                  Mods
                </Tab>
                {Object.entries(gameTabs).map(([v, c]) => c.tab)}
                <SectionTitle
                  style={{
                    fontSize: "12px",
                    marginBottom: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <b>GAMESERVER</b>
                </SectionTitle>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"console"}
                  ref={tabs.console}
                  onClick={() => setTab("console")}
                >
                  Console
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"timemachine"}
                  ref={tabs.timemachine}
                  onClick={() => setTab("timemachine")}
                  sx={{ position: "relative" }}
                >
                  Time Machine{" "}
                  <Chip
                    color="success"
                    size="sm"
                    sx={{ position: "absolute", right: 0, top: -3 }}
                  >
                    new
                  </Chip>
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"tasks"}
                  ref={tabs.tasks}
                  onClick={() => setTab("tasks")}
                >
                  Tasks
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"ports"}
                  ref={tabs.ports}
                  onClick={() => setTab("ports")}
                >
                  Ports
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"events"}
                  ref={tabs.events}
                  onClick={() => setTab("events")}
                >
                  Events
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"backups"}
                  ref={tabs.backups}
                  onClick={() => setTab("backups")}
                >
                  <span style={{ position: "relative" }}>
                    Backups
                    <Chip
                      color="warning"
                      size="sm"
                      sx={{ position: "absolute", left: 65, top: -3 }}
                    >
                      classic
                    </Chip>
                  </span>
                </Tab>
                <SectionTitle
                  style={{
                    fontSize: "12px",
                    marginBottom: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <SectionTitle
                    style={{
                      fontSize: "12px",
                      marginBottom: "0.5rem",
                      marginTop: "1rem",
                    }}
                  ></SectionTitle>
                  <b>SERVICES</b>
                </SectionTitle>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"file-manager"}
                  ref={tabs.filebrowser}
                  onClick={() => setTab("filebrowser")}
                >
                  File Manager
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"sftp"}
                  ref={tabs.sftp}
                  onClick={() => setTab("sftp")}
                >
                  SFTP
                </Tab>
                <Tab
                  variant="plain"
                  color="neutral"
                  indicatorInset
                  value={"mysql"}
                  ref={tabs.mysql}
                  onClick={() => setTab("mysql")}
                >
                  MySQL
                </Tab>
              </TabList>
            </Grid>
            <Grid sm={32}>
              <TabPanel
                value={"overview"}
                style={{ paddingTop: 0, background: "white" }}
              >
                {bodySwitchStatus[gameserver.status] ? (
                  <Section>{bodySwitchStatus[gameserver.status]}</Section>
                ) : (
                  <h2 style={{ marginBottom: "2rem" }}>
                    {gameserver && gameserver.status !== "online" && (
                      <span>
                        Gameserver status: <b>{gameserver.status}</b>
                      </span>
                    )}
                  </h2>
                )}
                {stats}
                {gameserver && gameserver.status === "online" && (
                  <GameserverConsole gameserver={gameserver} token={token} />
                )}
              </TabPanel>
              <TabPanel
                value={"mods"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <ModInstaller {...{ gameserver, token }} />
              </TabPanel>
              {Object.entries(gameTabs).map(([v, c]) => c.content)}
              <TabPanel value={"console"} style={{ paddingTop: 0 }}>
                <GameserverConsole gameserver={gameserver} token={token} />
              </TabPanel>
              <TabPanel
                value={"timemachine"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <H4>Time Machine</H4>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid sm={12}>
                    <Paragraph>
                      Time Machine is a new way of taking and restoring server
                      backups. Simply pick a date and a time and we'll restore
                      to that point.
                    </Paragraph>
                    <TimeMachineRestorePointPicker
                      onSubmit={onGameserverTimemachineRestoreSubmit}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={"tasks"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid sm={6}>
                    <H4>Scheduled Tasks</H4>
                  </Grid>
                  <Grid sm={6} sx={{ textAlign: "right" }}>
                    <Button onClick={() => setOpenNewScheduledTaskModal(true)}>
                      <Add /> New Scheduled Task
                    </Button>
                  </Grid>
                  <NewScheduledTaskModal
                    open={openNewScheduledTaskModal}
                    onClose={() => setOpenNewScheduledTaskModal(false)}
                    onSubmit={async (task) => {
                      await newGameserverTask(gameserver.id, task, token);
                      setOpenNewScheduledTaskModal(false);
                      setTab("overview");
                      setTab(() => "tasks");
                      setNonce(Date.now());
                    }}
                  />
                </Grid>
                <GameserverTasksTable
                  refreshToken={nonce}
                  {...{ gameserver, token }}
                />
              </TabPanel>
              <TabPanel
                value={"file-manager"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <FileBrowser gameserver={gameserver} token={token} path="" />
              </TabPanel>
              <TabPanel
                value={"sftp"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <H4>Connect to SFTP</H4>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid sm={12}>
                    <Paragraph>
                      SFTP is a file manangement protocol, usually installed
                      alongside of SSH which is installed on your Mac/Linux by
                      default. For Windows (or for a visual UI) FileZilla or
                      Cyberduck are good SFTP client choices.
                    </Paragraph>
                    <SFTPCredentials gameserver={gameserver} token={token} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={"mysql"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <H4>Connect to MySQL</H4>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid sm={12}>
                    <Paragraph>
                      MySQL is a widely used relational database management
                      system. Some mods may require data persistence via MySQL.
                      MySQL uses structured query language (SQL) to manage data
                      inside its database.
                    </Paragraph>
                    <MySQLCredentials gameserver={gameserver} token={token} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={"backups"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid sm={6}>
                    <H4>Backups</H4>
                  </Grid>
                  <Grid sm={6} sx={{ textAlign: "right" }}>
                    <LoadingButton
                      onClick={() => newGameserverBackup(gameserver.id, token)}
                    >
                      <Add /> New Backup
                    </LoadingButton>
                  </Grid>{" "}
                </Grid>
                <GameserverBackupTable gameserver={gameserver} token={token} />
              </TabPanel>
              <TabPanel
                value={"ports"}
                style={{ paddingTop: 0, background: "white" }}
              >
                {/*<Table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Name</th>
                <th style={{ width: "10%" }}>Protocol</th>
                <th>Port</th>
              </tr>
            </thead>
            <tbody>
              {(gameserver.ports || []).map((port) => (
                <tr key={port.name}>
                  <td>{port.name}</td>
                  <td>{port.protocol}</td>
                  <td>
                    {port.ip}:{port.port}
                  </td>
                </tr>
              ))}
            </tbody>
              </Table>*/}
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  {(gameserver.ports || []).map((port) => (
                    <Grid sm={12} md={3} lg={3}>
                      <Card variant="outlined">
                        <CardContent>
                          <H5 level="title-lg">
                            <LinkOutlined sx={{ transform: "rotate(45deg)" }} />{" "}
                            {port.name}
                          </H5>
                          <Paragraph>
                            {port.ip}:{port.port}
                          </Paragraph>
                        </CardContent>
                        <CardActions>
                          <CopyToClipboard text={`${port.ip}:${port.port}`}>
                            <Tooltip
                              placement="top"
                              title="Click to copy to clipboard"
                              arrow
                            >
                              <Button size="md">
                                <CopyAll /> Copy to clipboard
                              </Button>
                            </Tooltip>
                          </CopyToClipboard>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel
                value={"events"}
                style={{ paddingTop: 0, background: "white" }}
              >
                <GameserverEventsTable gameserver={gameserver} token={token} />
              </TabPanel>
            </Grid>
          </Grid>
        </Tabs>
      </Section>

      <Footer />
    </>
  );
};
