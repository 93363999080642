import LegalTemplate from "./LegalTemplate";
import Markdown from "components/misc/Markdown";

import policy from "policy/privacy-policy.md";

export default () => (
  <LegalTemplate>
    <h1>Privacy Policy</h1>
    <Markdown content={policy} />
  </LegalTemplate>
);
