import React, { useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/GetStarted.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/default.js";

import minecraftHeroSrc from "images/minecraft-hero.png";

import { TypeAnimation } from "react-type-animation";

import { useNavigate } from "react-router-dom";
import { useGameserver } from "data/gameserver";

const TitleDiv = tw.div`block items-center sm:flex-row text-center lg:justify-start mt-6 mb-0`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => {
  const imageCss = tw`rounded-4xl`;
  const navigate = useNavigate();
  const onChange = (e) => navigate(`/games/${e.value}`);
  const gameserver = useGameserver();

  useEffect(() => {
    if (!gameserver.isLoading && gameserver.data && gameserver.data.name) {
      navigate(`/gameservers/${gameserver.data.name}`);
    }
  }, [gameserver.isLoading, gameserver.data, navigate]);

  return (
    <AnimationRevealPage disabled>
      <Header />
      <TitleDiv>
        <h1
          style={{
            fontSize: "1.875rem",
            fontWeight: "900",
            position: "relative",
            left: "-60px",
          }}
        >
          Gameserver hosting that is{"  "}
          <HighlightedText style={{ position: "absolute", marginLeft: "10px" }}>
            <TypeAnimation
              sequence={[
                "Powerful",
                1000,
                "Scaleable",
                1000,
                "Flexible",
                1000,
                "Configurable",
                1000,
                "Fast",
                1000,
                "Global",
                1000,
                "Reliable",
                1000,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{ display: "inline-block" }}
            />
          </HighlightedText>
        </h1>
      </TitleDiv>
      <Hero
        heading={
          <>
            Claim your{"  "}
            <HighlightedText style={{ padding: "0 0.5rem" }}>
              free gameserver
            </HighlightedText>{" "}
            <br />
            during our open beta.
          </>
        }
        description={
          <span>
            <small>
              Gameservers are free for 1 hour and can be extended depending on
              availability.
            </small>
            <h5 style={{ marginTop: "20px" }}>Search for your game below:</h5>
          </span>
        }
        imageSrc={minecraftHeroSrc}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        features={[
          "100% Free",
          "Instant Setup",
          "Dedicated CPU/RAM",
          "DDOS Protection (L3/L4)",
          "Burstable Resources",
        ]}
        features2={[
          "10,000+ Plugins Available",
          "No Credit Card Required",
          "Modern Hadrware",
          "Global Region Support",
          "24/7 Support",
        ]}
        onSelectChange={onChange}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
