import { fetcher } from "./gameserver";

export const getMods = async (game, auth) => {
  const method = "GET";
  return fetcher({ method, subject: "mods" }, auth)(`/games/${game}/mods`);
};

export const getModVersions = async (game, modId, gameVersion, auth) => {
  const method = "GET";
  return fetcher(
    { method, subject: "mod" },
    auth
  )(`/games/${game}/mods/${modId}?game_version=${gameVersion}`);
};

export const getModVersionsForModloader = async (
  game,
  modId,
  gameVersion,
  modloader,
  auth
) => {
  const method = "GET";
  return fetcher(
    { method, subject: "mod" },
    auth
  )(
    `/games/${game}/mods/${modId}?game_version=${gameVersion}&modloader=${modloader}`
  );
};
