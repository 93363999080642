import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/default.js";
import styled from "styled-components";

const Section = styled.section`
  ${tw`w-2/3 m-auto mb-10 text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl mt-10`}
    font-weight: 800;
    border: none;
  }
  h1:first-child {
    margin-top: 0;
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  h4:first-child {
    ${tw`mb-6`}
  }
  ul,
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ children }) => {
  return (
    <>
      <Header />
      <Section>{children}</Section>
      <Footer />
    </>
  );
};
